import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Button,
  Typography,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import ChurchIcon from '@mui/icons-material/LocationCity';
import GymIcon from '@mui/icons-material/FitnessCenter';
import AddIcon from '@mui/icons-material/Add';
import deliveryAddressStore from '../stores/DeliveryAddressStore';
import authStore from '../stores/AuthStore';

function DeliveryLocationComponent({ isLoggedIn }) {
  const navigate = useNavigate();

  const [addresses, setAddresses] = useState(deliveryAddressStore.deliveryAddresses || []);
  const [open, setOpen] = useState(false);
  const [newAddress, setNewAddress] = useState({ street: '', city: '', state: '', postalCode: '', type: 'home' });

  const handleAddressClick = (address) => {
    deliveryAddressStore.setSelectedAddress(address);
    navigate('/order');
  };

  const renderIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'home':
        return <HomeIcon />;
      case 'work':
        return <WorkIcon />;
      case 'business':
        return <WorkIcon />;
      case 'church':
        return <ChurchIcon />;
      case 'gym':
        return <GymIcon />;
      default:
        return <Icon />;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    await deliveryAddressStore.addDeliveryAddress(newAddress);
    setAddresses(deliveryAddressStore.deliveryAddresses);
    handleClose();
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      await deliveryAddressStore.fetchDeliveryAddresses();
      setAddresses(deliveryAddressStore.deliveryAddresses);
    };

    if (isLoggedIn && addresses.length === 0) {
      fetchAddresses();
    }
  }, []);

  if (!isLoggedIn) {
    return (
      <Typography variant='h6' sx={{ paddingTop: '50px' }}>
        <Link to="/auth?type=signin" style={{ color: 'white', textDecoration: 'none' }}>
          <span style={{ textDecoration: 'underline' }}>Log in</span> to see delivery locations
        </Link>
      </Typography>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '1rem', overflowX: 'auto' }}>
        {addresses.map((address, index) => (
          <Button
            key={index}
            variant='outlined'
            startIcon={renderIcon(address.type)}
            onClick={() => handleAddressClick(address)}
            style={{
              color: 'white',
              borderColor: 'white',
              backgroundColor: 'transparent',
              minWidth: '150px',
              padding: '16px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography variant='body1'>{address.street}</Typography>
          </Button>
        ))}
        <Button
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
          style={{
            color: 'white',
            borderColor: 'white',
            backgroundColor: 'transparent',
            minWidth: '150px',
            padding: '16px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderStyle: 'solid',
            borderWidth: '1px',
          }}>
          Add Delivery Locations
        </Button>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Delivery Location</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="street"
            label="Street"
            type="text"
            fullWidth
            variant="outlined"
            value={newAddress.street}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="city"
            label="City"
            type="text"
            fullWidth
            variant="outlined"
            value={newAddress.city}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="state"
            label="State"
            type="text"
            fullWidth
            variant="outlined"
            value={newAddress.state}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="postalCode"
            label="Postal Code"
            type="text"
            fullWidth
            variant="outlined"
            value={newAddress.postalCode}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={newAddress.type}
              onChange={handleChange}
              label="Type"
            >
              <MenuItem value="home">Home</MenuItem>
              <MenuItem value="work">Work</MenuItem>
              <MenuItem value="church">Church</MenuItem>
              <MenuItem value="gym">Gym</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
  }
  
  export default DeliveryLocationComponent;
  