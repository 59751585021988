import React from 'react';
import { withRouter } from '../withRouter';
import InfoPage from '../templates/InfoPage'; // Make sure the file path is correct

function InnovationPage() {
  return (
    <InfoPage
      pageTitle="Pioneering Innovation in Online Food Delivery"
      subtitle="Redefining Convenience and Efficiency Through Technology"
      sections={[
        {
          backgroundColor: "#FFFFFF",
          heading: "Our Innovation Journey",
          text: "At Food Loop, we're not just delivering food – we're reimagining the entire food delivery experience through cutting-edge technology. We're constantly exploring new innovations to enhance convenience, speed, and overall satisfaction for our customers and partners.",
          imageSrc: "/deliveryDriver.svg",
          altText: "Innovation Journey",
        },
        {
          backgroundColor: "#F9F9FF",
          heading: "Smart Delivery Solutions",
          text: "We leverage state-of-the-art algorithms and real-time tracking to optimize our delivery routes. Our smart delivery solutions ensure that your meal arrives at your doorstep fresh and hot, while also minimizing travel time and fuel consumption.",
          imageSrc: "/deliveryWoman.png",
          altText: "Smart Delivery Solutions",
        },
        {
          backgroundColor: "#F3F4FF",
          heading: "Contactless Technology",
          text: "We're at the forefront of contactless technology, allowing you to place orders, make payments, and receive deliveries with minimal physical interaction. Our app features secure payment methods and touchless delivery options to prioritize safety and convenience.",
          imageSrc: "/open-door.svg",
          altText: "Contactless Technology",
        },
        {
          backgroundColor: "#E7E9FB",
          heading: "Join the Innovation Movement",
          text: "Are you excited about technology-driven solutions that redefine convenience? Join Food Loop and be part of a dynamic team that's shaping the future of online food delivery. Together, we can continue to innovate and create experiences that set new standards.",
          imageSrc: "/saladImage.png",
          altText: "Join Us",
          button: {
            text: "Join the Innovation",
          },
        },
      ]}
    />
  );
}

export default withRouter(InnovationPage);
