import React from "react";
import {
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import Navbar from "../components/Navbar";
import ProfitabilitySection from "../components/page-sections/ProfitabilitySection";
import TopTierSection from "../components/page-sections/TopTierSection";
import FavoritesSection from "../components/page-sections/FavoritesSection";
import CompetitiveSection from "../components/page-sections/CompetitiveSection";
import HighlightedBox from "../components/page-sections/HighlightedBox";
import { useNavigate } from 'react-router-dom';

const styles = {
  scrollingBanner: {
    transform: 'translateX(-15px) rotate(5deg)', // Apply the rotation
    width: "110%",
    paddingTop: "50px",
    paddingBottom: "15vh"
  },
  section: {
    marginTop: 50, // space between sections
    marginBottom: 50,
    paddingTop: 30, // space within each section
    paddingBottom: 30,
  },
};

export default function CustomerPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed

  const navigate = useNavigate();

  const navToHome = () => {
    navigate('/home');
  };

  return (
    <>
      <Navbar />
      <ProfitabilitySection
        backgroundColor="#FFFFFF"
        image="/sellingFood.svg"
        headerText="Fresh, Affordable Food Delivered to You"
        subheaderText="Enjoy the best of both worlds: top-quality, fresh food from local restaurants delivered right to your door, without the markups. Download the app now!"
        buttonText="Order"
        handleClick={navToHome}
        showGradient={true}
      />
      <TopTierSection
        backgroundColor='linear-gradient(to bottom right, #2A3890, #0C102A)'
        image="/tomatoes.png"
        mainLine="Top Tier Delivery"
        headerText="Elevate Your Eating with FoodLoop."
        headerColor="#FF4848"
        subheaderText="Dive into the FoodLoop experience – where exceptional meals come at unbeatable prices. Our innovative approach cuts costs, not corners, delivering your favorite meals up to 30% less than the competition. And with our golden seal guarantee, your meal’s integrity is as untampered as your wallet."
        profitIncreaseText="No More Mark-Ups"
        controlMenuText="On-The-Dot Delivery"
        subText1="The price tag you see in-store? That's exactly what you'll pay for delivery. No more massive markups."
        subText2="Your hunger waits for no one. That’s why we’re committed to delivering your meals swiftly, without compromising on freshness."
      />
      <div style={styles.section}>
        <FavoritesSection />
      </div>

      <ProfitabilitySection
        backgroundColor="#FF4848"
        mainLineColor="white"
        bodyColor="#333"
        image="/open-door.svg"
        mainLine="Profitability First"
        headerText="Delivering fresh food right to your doorstep."
        headerColor="#333"
        subheaderText="At FoodLoop, we prioritize your satisfaction by delivering fresh, high-quality food from local restaurants at prices that won't break the bank. With our innovative delivery method and transparent pricing, you can trust that your money is well spent."
        profitIncreaseText="30%"
        controlMenuText="100%"
        subText1="Cheaper food than the other leading food delivery services."
        subText2="Transparency on your order. Know exactly where your money is going."
        buttonText="Order"
        handleClick={navToHome}
        showGradient={true}
      />
      <CompetitiveSection
        title="Elevate Your Business With FoodLoop."
        subtitle="FoodLoop stands as a testament to what's possible when innovation meets understanding. We've crafted a realm where restaurants don't just survive, but flourish."
        items={[
          { src: "tags.svg", title: "Competitive Pricing", description: "At FoodLoop, we are committed to offering unparalleled value. Our competitive pricing strategy averages a 30% cost advantage over our competitors. Consistency is key – the prices you encounter in-store are precisely what you can expect to pay." },
          { src: "overlap.svg", title: "Total Transparency", description: "In an industry often clouded with ambiguous pricing, FoodLoop emerges as a paragon of transparency. The price you encounter in-store remains consistent at the point of delivery. This commitment to clear, straightforward pricing is a testament to our dedication to integrity and customer respect." },
          { src: "delivery.svg", title: "Golden Seal Delivery Method", description: "Our Golden Seal delivery method guarantees that all the food you order is untampered with, ensuring the highest quality and safety standards. With FoodLoop, you can trust that your food will arrive fresh and delicious." },
        ]}
        buttonText="Sign Up"
        image="/rice-bowl.png"
        learnMoreText="Learn More"
      />
      <div style={styles.section}>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginBottom: 100 }}>
          <HighlightedBox
            header="Order Fresh Food Online Now"
            subtitle="Discover the Best Local Food Delivery Experience"
            inputLabel="Your Email"
            buttonText="Subscribe"
            headerColor="white"
            subtitleColor="white"
          />
        </Box>
      </div>
    </>
  );
}
