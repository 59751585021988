import React from 'react';
import ActionPage from '../templates/ActionPage';

function DownloadPage() {
  return (
    <ActionPage
      title="Download Our App"
      description="Download our app to make ordering faster and easier, and receive notifications of promotions or discounts from all your favorite restaurants!"
      imageSrc="/abillion-TefCcU-33MM-unsplash.jpg"
      actionButtons={[
        {
          link: "https://play.google.com/store/apps",
          iconSrc: "playstore.svg",
          altText: "Play Store"
        },
        {
          link: "https://apps.apple.com/us/app/your-app-name/id123456789",
          iconSrc: "appstore.svg",
          altText: "App Store"
        }
      ]}
    />
  );
}

export default DownloadPage;
