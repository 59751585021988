import React from 'react';
import { Container, Paper, Typography, Button } from '@mui/material';

function ActionPage({
  title,
  description,
  imageSrc,
  actionButtons = []
}) {
  return (
    <div
      style={{
        background: "linear-gradient(148deg, rgba(242,159,80,1) 0%, rgba(208,45,58,1) 50%)",
        minHeight: "60vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflowX: "hidden",
        maxWidth: "100vw"
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: { xs: 'column', md: 'row' },
          gap: "16px",
          padding: "20px",
          width: "1000px",
          maxWidth: "75vw"
        }}
      >
        {/* Content Section */}
        <div style={{ flex: "1", padding: "16px", width: "100%" }}>
          <Paper
            elevation={0}
            style={{ padding: "24px", height: "100%", backgroundColor: "transparent", width: "100%" }}
          >
            <Typography
              variant="h2"
              align="left"
              fontWeight={"Bold"}
              color="white"
              gutterBottom
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              align="left"
              paddingBottom="100px"
              color="white"
            >
              {description}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              {actionButtons.map((button, idx) => (
                <Button
                  key={idx}
                  style={{
                    padding: 0,
                    minWidth: 'auto',
                    background: 'transparent',
                    boxShadow: 'none'
                  }}
                  onClick={() => window.open(button.link, "_blank")}
                >
                  <img src={button.iconSrc} alt={button.altText} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </Button>
              ))}
            </div>
          </Paper>
        </div>

        {/* Image Section */}
        <div
          style={{
            flex: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            overflow: "hidden",
            margin: "32px",
            minWidth: "250px",
          }}
        >
          <img
            src={imageSrc}
            alt={imageSrc}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </Container>
    </div>
  );
}

export default ActionPage;
