import React from 'react'
import { Button, TextField, Typography, Box } from '@mui/material'

function SetPassword({ onPasswordSet }) {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')

  const hasUpperCase = /[A-Z]/.test(password)
  const hasLowerCase = /[a-z]/.test(password)
  const hasNumber = /\d/.test(password)
  const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)
  const hasEightCharacters = password.length >= 8

  return (
    <div>
      <TextField
        fullWidth
        margin='normal'
        label='Set Username'
        type='text'
        variant='outlined'
        value={username}
        onChange={e => setUsername(e.target.value)}
      />

      <TextField
        fullWidth
        margin='normal'
        label='Set Password'
        type='password'
        variant='outlined'
        value={password}
        onChange={e => setPassword(e.target.value)}
      />

      <Box mt={2}>
        <Typography
          variant='body1'
          style={{
            marginBottom: '8px',
            color: hasEightCharacters ? 'green' : 'red',
          }}>
          {hasEightCharacters ? '✔' : '✖'} At least 8 characters long
        </Typography>
        <Typography
          variant='body1'
          style={{
            marginBottom: '8px',
            color: hasUpperCase ? 'green' : 'red',
          }}>
          {hasUpperCase ? '✔' : '✖'} Contains an uppercase letter
        </Typography>
        <Typography
          variant='body1'
          style={{
            marginBottom: '8px',
            color: hasLowerCase ? 'green' : 'red',
          }}>
          {hasLowerCase ? '✔' : '✖'} Contains a lowercase letter
        </Typography>
        <Typography
          variant='body1'
          style={{ marginBottom: '8px', color: hasNumber ? 'green' : 'red' }}>
          {hasNumber ? '✔' : '✖'} Contains a number
        </Typography>
        <Typography
          variant='body1'
          style={{
            marginBottom: '8px',
            color: hasSpecialChar ? 'green' : 'red',
          }}>
          {hasSpecialChar ? '✔' : '✖'} Contains a special character
        </Typography>
      </Box>

      <Button
        variant='outlined'
        style={{ marginTop: '32px' }}
        fullWidth
        onClick={() => onPasswordSet({username: username, password: password})}>
        Create Account
      </Button>
    </div>
  )
}

export default SetPassword
