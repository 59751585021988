import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import Box from '@mui/material/Box';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AccordionComponent({ accordionItems }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const styles = {
    subheader: {
      fontSize: '18px', // Default font size for larger screens (lg)
      maxWidth: '90%',
        '@media (max-width: 1200px)': {
          fontSize: '16px', // Adjust for medium screens (md)
        },
        '@media (max-width: 992px)': {
          fontSize: '14px', // Adjust for small screens (sm)
        },
        '@media (max-width: 768px)': {
          fontSize: '12px', // Adjust for extra-small screens (xs)
        },
    },
  };
  return (
    <Box sx={{paddingTop: '10vh', paddingLeft: '5vw', paddingBottom: '5vh'}}>
      {accordionItems.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{ marginBottom: 1, minHeight: 75, boxShadow: 'none'}}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}bh-content`} id={`panel${index}bh-header`}>
            <Typography sx={{ ...styles.subheader, fontWeight: 'bold' }}>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ ...styles.subheader}}>{item.content}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

