import React from "react";
import Navbar from "../components/Navbar";
import DollarsSection from "../components/page-sections/DollarsComponent";
import ProfitabilitySection from "../components/page-sections/ProfitabilitySection";
import ProfitsSection from "../components/page-sections/ProfitsSection";
import PricingSection from "../components/page-sections/PricingSection";
import PocketSection from "../components/page-sections/PocketSection";
import QuestionSection from "../components/page-sections/QuestionSection";
import { useNavigate } from 'react-router-dom';

const indexedTextEntries = ['Fast Delivery Times', 'Seamless Order Management', 'Competitive Pricing', 'Untampered Food Guarantee', 'Transparent Cost Breakdown', 'Support Local Families', 'Reliable Delivery Drivers', '24/7 Customer Support', 'Complete Control', 'Flexible Delivery Options' ];
const ListEntries = [{ header: 'Clear Pricing Structure', content: 'No hidden costs. Just straightforward billing allowing you to put more money in your pocket.' },
{ header: 'Food Integrity Insurance', content: 'Rest easy knowing your food remains untampered with our Golden Seal delivery method.' },
{ header: 'Your Menu, Your Terms', content: 'Complete control of your menu without any surcharges, allowing you to set your own profit margins without ridiculous markups.' },];

const cardData = [
  {
    iconImage: '/YellowRocket.svg',
    header: 'Skyrocket Your Revenue',
    body: 'Experience earnings that overshadow what competitors offer, because with us, every dish ordered is another boost to your bottom line.',
  },
  {
    iconImage: '/RedHandshake.svg',
    header: 'No Hidden Fees, Pure Transparency',
    body: 'Say goodbye to the fine print and unexpected deductions. Our model is as clear as freshly cleaned glass. With FoodLoop, what you see is what you get.',
  },
  {
    iconImage: '/BlueDirections.svg',
    header: 'Full Command Over Your Operations',
    body: 'Your restaurant, your rules. Retain full control over your menu, pricing, and promotions. We empower you to run your delivery service your way.',
  },
];


export default function RestaurantPage() {

  const navigate = useNavigate();

  const navToRestSignUp = () => {
    navigate('/business');
  };

  return (
    <>
      <Navbar />
      <DollarsSection
        image = "/MoneyPlate.png"
        title="Food Delivery No Longer Makes Sense...
        It Makes Dollars"
        subtitle="Good food deserves good profits. With FoodLoop, you can elevate your digital presence without the side dish of decreased margins. Increase profits, increase margins, & take back control of your menu & prices."
        buttonText="Sign Up"
        handleClick={navToRestSignUp}
      />
      <ProfitabilitySection
        backgroundColor="#FFFFFF"
        mainLineColor="#FF4848"
        bodyColor = "#333"
        image = "/two-chefs.png"
        mainLine="Profitability First"
        headerText="Boost Your Bottom Line Like Never Before."
        subheaderText="FoodLoop isn't just about delivery – it's about amplifying your profits. As each dish leaves your kitchen, know that it's backed by a delivery model that emphasizes your margins, with zero surprise fees."
        profitIncreaseText="30%"
        controlMenuText="100%"
        subText1="Increase in average Profit Margins*"
        subText2="Control over your menu"
        handleClick={navToRestSignUp}
      />
      <ProfitsSection
        titleText="FoodLoop"
        subtitleText="Unlock Unprecedented Profits"
        cardData={cardData}
      />
      <PricingSection
        superHeader="Transparency"
        transparencyHeader="Simple Pricing"
        transparencyText="Through our innovative subscription model, we’ve eliminated per-order fees & given the control back to the restaurants, allowing boosted profitability and operational streamlining."
        price="$1500 / month"
        elitePlanTexts={["Elite Plan", "Affordable and Reliable Delivery Service."]}
        indexedTextEntries={indexedTextEntries}
        listEntries={ListEntries}
        handleClick={() => console.log("Navigating to restaurant sign-up...")}
        handleLearnMore={() => console.log("Navigating to learn more...")}
      />
      <PocketSection
        image = "/oyster-guy.png"
        title="More Money In Your Pocket"
        subtitle="The game has changed, and FoodLoop is leading the charge. By replacing outdated per-order fees with our dynamic subscription model, we're redefining delivery. Experience heightened profitability and unlimited order opportunities & step into the new age of food service."
      />
      <QuestionSection
        sectionTitle="Profitability First"
        mainTitle="Questions"
        subTitle="Find answers to common questions and concerns about partnering with FoodLoop."
        arrowsImageAlt="arrows"
      />
    </>
  );
}
