import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Rating} from '@mui/material';

const styles = {
root: {
    display: 'flex',
    width: '10%',
    marginBottom: 16,
},
content: {
    flex: '1 0 auto',
},
avatar: {
    width: 45,
    height: 45,
    borderRadius: '50%',
},
rating: {
    marginTop: 8,
},
};

const ReviewCard = ({ quote, name, profileImage, starReview }) => {

  return (
    <Card style={{margin: '5vw 0 5vw 5vw'}}>
      <CardContent>
        <img src='/quotes.svg' alt='quote' style = {{width: '24px', height: 'auto'}} />
        <Typography variant="body1" component="div" style = {{paddingBottom: '50px'}}>
          {quote}
        </Typography>
        <div style = {{display: 'flex', flexDirection: 'row'}}>
            <CardMedia
            component="img"
            alt={name}
            image={profileImage}
            style = {styles.avatar}
            />
            <div style = {{paddingLeft: '15px'}}>
                <Typography variant="subtitle1" color="black" fontWeight="bold">
                {name}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                    Happy Customer
                </Typography>
                <Box className={styles.rating}>
                <Rating name="star" value={starReview} max={5} precision={0.5} readOnly />
                </Box>
            </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ReviewCard;
