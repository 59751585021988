import React from 'react';
import { withRouter } from '../withRouter';
import InfoPage from '../templates/InfoPage';

function CareersPage() {
  return (
    <InfoPage
      pageTitle="Join Our Passionate Team"
      subtitle="Deliver Delicious Moments to Hungry Hearts"
      sections={[
        {
          backgroundColor: "#FFFFFF",
          heading: "Why Choose a Career With Us?",
          text: "Fuel Your Future with Flexible Hours, Competitive Pay, and More!",
          imageSrc: "/paolo-feser-1MFqJzNUwOM-unsplash.jpg",
          altText: "Food Image 1"
        },
        {
          backgroundColor: "#F9F9FF",
          heading: "Craft Meaningful Connections",
          text: "At the heart of our company lies a passion for creating connections. Join us to impact lives, one delivery at a time.",
          imageSrc: "/szymon-fischer-9Cwg1ym3SCs-unsplash.jpg",
          altText: "Food Image 2"
        },
        {
          backgroundColor: "#F3F4FF",
          heading: "Grow Your Potential",
          text: "Every day is a chance to grow. Be part of a team that values your growth, empowering you with opportunities and skills.",
          imageSrc: "/loes-klinker-vLQY3beH2h0-unsplash.jpg",
          altText: "Food Image 3"
        },
        {
          backgroundColor: "#E7E9FB",
          heading: "Enjoy Rewards Beyond Delivery",
          text: "Join our family and relish discounts, rewards, and a supportive community that extends beyond the workplace.",
          imageSrc: "/shri-Q4CX55VsBP0-unsplash.jpg",
          altText: "Food Image 4",
          button: {
            text: "Take the First Step Toward a Rewarding Career"
          }
        }
      ]}
    />
  );
}

export default withRouter(CareersPage);
