import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  styled,
  Typography,
} from '@mui/material';
import restaurantStore from '../stores/RestaurantStore';
import { useNavigate } from 'react-router-dom';

const CardWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(4),
  backdropFilter: 'blur(8px)',
  zIndex: 1,
}));

const BackgroundImage = styled('img')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: -1,
});

function BusinessPage() {
  const navigate = useNavigate();
  const [businessInfo, setBusinessInfo] = useState({
    name: '',
    address: { street: '', city: '', line2: '', state: '', postalCode: '', country: '' },
    email: '',
    phoneNumber: '',
    description: '',
    primaryContactInfo: { name: '', email: '', phoneNumber: '' },
    corporateEntity: { federalTaxId: '', name: '' }
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBusinessInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleSubChange = (type, event) => {
    setBusinessInfo({
      ...businessInfo,
      [type]: {
        ...businessInfo[type], [event.target.name]: event.target.value
      }
    })
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const ownerInfo = {
      ...businessInfo.primaryContactInfo,
    }

    const corporateEntity = {
      ...businessInfo.corporateEntity,
      address: {
        ...businessInfo.address,
        country: 'USA'
      }
    }

    businessInfo.ownerInfo = ownerInfo;
    businessInfo.corporateEntity = corporateEntity;
    
    console.log('Form submitted with:', businessInfo);

    restaurantStore.createRestaurant(businessInfo).then((response) => {
      console.log(response);
      navigate('/home');
    }, (error) => {
      console.log(error);
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <BackgroundImage
        src="/restaurantPageImage.png"
        alt="An image"
      />
      <Typography variant='h1' sx={{ margin: "72px auto" }}>
        Become a Food Loop Business Partner
      </Typography>
      <Typography variant='h5' sx={{ margin: "8px auto" }}>
        Fill out the form below to get started with Food Loop.
      </Typography>
      <Container maxWidth="md">
        <CardWrapper>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Business Name"
                    variant="outlined"
                    name="name"
                    value={businessInfo.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Street Address"
                    variant="outlined"
                    name="street"
                    value={businessInfo.address.street}
                    onChange={(event) => handleSubChange('address', event)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Address Line 2 (Optional)"
                    variant="outlined"
                    name="line2"
                    value={businessInfo.address.line2}
                    onChange={(event) => handleSubChange('address', event)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="City"
                    variant="outlined"
                    name="city"
                    value={businessInfo.address.city}
                    onChange={(event) => handleSubChange('address', event)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="State"
                    variant="outlined"
                    name="state"
                    value={businessInfo.address.state}
                    onChange={(event) => handleSubChange('address', event)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Postal Code"
                    variant="outlined"
                    name="postalCode"
                    value={businessInfo.address.postalCode}
                    onChange={(event) => handleSubChange('address', event)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Country"
                    variant="outlined"
                    name="country"
                    value={businessInfo.address.country}
                    onChange={(event) => handleSubChange('address', event)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Business Email"
                    variant="outlined"
                    type="email"
                    name="email"
                    value={businessInfo.email}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    type="tel"
                    name="phoneNumber"
                    value={businessInfo.phoneNumber}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Primary Contact Name"
                    variant="outlined"
                    name="name"
                    value={businessInfo.primaryContactInfo.name}
                    onChange={(event) => handleSubChange('primaryContactInfo', event)}
                    fullWidth
                    required
                  /> 
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Primary Contact Email"
                    variant="outlined"
                    type="email"
                    name="email"
                    value={businessInfo.primaryContactInfo.email}
                    onChange={(event) => handleSubChange('primaryContactInfo', event)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Primary Contact Phone Number"
                    variant="outlined"
                    type="tel"
                    name="phoneNumber"
                    value={businessInfo.primaryContactInfo.phoneNumber}
                    onChange={(event) => handleSubChange('primaryContactInfo', event)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Corporate Entity Name"
                    variant="outlined"
                    name="name"
                    value={businessInfo.corporateEntity.name}
                    onChange={(event) => handleSubChange('corporateEntity', event)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Federal Tax ID"
                    variant="outlined"
                    name="federalTaxId"
                    value={businessInfo.corporateEntity.federalTaxId}
                    onChange={(event) => handleSubChange('corporateEntity', event)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Business Description"
                    variant="outlined"
                    name="description"
                    value={businessInfo.description}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={20}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    type="submit"
                    style={{ marginTop: '10px', marginLeft: 0 }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </CardWrapper>
      </Container>
    </div>
  );
}

export default BusinessPage;

