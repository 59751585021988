import React, { useState } from 'react'
import {
  Grid,
  Autocomplete,
  TextField,
  Chip,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Button,
  Box,
} from '@mui/material'
import FastFoodIcon from '@mui/icons-material/Fastfood'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

function FoodPreferences({ confirm }) {
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedStores, setSelectedStores] = useState([])

  const categories = ['Pizza', 'Burger', 'Salad', 'Ice Cream', 'Sushi']
  const allergies = ['Gluten', 'Nuts', 'Dairy', 'Shellfish', 'Eggs']
  const dietTypes = ['Vegan', 'Paleo', 'Vegetarian', 'Keto']

  const combinedOptions = [...categories, ...allergies, ...dietTypes]

  const restaurantData = [
    {
      name: "McDonald's",
      time: '15 mins',
      fee: '$0 delivery fee',
      image: '/mcdonalds.jpg',
    },
    {
      name: 'Burger King',
      time: '20 mins',
      fee: '$0 delivery fee',
      image: '/burger-king.jpg',
    },
    {
      name: 'Pizza Hut',
      time: '25 mins',
      fee: '$0 delivery fee',
      image: '/pizza-hut.jpg',
    },
    {
      name: "Dunkin' Donuts",
      time: '13 mins',
      fee: '$0 delivery fee',
      image: '/dunkin-donuts.jpg',
    },
    {
      name: 'Wing Stop',
      time: '33 mins',
      fee: '$0 delivery fee',
      image: '/wing-stop.jpg',
    },
    {
      name: 'Popeyes',
      time: '22 mins',
      fee: '$0 delivery fee',
      image: '/popeyes.jpg',
    },
  ]

  const handleStoreSelect = store => {
    if (selectedStores.includes(store.name)) {
      setSelectedStores(prevStores => prevStores.filter(s => s !== store.name))
    } else {
      setSelectedStores(prevStores => [...prevStores, store.name])
    }
  }

  return (
    <div>
      <Typography variant='h6' style={{ marginTop: '1rem' }}>
        Categories of Food
      </Typography>
      <Autocomplete
        multiple
        id='categories'
        options={categories}
        value={selectedCategories.filter(option => categories.includes(option))}
        onChange={(_, newValue) => setSelectedCategories(newValue)}
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            placeholder='Pizza, Burger...'
          />
        )}
      />

      <Typography variant='h6' style={{ marginTop: '1rem' }}>
        Food Allergies
      </Typography>
      <Autocomplete
        multiple
        id='allergies'
        options={allergies}
        value={selectedCategories.filter(option => allergies.includes(option))}
        onChange={(_, newValue) =>
          setSelectedCategories(prev => [
            ...prev.filter(option => !allergies.includes(option)),
            ...newValue,
          ])
        }
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            placeholder='Gluten, Nuts...'
          />
        )}
      />

      <Typography variant='h6' style={{ marginTop: '1rem' }}>
        Diet Types
      </Typography>
      <Autocomplete
        multiple
        id='diet-types'
        options={dietTypes}
        value={selectedCategories.filter(option => dietTypes.includes(option))}
        onChange={(_, newValue) =>
          setSelectedCategories(prev => [
            ...prev.filter(option => !dietTypes.includes(option)),
            ...newValue,
          ])
        }
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            placeholder='Vegan, Paleo...'
          />
        )}
      />

      <Typography
        variant='h5'
        style={{ marginTop: '2rem', marginBottom: '1rem' }}>
        I prefer places like...
      </Typography>

      <Grid container spacing={3} style={{ marginTop: '1rem' }}>
        {restaurantData.map((store, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Card
              elevation={selectedStores.includes(store.name) ? 12 : 3}
              sx={{ borderRadius: 5, backgroundColor: 'white' }}>
              <CardActionArea onClick={() => handleStoreSelect(store)}>
                <Box position='relative'>
                  <CardMedia
                    component='img'
                    height='140'
                    image={store.image}
                    alt={store.name}
                    sx={{
                      filter: selectedStores.includes(store.name)
                        ? 'brightness(0.6)'
                        : 'none',
                    }}
                  />
                  {selectedStores.includes(store.name) && (
                    <Box
                      position='absolute'
                      top={0}
                      left={0}
                      p={1}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'>
                      <CheckCircleOutlineIcon
                        color='success'
                        fontSize='large'
                      />
                    </Box>
                  )}
                </Box>
                <CardContent>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography variant='h6'>{store.name}</Typography>
                    <FastFoodIcon sx={{ color: 'black' }} />
                  </div>
                  <Typography variant='body2'>{store.time}</Typography>
                  <Typography variant='body2'>{store.fee}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
        <Button
            variant="outlined"
            color="primary"
            fullWidth // This makes the button take up the full width of its parent container
            sx={{ 
              marginTop: '2rem',
              maringLeft: '16px',
              borderRadius: '50px' // This makes the button rounded
            }}
            onClick={() => {
              // Handle your button click event here
              confirm();
            }}
          >
            Confirm Selection
          </Button>
      </Grid>
    </div>
  )
}

export default FoodPreferences
