import React from 'react';
import InfoPage from '../templates/InfoPage';
function CulinaryExperiencesPage() {
  return (
    <InfoPage
      pageTitle="Celebrate Culinary Experiences"
      subtitle="Explore Flavors, Stories, and Connections"
      sections={[
        {
          backgroundColor:"#FFFFFF",
          heading:"Our Journey",
          text:"Embark on a journey through the diverse and flavorful world of culinary experiences. Discover the stories behind the dishes and the communities that make them special.",
          imageSrc:"/girlChair.svg",
          altText:"Journey Image",
          },
        {
          backgroundColor:"#F9F9FF",
          heading:"Commissary Kitchens",
          text:"Our state-of-the-art commissary kitchens offer a space for aspiring chefs and food entrepreneurs to craft their creations. Equipped with top-notch amenities and utilities.",
          imageSrc:"/groceriesImage.png",
          altText:"Kitchen Image",
        },
        {
          backgroundColor:"#F3F4FF",
          heading:"Nourishing Knowledge",
          text:"Empower yourself with nutritional insights and culinary skills. Join our educational sessions and workshops to enhance your culinary prowess.",
          imageSrc:"/iceCreamImage.png",
          altText:"Knowledge Image",
        },
        {
          backgroundColor:"#E7E9FB",
          heading:"Savor the Flavors",
          text:"Delight in our selection of delis and pantries, offering an array of packaged goods and beverages. Experience the authentic tastes that culinary traditions bring.",
          imageSrc:"/salads.png",
          altText:"Deli Image",
          button: {
            text: "Explore Our Offerings"
          }
        }
      ]}
    />
  );
}

export default CulinaryExperiencesPage;
