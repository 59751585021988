import React from "react";
import { Box, Typography, Button, Container, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

function PartnersPage() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        background:
          "linear-gradient(148deg, rgba(242,159,80,1) 0%, rgba(208,45,58,1) 50%)",
        minHeight: "100vh",
        paddingTop: "5vh",
        paddingBottom: "5vh",
      }}
    >
      <Container maxWidth="lg">
        {/* Header Section */}
        <Paper
          elevation={0}
          sx={{ p: 3, mb: 3, backgroundColor: "transparent" }}
        >
          <Typography
            variant="h2"
            gutterBottom
            align="left"
            fontWeight={"Bold"}
            color="white"
          >
            Our Technology Partners
          </Typography>
          <Typography variant="h5" gutterBottom align="left" color="white">
            Always on the lookout for innovative collaborations.
          </Typography>
        </Paper>

        {/* Main Content Section */}
        <Paper
          elevation={0}
          sx={{ p: 3, mb: 3, backgroundColor: "transparent" }}
        >
          <Typography variant="body1" mt={3} mb={5} align="left" color="white">
            If you have an ordering platform, a food line system, a delivery
            system, or a POS system, we are eager to connect. At Food Loop, we
            believe in the power of partnership to create a difference. We
            cherish values that revolve around the well-being of our customers,
            supporting drivers to earn a respectable living, and enabling
            restaurants to thrive.
          </Typography>

          <Typography variant="h6" gutterBottom align="left" color="white">
            Do you share our values?
          </Typography>
          <Typography variant="body1" mb={4} align="left" color="white">
            If you care about:
            <ul>
              <li>Keeping food affordable for all</li>
              <li>Empowering drivers to earn a sustainable income</li>
              <li>Supporting restaurants in their journey</li>
            </ul>
            ... then we'd love to hear from you.
          </Typography>
        </Paper>

        {/* CTA Section */}
        <Paper elevation={0} sx={{ p: 3, backgroundColor: "transparent" }}>
          <Button
              variant="outlined"
              sx={{
                color: "white",
                borderColor: "white",
                marginTop: 2,
                "&:hover": {
                  backgroundColor: "rgba(255,255,255,.45)",
                },
                "&:focus": {
                  backgroundColor: "rgba(255,255,255,.45)",
                },
              }}
            onClick={() => navigate("/contact")}
          >
            Send Us a Message
          </Button>
        </Paper>
      </Container>
    </div>
  );
}

export default PartnersPage;
