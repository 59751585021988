import React from "react";
import Navbar from "../components/Navbar";
import DollarsSection from "../components/page-sections/DollarsComponent";
import ProfitabilitySection from "../components/page-sections/ProfitabilitySection";
import EmployeeSection from "../components/page-sections/EmployeesSection";
import CompetitiveSection from "../components/page-sections/CompetitiveSection";
import { useNavigate } from 'react-router-dom';

const indexedTextEntries = ['Fast Delivery Times', 'Seamless Order Management', 'Competitive Pricing', 'Untampered Food Guarantee', 'Transparent Cost Breakdown', 'Support Local Families', 'Reliable Delivery Drivers', '24/7 Customer Support', 'Complete Control', 'Flexible Delivery Options' ];
const ListEntries = [{ header: 'Clear Pricing Structure', content: 'No hidden costs. Just straightforward billing allowing you to put more money in your pocket.' },
{ header: 'Food Integrity Insurance', content: 'Rest easy knowing your food remains untampered with our Golden Seal delivery method.' },
{ header: 'Your Menu, Your Terms', content: 'Complete control of your menu without any surcharges, allowing you to set your own profit margins without ridiculous markups.' },];

const cardData = [
  {
    iconImage: '/profile-image-01.png',
    header: 'John Doe',
    subheader: 'CEO',
    body: 'I handle organization of everything',
  },
  {
    iconImage: '/profile-image-01.png',
    header: 'Jerry Donald',
    subheader: 'CFO',
    body: 'I handle Finances',
  },{
    iconImage: '/profile-image-01.png',
    header: 'Joanna Donovan',
    subheader: 'CTO',
    body: 'I handle the technology',
  },{
    iconImage: '/profile-image-01.png',
    header: 'Jackson Dorry',
    subheader: 'CMO',
    body: 'I handle the marketing',
  },{
    iconImage: '/profile-image-01.png',
    header: 'Josie Duncan',
    subheader: 'COO',
    body: 'I handle operations',
  },{
    iconImage: '/profile-image-01.png',
    header: 'Jim Duffy',
    subheader: 'CLO',
    body: 'I handle Legal issues',
  },
];


export default function OurStoryPage() {

  const navigate = useNavigate();

  const downloadApp = () => {
    // Download App
    console.log("Coming soon...")
  }

  return (
    <>
      <Navbar />
      <DollarsSection
        image = "/salads.png"
        title="Delivering Delicious Food, One Loop at a Time"
        subtitle="At FoodLoop, our mission is to connect restaurants, drivers, and customers in a seamless and mutually beneficial way. We believe in supporting local businesses, empowering drivers, and providing savings for our valued customers."
        buttonText="Download"
        handleClick={downloadApp}
      />
      <ProfitabilitySection
        backgroundColor="#FFFFFF"
        mainLineColor="#FF4848"
        bodyColor = "#333"
        image = "/campaign-creators-qCi_MzVODoU-unsplash.jpg"
        headerText="Our Founders and the FoodLoop Story"
        subheaderText="FoodLoop was founded by a group of passionate individuals who saw an opportunity to revolutionize the food delivery industry. Frustrated with the lack of transparency and fairness, they set out to create a platform that benefits restaurants, drivers, and customers alike."
        buttonText="Learn More"
        handleClick={() => console.log("Coming soon...")}
        showGradient={true}
      />
      {/* <EmployeeSection
        titleText="Innovators"
        subtitleText="Meet Our Team"
        cardData={cardData}
      /> */}
      <CompetitiveSection
        title="Elevate Your Business With FoodLoop."
        subtitle="FoodLoop stands as a testament to what's possible when innovation meets understanding. We've crafted a realm where restaurants don't just survive, but flourish."
        items={[
          { src: "community.svg", title: "Community Emphasis", description: "FoodLoop places a strong emphasis on building and supporting local communities. We believe in not only providing a convenient food delivery service, but also in ensuring that the benefits of our platform trickle down to the community." },
          { src: "focus.svg", title: "Local Focus", description: "Located in the Austin area, FoodLoop is providing a hyper-localized experience for both restaurants and customers. By concentrating our efforts in one location, we can better understand and cater to the unique needs of the community." },
          { src: "overlap.svg", title: "Transparency & Fairness", description: "At FoodLoop, we believe in clear and straightforward pricing for customers. We also prioritize earnings stability and predictability for our drivers, ensuring that they are fairly compensated for their hard work." },
        ]}
        image = "/aid-box.png"
        buttonText="Sign Up"
        learnMoreText="Learn More"
      />
      <ProfitabilitySection
        backgroundColor="#FF4848"
        mainLineColor="white"
        bodyColor = "#333"
        image = "/scooter-delivery.svg"
        headerText="FoodLoop's Impact on the Community, Drivers, and Restaurants"
        headerColor="#333"
        subheaderText="FoodLoop eliminates corporate greed, giving back to the community one meal at a time. "
        profitIncreaseText="30%"
        controlMenuText="100%"
        subText1="Of the orders that come through FoodLoop help to give back to a local family in need. "
        subText2="Increase in revenue for restaurants and drivers, while saving the customer an additional 30%. "
        buttonText="Download"
        handleClick={downloadApp}
        showGradient={true}
      />
    </>
  );
}