import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Autocomplete } from '@mui/material';
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import ChurchIcon from "@mui/icons-material/LocationCity"; 
import GymIcon from "@mui/icons-material/FitnessCenter";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";

function UserDetails({ onSaveDetails, onSkipDetails, existingEmail = '', existingPhone = '', addresses = [] }) {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState(existingEmail);
  const [phoneNumber, setPhoneNumber] = React.useState(existingPhone);
  const [addressQuery, setAddressQuery] = useState("");
  const [addressOptions, setAddressOptions] = useState([]);

  useEffect(() => {
    if (addressQuery && addressQuery.length >= 3) {
      const fetchAddresses = async () => {
        const mapboxUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          addressQuery
        )}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
        const response = await fetch(mapboxUrl);
        const data = await response.json();

        if (data.features) {
          setAddressOptions(data.features.map((feat) => feat.place_name));
        }
      };

      fetchAddresses();
    }
  }, [addressQuery]);

  const renderIcon = (type) => {
    switch (type) {
      case "home":
        return <HomeIcon />;
      case "work":
        return <WorkIcon />;
      case "church":
        return <ChurchIcon />;
      case "gym":
        return <GymIcon />;
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          margin='normal'
          label='First Name'
          variant='outlined'
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          margin='normal'
          label='Last Name'
          variant='outlined'
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          margin='normal'
          label='Email'
          variant='outlined'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          margin='normal'
          label='Phone Number'
          variant='outlined'
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          style={{ width: "100%" }}
          onInputChange={(event, newInputValue) => {
            setAddressQuery(newInputValue);
          }}
          options={addressOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Address Search"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <PlaceOutlinedIcon
                    style={{
                      color: "rgba(0,0,0,0.54)", // default icon color for mui
                      marginRight: '8px'
                    }}
                  />
                ),
              }}
            />
          )}
        />
      </Grid>

      {addresses.map((address, index) => (
        <Grid item xs={12} md={6} key={index}>
          <Button
            variant="outlined"
            startIcon={renderIcon(address.type)}
            fullWidth
          >
            {address.location}
          </Button>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Button 
          variant="outlined" 
          color="secondary" 
          fullWidth 
          onClick={() => onSaveDetails({
            firstName,
            lastName,
            email,
            phoneNumber
          })}
        >
          Save Details
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Button 
          variant="outlined" 
          color="primary" 
          fullWidth 
          onClick={() => onSkipDetails(null)}
        >
          Skip for Now
        </Button>
      </Grid>
    </Grid>
  );
}

export default UserDetails;