import React from 'react';
import { Container, Grid, Paper, Typography, Card, CardMedia } from '@mui/material';

const ContentSectionAlt = ({ backgroundColor, heading, text, imageSrc, altText }) => {
    return (
      <div style={{backgroundColor, height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: 'none' }}>
                    <Card>
                    <CardMedia
                        component="img"
                        image={imageSrc}
                        alt={altText}
                        style={{ width: '800px', height: '500px' }}
                    />
                    </Card>
                </Grid>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Paper elevation={0} sx={{ p: 3, height: '100%', backgroundColor, boxShadow: 'none' }}>
                <Typography variant="h2" align="left" fontWeight={"Bold"} gutterBottom>
                  {heading}
                </Typography>
                <Typography variant="body1" align="left">
                  {text}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
};

export default ContentSectionAlt;