import React, { useState, useEffect } from 'react'
import {
  Container,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Snackbar,
  styled,
} from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import authStore from '../stores/AuthStore'

const CardWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(4),
  backdropFilter: 'blur(8px)',
  zIndex: 1,
}))

function ResetPasswordPage() {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState('')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const resetToken = urlParams.get('token')
    if (resetToken) {
      setToken(resetToken)
    } else {
      navigate('/') // Redirect if no token is found
    }
  }, [location.search, navigate])

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      setSnackbarMessage('Passwords do not match')
      setIsSnackbarOpen(true)
      return
    }
    setIsLoading(true)
    try {
      await authStore.resetPassword(password, token)
      setSnackbarMessage('Password reset successfully')
      setIsSnackbarOpen(true)
      // Redirect or additional actions after successful reset
    } catch (error) {
      setSnackbarMessage('Error resetting password')
      setIsSnackbarOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false)
  }

  return (
    <div
      style={{
        backgroundImage:
          'url(/pexels-jonathan-borba-2983101.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Container maxWidth='sm' style={{ marginTop: '10vh' }}>
        <CardWrapper>
          <CardContent>
            <Typography variant='h5' align='center' gutterBottom>
              Reset Password
            </Typography>
            <TextField
              fullWidth
              margin='normal'
              label='New Password'
              variant='outlined'
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <TextField
              fullWidth
              margin='normal'
              label='Confirm Password'
              variant='outlined'
              type='password'
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
            <Button
              fullWidth
              style={{ marginTop: '16px', borderRadius: '25px' }}
              onClick={handleResetPassword}
              variant='contained'
              color='primary'
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Reset Password'}
            </Button>
          </CardContent>
        </CardWrapper>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </Container>
    </div>
  )
}

export default ResetPasswordPage
