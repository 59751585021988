import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import ButtonBase from '@mui/material/ButtonBase';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import FastFoodIcon from "@mui/icons-material/Fastfood";

function CardComponent({ to, imageSrc, title}) {
  const theme = useTheme();
  const imageUrl = imageSrc || '/FoodLoopLogo.png';
  const handleClick = () => {
  };
  return (
    <div style={{ margin: '25px' }}>
      <Link to={to} style={{ textDecoration: 'none' }}>
      <ButtonBase
      onClick={handleClick}
      sx={{
        width: "100px",
        textDecoration: "none",
        borderRadius: '18px'
      }}>
      <Card
        elevation={0}
        sx= {{borderRadius: '18px', backgroundColor: '#FFF', width: '300px'}}
      >
        <CardMedia
          component="img"
          height="100px"
          image={imageUrl}
          alt={title}
          style={{ width: "100px", borderRadius: '18px' }}
        />
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" style={{ color: '#333', fontWeight: 'bold', textAlign: 'left'}}>{title}</Typography>
          </div>
        </CardContent>
      </Card>
    </ButtonBase>
      </Link>
    </div>
  );
}

export default CardComponent;