import React from "react";
import Button from "@mui/material/Button";
import {
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
  Box
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const styles = {

    root: {
      width: "100%",
      display: 'flex',
      alignItems: 'center',
      padding: '20px',
      background: '#FEFAF0',
    },
    image: {
      width: '30vw',
      paddingLeft: '15vw',
      paddingRight: '5vw',
      height: 'auto',
      marginRight: '16px',
    },
    content: {
      flex: 1,
    },
    header: {
      fontWeight: 'bold',
      fontSize: {
        xs: '30px',
        sm: '40px',
        md: '50px',
        lg: '60px',
      },
    },
    subheader: {
      marginTop: '16px',
      marginBottom: '32px',
      minWidth: '450px',
      fontSize: {
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
      },
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
    },
    listButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    checkIcon: {
      marginRight: '8px',
      color: '#FF4848',
    },
  };

  function ElevateSection({ title, subtitle, items, buttonText, learnMoreText, handleButton, handleLearnMore }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
      <Box sx={{ backgroundColor: '#FFFFFF', minHeight: '85vh', paddingLeft: {xs: '10px', md: '50px'} }}>
        <div style={{
          ...styles.root,
          backgroundColor: '#FFFFFF',
          overflow: 'hidden',
          minHeight: '80vh',
          maxWidth: '100%',
          flexDirection: isMobile ? "column" : "row",
        }}>
          <div style={{ ...styles.content }}>
            <Typography variant="h1" sx={styles.header}>
              {title}
            </Typography>
            <Typography variant="body1" sx={styles.subheader}>
              {subtitle}
            </Typography>
            <List>
              {items.map((item, index) => (
                <ListItem key={index} style={styles.listItem}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <Typography variant="body1" style={{ color: '#FF4848', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        <CheckCircleIcon style={{ ...styles.checkIcon, color: '#FF4848' }} />
                        {item.title}
                      </Typography>
                      <Typography variant="body1" style={{ color: '#808080' }}>
                        {item.description}
                      </Typography>
                    </div>
                  </div>
                </ListItem>
              ))}
              <ListItem style={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: '50px'
              }}>
                <Button
                  color='inherit'
                  variant='outlined'
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    height: '50px',
                    width: '30%',
                    borderRadius: '8px',
                    borderWidth: '1px',
                  }}
                  onClick={handleButton}
                >
                  {buttonText || "Sign Up"}
                </Button>
                <Button
                  variant="text"
                  endIcon={<ArrowForwardIcon />}
                  style={{ color: 'black', margin: '0 0 0 20px', height: '50px', fontWeight: 'bold' }}
                  onClick={handleLearnMore}
                >
                  {learnMoreText || "Learn More"}
                </Button>
              </ListItem>
            </List>
          </div>
          {isMobile && (
          <img
            src="/foodGrid.png"
            alt="FoodLoop"
            style={{
              ...styles.image,
              width: "80%",
              height: "auto",
              order: 2,
            }}
          />
        )}
        {!isMobile && (
          <img
          src="/foodGrid.png"
          alt="FoodLoop"
          style={{ ...styles.image, width: '35vw' }}
          />
        )}
        </div>
      </Box>
    );
  }

  export default ElevateSection;