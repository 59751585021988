import React, { useState, useEffect } from 'react';
import { withRouter } from '../withRouter'; 
import { Accordion, AccordionSummary, AccordionDetails, Container, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQPage() {
      // Assuming you fetch this data from an API or database
  const [faqs, setFaqs] = useState([
    
    {
        question: "What is Food Loop ?",
        answer: "Food Loop is not just another tech company! We're the cool folks connecting hungry people with yummy food. And guess what? We don’t snatch a cut from your hard-earned cash. It’s all about making deliveries fun, affordable, and awesome for everyone involved!"
      },
      {
        question: "How can Food Loop make my business sizzle?",
        answer: "Food Loop is like the secret sauce to your burger! We help you connect with foodies, give your brand a spotlight dance online, and let your business grow without nibbling at your earnings. Pretty cool, right?"
      },
      {
        question: "Is Food Loop stirring the pot in my area?",
        answer: "We're zipping around many neighborhoods! Check out our app or website to see if we’re serving up deliciousness in your area."
      },
      {
        question: "How did Food Loop help during the COVID-19 rollercoaster?",
        answer: "We put on our superhero capes! From safety measures to sizzling promotions, we were all about keeping businesses booming and tummy's happy, all while keeping the coins in your pocket."
      },
      {
        question: "What makes Food Loop the cherry on top among delivery platforms?",
        answer: "We're not here to just grab a slice of your pie. We focus on dishing out joy to our customers and ensuring that drivers and restaurants feel the love (and the cha-ching!)."
      },
      {
        question: "I’m already on another platform, can I still jump onto the Food Loop fun ride?",
        answer: "Of course! The more, the merrier. Joining our party won’t rain on your other parades. Let’s make some foodie magic together!"
      },
      {
        question: "I've got more burning questions! Where do I go?",
        answer: "Got a craving for more info? Our super helpful (and fun!) support team is ready to chat. Hit us up on our app or website. We can't wait to hear from you!"
      },
      {
        question: "Why should I ride the Food Loop delivery wave?",
        answer: "Hop on board for a flavor-packed journey! With Food Loop , you don't just get delivery - you get a whole lot of fun, excitement, and most importantly, you keep more of your moolah!"
      },
    // ... more faqs
  ]);

  useEffect(() => {
    // This is where you'd make an API call to fetch your FAQs
    // For the purpose of this example, we'll use hardcoded data

    // Example:
    // fetch('/api/faqs')
    //   .then(res => res.json())
    //   .then(data => setFaqs(data));
  }, []);
  return (
    <div>
      <div style={{background: 'radial-gradient(circle at 100% 0%, rgba(150, 134, 130, 0.29) 0%, rgba(76, 76, 76, 1) 33%)',  minHeight: '40vh'}}>
        <Typography variant="h1" align="center" fontWeight={400} paddingTop='5vh' color="white" gutterBottom>
            Frequently Asked Questions
        </Typography>
      </div>

        <Container maxWidth="lg">
        {faqs.map((faq, index) => (
            <Accordion elevation={0} key={index}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography variant='h4'>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails >
                <Typography>
                {faq.answer}
                </Typography>
            </AccordionDetails>
            </Accordion>))}
        </Container>
    </div>
  );
}

export default withRouter(FAQPage);
