import React from "react";
import Button from "@mui/material/Button";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  ButtonBase,
  ListItem
} from "@mui/material";

const styles = {

    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '20px',
      background: '#FEFAF0',
    },
    imageContainer: {
      position: 'relative',
      width: '30vw',
      height: '40vw',
      marginRight: '5vw',
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: '100%',
      borderRadius: '50px',
      objectFit: 'cover',
    },
    gradientOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '50px',
      width: '100%',
      height: '100%',
      background: 'radial-gradient(circle at 50% 50%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%)',
    },
    content: {
      flex: 1,
    },
    header: {
      fontWeight: 'bold',
      fontSize: {
        xs: '30px',
        sm: '40px',
        md: '50px',
        lg: '60px',
      },
    },
    subheader: {
      marginTop: '16px',
      marginBottom: '32px',
      fontSize: {
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
      },
    },
    listButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
  };

  function EmpowerSection({ title, subtitle, buttonText, handleButton1, handleButton2 }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
      <div style={{ background: 'linear-gradient(to bottom right, #2A3890, #0C102A)', minHeight: '75vh' }}>
        <Box sx={{
          ...styles.root,
          background: 'linear-gradient(to bottom right, #2A3890, #0C102A)',
          overflow: 'hidden',
          minHeight: '75vh',
          maxWidth: '100%',
          paddingLeft: {xs: '10px', md: '50px'},
          flexDirection: isMobile ? "column" : "row",
        }}>
          {isMobile && (
          <img
            src="/foodAid.png"
            alt="FoodLoop"
            style={{
              ...styles.image,
              width: "80%",
              height: "auto",
              order: 0,
            }}
          />
        )}
          {!isMobile && (
          <Box style={styles.imageContainer}>
            <img src="/foodAid.png" alt="FoodLoop" style={styles.image} />
            <Box style={styles.gradientOverlay}/>
          </Box>
          )}
        <div style={{ ...styles.content, paddingLeft: '5vw', maxWidth: '50%', minWidth: '400px' }}>
          <Typography variant="h1" color="white" sx={{ ...styles.header, paddingBottom: '5vw' }}>
            {title || "Empowering Austin Through Local Engagement"}
          </Typography>
          <Typography variant="body1" color="white" sx={{ ...styles.subheader, paddingBottom: '5vh' }}>
            {subtitle}
          </Typography>
          <ListItem sx={styles.listButtons}>
            <Button
              color="inherit"
              sx={{
                backgroundColor: 'white',
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                width: { xl: '75%', md: '40%', sm: '25%', xs: '25%' },
                borderRadius: '8px',
                borderWidth: '1px',
              }}
              onClick={handleButton1}
            >
              {buttonText}
            </Button>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <ButtonBase
                sx={{
                  width: { xl: '100%', md: '50%'},
                  marginLeft: { xs: 0, md: '10px' },
                  '& img': {
                    width: '100%',
                  },
                }}
                onClick={handleButton1}
              >
                <img src="/playstore.svg" alt="Play Store" />
              </ButtonBase>
              <ButtonBase
                sx={{
                  width: { xl: '100%', md: '50%'},
                  marginLeft: { xs: 0, md: '10px' },
                  '& img': {
                    width: '100%',
                  },
                }}
                onClick={handleButton2}
              >
                <img src="/appStore.svg" alt="App Store" />
              </ButtonBase>
            </Box>
          </ListItem>
        </div>
        </Box>
      </div>
    );
  }

  export default EmpowerSection;