import React from 'react';
import { withRouter } from '../withRouter';
import InfoPage from '../templates/InfoPage'; // Make sure the file path is correct

function DiversityPage() {
  return (
    <InfoPage
      pageTitle="Celebrating Diversity and Inclusion"
      subtitle="Embracing Our Differences, Uniting Through Food"
      sections={[
        {
          backgroundColor: "#FFFFFF",
          heading: "Our Commitment to Diversity",
          text: "At Food Loop, we believe that diversity enriches us all. We're dedicated to fostering an environment where everyone is welcomed, respected, and celebrated. Our diverse team, comprising various backgrounds, cultures, and experiences, helps us create a more inclusive world.",
          imageSrc: "/deliveryWoman.png",
          altText: "Diversity Community",
        },
        {
          backgroundColor: "#F9F9FF",
          heading: "Inclusive Community",
          text: "We're proud to serve a diverse community of customers, drivers, and partners. Our platform provides an opportunity for people from all walks of life to come together, share their stories, and enjoy delicious meals that represent the richness of our global society.",
          imageSrc: "/sellingFood.svg",
          altText: "Inclusive Community",
        },
        {
          backgroundColor: "#F3F4FF",
          heading: "Veteran Driver Program",
          text: "As part of our commitment to diversity, we are proud to offer a special program for veteran drivers. If you've served in the military, we value your unique skills and experiences. Join our team and continue making a positive impact by delivering meals and connecting communities.",
          imageSrc: "/driverImage.png",
          altText: "Veteran Drivers",
        },
        {
          backgroundColor: "#E7E9FB",
          heading: "Join Our Inclusive Team",
          text: "If you're passionate about creating an inclusive world and have a love for food, we invite you to join our diverse team. Together, we can make a positive impact, inspire change, and continue to build a community that values and uplifts every individual.",
          imageSrc: "/open-door.svg",
          altText: "Join Our Team",
          button: {
            text: "Join Us",
          },
        },
      ]}
    />
  );
}

export default withRouter(DiversityPage);
