import React, { useState, useEffect } from 'react'
import {
  Box,
  Toolbar,
  InputBase,
  IconButton,
  alpha,
  Backdrop,
  CircularProgress,
  ListItem,
  Menu,
  MenuItem,
  Container,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import FilterListIcon from '@mui/icons-material/FilterList'
import SortIcon from '@mui/icons-material/Sort'
import { styled } from '@mui/system'
import productStore from '../stores/ProductStore'
import restaurantStore from '../stores/RestaurantStore'
import CardComponent from '../components/CardComponent'
import IconCard from '../components/IconCard'

const mockData = {
  restaurants: [
    {
      name: "McDonald's",
      time: '15 mins',
      fee: '$0 delivery fee',
      image: '/mcdonalds.jpg',
    },
    {
      name: 'Burger King',
      time: '20 mins',
      fee: '$0 delivery fee',
      image: '/burger-king.jpg',
    },
    {
      name: 'Pizza Hut',
      time: '25 mins',
      fee: '$0 delivery fee',
      image: '/pizza-hut.jpg',
    },
    {
      name: "Dunkin' Donuts",
      time: '13 mins',
      fee: '$0 delivery fee',
      image: '/dunkin-donuts.jpg',
    },
    {
      name: 'Wing Stop',
      time: '33 mins',
      fee: '$0 delivery fee',
      image: '/wing-stop.jpg',
    },
    {
      name: 'Popeyes',
      time: '22 mins',
      fee: '$0 delivery fee',
      image: '/popeyes.jpg',
    },
  ],
  categories: [
    {
      name: 'Pasta',
      image: '/pastaImage.png',
      time: 'Most popular',
      fee: '100+ options',
    },
    {
      name: 'Burgers',
      image: '/burgerImage.png',
      time: 'Trending now',
      fee: '80+ options',
    },
    {
      name: 'Sushi',
      image: '/sushiImage.png',
      time: 'Top rated',
      fee: '70+ options',
    },
    {
      name: 'Vegan',
      image: '/saladImage.png',
      time: 'Most loved',
      fee: '60+ options',
    },
    {
      name: 'Pizza',
      image: '/pizzaImage.png',
      time: 'Fast delivery',
      fee: '90+ options',
    },
    {
      name: 'Grocery',
      image: '/groceriesImage.png',
      time: 'Fast delivery',
      fee: '90+ options',
    },
    {
      name: 'Chinese',
      image: '/chineseImage.png',
      time: 'Fast delivery',
      fee: '90+ options',
    },
    {
      name: 'Fast Food',
      image: '/fastFoodImage.png',
      time: 'Fast delivery',
      fee: '90+ options',
    },
    {
      name: 'Indian',
      image: '/IndianImage.png',
      time: 'Fast delivery',
      fee: '90+ options',
    },
    {
      name: 'Ice Cream',
      image: '/iceCreamImage.png',
      time: 'Fast delivery',
      fee: '90+ options',
    },
    {
      name: 'Noodles',
      image: '/noodlesImage.png',
      time: 'Fast delivery',
      fee: '90+ options',
    },
  ],
  products: [
    {
      name: 'Spaghetti Carbonara',
      image: '/pizza-hut.jpg',
      time: '10+ ordered today',
      fee: '$9.99',
    },
    {
      name: 'Classic Cheeseburger',
      image: '/burger-king.jpg',
      time: '20+ ordered today',
      fee: '$7.99',
    },
    {
      name: 'Dragon Roll',
      image: '/dunkin-donuts.jpg',
      time: '15+ ordered today',
      fee: '$10.99',
    },
    {
      name: 'Vegan Salad Bowl',
      image: '/wing-stop.jpg',
      time: '8+ ordered today',
      fee: '$8.99',
    },
    {
      name: 'Pepperoni Pizza',
      image: '/mcdonalds.jpg',
      time: '25+ ordered today',
      fee: '$11.99',
    },
  ],
}

const HorizontalList = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  padding: '10px 0',
  width: '100%',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const ListItemStyled = styled(ListItem)({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '200px',
  marginRight: '15px',
})

const SearchDiv = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const SortAndFilterBar = styled(Box)({
  paddingTop: 50,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
})

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  padding: theme.spacing(1, 1, 0, 0),
  width: '300px',
  paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  flex: 1,
}))

const StyledInputBaseWithUnderline = styled(StyledInputBase)`
  && {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px; /* Adjust the underline height as needed */
      background-color: #000; /* Adjust the underline color as needed */
    }
  }
`

const SearchIconDiv = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  display: 'flex',
}))

function DisplayList({ label, items, color, textColor, deal }) {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <Box>
      <Typography sx={{ fontSize: '30px', fontWeight: '800' }}>{label}</Typography>
      {isLoading || !items ? (
        <Box>Loading...</Box>
      ) : (
        <Container>
          <HorizontalList>
            {items.map((item, index) => (
              <CardComponent
                to={`/order-restaurant?rest=${item.restaurant}`}
                textColor={textColor}
                imageSrc={item.image}
                title={item.name}
                description={item.description}
                key={index}
                color={color}
                deal={deal}
              />
            ))}
          </HorizontalList>
        </Container>
      )}
    </Box>
  )
}

function DisplayListIcons({ label, items }) {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <Box>
      <Typography sx={{ fontSize: '30px', fontWeight: '800' }}>{label}</Typography>
      {isLoading || !items ? (
        <Box>Loading...</Box>
      ) : (
        <Container>
          <HorizontalList>
            {items.map((item, index) => (
              <IconCard
                to={`/order-restaurant?rest=${item.restaurant}`}
                imageSrc={item.image}
                title={item.name}
                key={index}
              />
            ))}
          </HorizontalList>
        </Container>
      )}
    </Box>
  )
}

function OrderPage() {
  const [isSearching, setIsSearching] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null)
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null)
  const [activeFilters, setActiveFilters] = useState([])
  const [products, setProducts] = useState(productStore.products || [])

  const handleSortMenuClick = (event) => {
    setSortMenuAnchor(event.currentTarget)
  }

  const handleSortMenuClose = () => {
    setSortMenuAnchor(null)
  }

  const handleFilterMenuClick = (event) => {
    setFilterMenuAnchor(event.currentTarget)
  }

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null)
  }

  const handleAddFilter = (filter) => {
    setActiveFilters((prevFilters) => [...prevFilters, filter])
    handleFilterMenuClose()
  }

  const handleRemoveFilter = (filterToRemove) => {
    setActiveFilters((prevFilters) =>
      prevFilters.filter((filter) => filter !== filterToRemove),
    )
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
    setIsSearching(true)
  }

  useEffect(() => {
    const fetchProducts = async () => {
      productStore.setRestaurantId(null)
      await productStore.fetchProducts(10, { restaurant: { $ne: null } })
      setProducts(productStore.products)
    }

    fetchProducts()
  }, [])

  useEffect(() => {
    if (isSearching) {
      const filteredProducts = productStore.products.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      setProducts(filteredProducts)
      setIsSearching(false)
    }
  }, [isSearching, searchQuery])

  useEffect(() => {
    if (activeFilters.length > 0) {
      const filteredProducts = productStore.products.filter((product) => {
        return activeFilters.every((filter) => {
          if (filter === 'Free Delivery') {
            return product.fee.includes('$0 delivery fee')
          }
          if (filter === 'Fast Delivery') {
            return parseInt(product.time) <= 20
          }
          return true
        })
      })
      setProducts(filteredProducts)
    } else {
      setProducts(productStore.products)
    }
  }, [activeFilters])

  return (
    <Container maxWidth="lg" sx={{ margin: '70px auto' }}>
      <Toolbar disableGutters variant="dense">
        <SortAndFilterBar>
          <SearchIconDiv>
            <SearchIcon sx={{ fontSize: '24px' }} />
          </SearchIconDiv>
          <StyledInputBaseWithUnderline
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleSearchChange}
          />
          <IconButton
            aria-label="sort"
            sx={{ width: '50px', height: '50px' }}
            onClick={handleSortMenuClick}
          >
            <SortIcon sx={{ fontSize: '24px' }} />
          </IconButton>
          <Menu
            anchorEl={sortMenuAnchor}
            open={Boolean(sortMenuAnchor)}
            onClose={handleSortMenuClose}
          >
            <MenuItem onClick={handleSortMenuClose}>Name</MenuItem>
            <MenuItem onClick={handleSortMenuClose}>Delivery Time</MenuItem>
            <MenuItem onClick={handleSortMenuClose}>Fee</MenuItem>
          </Menu>
          <IconButton
            aria-label="filter list"
            sx={{ width: '50px', height: '50px' }}
            onClick={handleFilterMenuClick}
          >
            <FilterListIcon sx={{ fontSize: '24px' }} />
          </IconButton>
          <Menu
            anchorEl={filterMenuAnchor}
            open={Boolean(filterMenuAnchor)}
            onClose={handleFilterMenuClose}
          >
            <MenuItem onClick={() => handleAddFilter('Free Delivery')}>
              Free Delivery
            </MenuItem>
            <MenuItem onClick={() => handleAddFilter('Fast Delivery')}>
              Fast Delivery
            </MenuItem>
          </Menu>
        </SortAndFilterBar>
      </Toolbar>
      <Backdrop open={isSearching} sx={{ zIndex: 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DisplayListIcons label="Categories" items={mockData.categories} />
      <DisplayList label="Food Near You" items={products} color="#F8E6D6" textColor />
      <DisplayList label="Local Favorites" items={mockData.restaurants} color="#EB6440" />
      <DisplayList
        label="Deals"
        items={mockData.restaurants}
        color="#76B288"
        deal="Buy 1 Get 1 Free"
      />
    </Container>
  )
}

export default OrderPage
