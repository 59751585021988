import React from "react";
import { Grid, Container, Typography, Box, useMediaQuery, useTheme } from "@mui/material";

function TransparencySection({
  mainTitle,
  subTitle,
  contentSections,
  backgroundImage
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom left, #FF4848, #D52826)",
        display: "flex",
        paddingLeft: '20px',
      }}
    >
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: { xs: '10px', md: '50px' },
            paddingRight: { xs: "5vw", md: "30px" },
            paddingTop: '50px',
            paddingBottom: '50px',
          }}
        >
          <Typography
            variant="h1"
            fontWeight="bold"
            color="white"
            gutterBottom
            sx={{
              fontSize: { xs: "24px", sm: "32px", md: "40px", lg: "48px" },
            }}
          >
            {mainTitle}
          </Typography>
          <Typography
            variant="body1"
            paddingBottom="16px"
            color="white"
            sx={{
              width: { xs: "100%", md: "80%" },
              paddingTop: { xs: "10px", sm: "20px", md: "30px", lg: "40px" },
              fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
            }}
          >
            {subTitle}
          </Typography>
          <Grid
            container
            sx={{
              paddingTop: { xs: "20px", sm: "30px", md: "40px", lg: "50px" },
            }}
          >
            {contentSections.map((section, index) => (
              <Grid item xs={12} md={6} key={index} paddingRight={6}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="white"
                    gutterBottom
                    sx={{ fontSize: { xs: "12px", sm: "14px", md: "18px", lg: "20px" } }}
                  >
                    {section.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="white"
                    gutterBottom
                    sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" } }}
                  >
                    {section.text}
                  </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {!isMobile && backgroundImage && (
          <Grid
            item
            lg={6}
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              maxHeight: "100vh",
            }}
          >
            <img
              src={backgroundImage}
              alt="Background"
              style={{
                flex: "1",
                width: "100%",
                minHeight: "100%",
                objectFit: "cover",
                zIndex: "1",
              }}
              className="top-aligned-image"
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default TransparencySection;
