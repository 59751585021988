import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme,
  IconButton,
  styled,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import authStore from '../stores/AuthStore';
import Cart from './CartComponent';

const CustomToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
  height: '100px',
});

const NavButton = styled(Button)({
  margin: '0 24px',
  textDecoration: 'none',
  color: 'inherit',
  textTransform: 'none',
  fontSize: '20px',
  borderRadius: 0,
});

const CurrentNavButton = styled(NavButton)({
  borderBottom: '6px solid #FFC501',
});

const Navbar = observer(() => {
  const [bgOpacity, setBgOpacity] = useState(0);
  const [logoBrightness, setLogoBrightness] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      let newOpacity = Math.min(1, currentScrollY / 400);
      let logoBrightnessMath = 5 - Math.floor(4 * (currentScrollY / 500));
      let newBrightness = location.pathname === '/home' ? (logoBrightnessMath > 1 ? logoBrightnessMath : 1) : 1;
      if (location.pathname !== '/home') {
        newBrightness = 1;
      }
      setBgOpacity(newOpacity);
      setLogoBrightness(newBrightness);
    };
    if (location.pathname === '/home') {
      setLogoBrightness(5);
    } else {
      setLogoBrightness(1);
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location.pathname]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const pageTitles = ['Order', 'Drivers', 'Restaurants', 'Customers', 'About'];

  const username = authStore.isAuthenticated ? authStore.username : '';
  const avatarLetter = username ? username.charAt(0).toUpperCase() : '';

  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: `rgba(255, 255, 255, ${bgOpacity})`,
        color: 'black',
        transition: 'background-color 0.3s',
      }}
      elevation={0}
    >
      <CustomToolbar>
        <Link to="/home">
          <img
            src="FoodLoopLogo.png"
            alt="Logo"
            style={{
              height: '64px',
              display: 'block',
              transition: 'filter 0.4s',
              filter: `brightness(${logoBrightness})`,
            }}
          />
        </Link>
        <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
          {!isMobile && pageTitles.map((text, index) => (
            <React.Fragment key={index}>
              {location.pathname.toLowerCase() === `/${text.toLowerCase()}` ? (
                <CurrentNavButton
                  component={Link}
                  to={`/${text.toLowerCase().replace(/\s+/g, '-')}`}
                >
                  {text}
                </CurrentNavButton>
              ) : (
                <NavButton
                  component={Link}
                  to={`/${text.toLowerCase().replace(/\s+/g, '-')}`}
                  style={{ color: logoBrightness === 5 ? 'white' : 'black' }}
                >
                  {text}
                </NavButton>
              )}
            </React.Fragment>
          ))}
        </div>
        {isMobile ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Cart iconColor={logoBrightness === 5 ? 'white' : 'black'} />
            <IconButton
              onClick={toggleDrawer(true)}
              edge="start"
              color="inherit"
              aria-label="menu"
              style={{
                fontSize: '24px',
                color: logoBrightness === 5 ? 'white' : 'black',
              }}
            >
              <MenuIcon />
            </IconButton>
          </div>
        ) : authStore.isAuthenticated ? (
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <Cart iconColor={logoBrightness === 5 ? 'white' : 'black'} />
            <Avatar onClick={handleAvatarClick} sx={{ cursor: 'pointer', ml: 2 }}>
              {avatarLetter}
            </Avatar>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={authStore.logout}>Logout</MenuItem>
            </Menu>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <Cart iconColor={logoBrightness === 5 ? 'white' : 'black'}/>
            <Button
              color="inherit"
              style={{
                borderRadius: '50px',
                padding: '8px 20px',
                margin: '0 4px',
                color: logoBrightness === 5 ? 'white' : 'black',
              }}
              onClick={() => navigate('/auth?type=signin')}
            >
              Log In
            </Button>
            <Button
              color="inherit"
              style={{
                borderRadius: '50px',
                padding: '8px 20px',
                margin: '0 4px',
                color: logoBrightness === 5 ? 'white' : 'black',
              }}
              onClick={() => navigate('/auth?type=signup')}
            >
              Sign Up
            </Button>
          </div>
        )}
      </CustomToolbar>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          width: 250,
          '& .MuiDrawer-paper': {
            width: 250,
            boxSizing: 'border-box',
          },
        }}
      >
        <List>
          {pageTitles.map((text, index) => (
            <ListItemButton
              key={index}
              component={Link}
              to={`/${text.toLowerCase().replace(/\s+/g, '-')}`}
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary={text} />
            </ListItemButton>
          ))}
          {authStore.isAuthenticated ? (
            <>
              <ListItemButton onClick={authStore.logout}>
                <ListItemText primary="Log Out" />
              </ListItemButton>
            </>
          ) : (
            <>
              <ListItemButton component={Link} to="/auth?type=signin">
                <ListItemText primary="Login to Account" />
              </ListItemButton>
              <ListItemButton component={Link} to="/auth?type=signup">
                <ListItemText primary="Sign Up Now" />
              </ListItemButton>
            </>
          )}
        </List>
      </Drawer>
    </AppBar>
  );
});

export default Navbar;
