import React from 'react';
import { withRouter } from '../withRouter';
import InfoPage from '../templates/InfoPage'; // Make sure the file path is correct

function SustainabilityPage() {
  return (
    <InfoPage
      pageTitle="Embracing Sustainability for a Greener Future"
      subtitle="Taking Action to Preserve Our Planet"
      sections={[
        {
          backgroundColor: "#FFFFFF",
          heading: "Our Sustainability Mission",
          text: "At Food Loop, we're dedicated to reducing our carbon footprint and supporting sustainable practices. We believe that by making conscious choices, we can contribute to a healthier planet and a brighter future for generations to come.",
          imageSrc: "/salads.png",
          altText: "Sustainability Mission",
        },
        {
          backgroundColor: "#F9F9FF",
          heading: "Eco-Friendly Packaging",
          text: "We're committed to using eco-friendly packaging materials that are biodegradable or recyclable. By minimizing single-use plastics, we're working towards reducing waste and protecting our oceans and landfills from unnecessary pollution.",
          imageSrc: "/foodAid.png",
          altText: "Eco-Friendly Packaging",
        },
        {
          backgroundColor: "#F3F4FF",
          heading: "Reducing Food Waste",
          text: "Through careful planning and technology, we strive to minimize food waste at every step of our process. By optimizing inventory management and partnering with local charities, we're actively working to ensure that surplus food finds its way to those in need.",
          imageSrc: "/fastFoodImage.png",
          altText: "Reducing Food Waste",
        },
        {
          backgroundColor: "#E7E9FB",
          heading: "Join Us in Sustainability",
          text: "We invite you to be a part of our sustainability journey. By choosing Food Loop, you're supporting a company that values the environment and actively takes steps to make a positive impact. Together, we can create a more sustainable world.",
          imageSrc: "/two-chefs.png",
          altText: "Join Us",
          button: {
            text: "Support Sustainability",
          },
        },
      ]}
    />
  );
}

export default withRouter(SustainabilityPage);
