import React from "react";
import {
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AccordionComponent from "../AccordionComponent";
import { useLocation } from 'react-router-dom';

const QuestionSection = ({ sectionTitle, mainTitle, subTitle, arrowsImageAlt }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  const isDriverPage = location.pathname === '/drivers';
  const isRestaurantPage = location.pathname === '/restaurants';

  const driverQA = [
    { title: 'How does it work?', content: 'Drivers pick up orders from local restaurants and deliver them to customers. Orders are managed through our driver app, which provides all the necessary details for each delivery.' },
    { title: 'How to join?', content: 'Drivers can join by applying through our driver portal. Once approved, they can start accepting delivery requests through the app.' },
    { title: 'What are the fees?', content: 'Drivers pay a subscription fee that can be taken from their earnings. This fee covers all operational costs, allowing drivers to keep 100% of their tips and earn competitive pay for each delivery.' },
    { title: 'How to manage orders?', content: 'Drivers manage their orders through our driver app, where they can view order details, navigate to the restaurant and customer locations, and communicate with support if needed.' },
    { title: 'How do payments work?', content: 'Drivers get paid for each delivery and receive their earnings weekly. Tips are paid out in full, and drivers can track their earnings and payments through the app.' },
  ];

  const restaurantQA = [
    { title: 'How does it work?', content: 'Our platform connects customers with local restaurants and delivery drivers. Customers place an order through our app or website, and a driver picks up the order from the restaurant and delivers it to the customer.' },
    { title: 'How to join?', content: 'To join our platform, restaurants can partner with us by filling out an online form. Once approved, they can start receiving orders from customers through our platform.' },
    { title: 'What are the fees?', content: 'We charge a simple monthly fee for our service with no additional costs. This straightforward pricing model can save restaurants thousands of dollars compared to competitors who charge per-order fees and other hidden costs.' },
    { title: 'How to manage orders?', content: 'Restaurants can manage incoming orders through our restaurant dashboard, where they can view order details, track order status, and update menu items. Our user-friendly interface ensures smooth and efficient order management.' },
    { title: 'How do payments work?', content: 'Restaurants receive payments directly to their bank accounts on a regular schedule. The monthly fee covers all operational costs, ensuring there are no unexpected charges. This provides a predictable and hassle-free payment process for our partners.' },
  ];

  const accordionItems = isDriverPage ? driverQA : isRestaurantPage ? restaurantQA : [];

  const styles = {
    root: {
      width: "100%",
      display: 'flex',
      alignItems: 'center',
      background: '#FEFAF0',
    },
    image: {
      width: '30vw',
      height: 'auto',
      marginRight: '16px',
    },
    content: {
      flex: 1,
    },
    header: {
      fontWeight: 'bold',
      maxWidth: '90%',
      paddingLeft: '24px',
      fontSize: {
        xs: '30px',
        sm: '40px',
        md: '50px',
        lg: '60px',
      },
    },
    subheader: {
      marginTop: '16px',
      marginBottom: '32px',
      paddingLeft: '24px',
      maxWidth: '90%',
      minWidth: '300px',
      fontSize: {
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
      },
    },
  };

  return (
    <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        <Typography variant="h5" style={{ fontWeight: 'lighter', letterSpacing: 3, paddingTop: '10vh', color: "#FF4848", paddingLeft: '5vw' }}>
          {sectionTitle || "Profitability First"}
        </Typography>
        <Typography variant="h1" sx={{ ...styles.header }}>
          {mainTitle || "Questions"}
        </Typography>
        <Typography variant="body1" sx={{ ...styles.subheader }}>
          {subTitle || "Find answers to common questions and concerns about partnering with FoodLoop."}
        </Typography>
        {isMobile ? null : (
          <img src="/arrows.svg" alt={arrowsImageAlt || "arrows"} style={{ width: '100px', height: 'auto', paddingLeft: '5vw', paddingTop: '10vh' }} />
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        <AccordionComponent accordionItems={accordionItems} />
      </div>
    </div>
  );
}

export default QuestionSection;
