import React, { useEffect, useState } from 'react';
import { Button, TextField, Container, FormControl, InputLabel, Select, MenuItem, Grid, Snackbar } from '@mui/material';
import StripeForm from './StripeForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import apiHandler from '../api/ApiHandler';
import { wait } from '@testing-library/user-event/dist/utils';

const DriverRegisterPayment = ({ onPaymentSuccess }) => {

  const [clientSecret, setClientSecret] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarOpen = () => {setIsSnackbarOpen(true)};
  const handleSnackbarClose = () => {setIsSnackbarOpen(false)};

  useEffect(() => {
    
    //Fetch Payment Intent
    const fetchPaymentIntent = async () => {
      const response = await apiHandler.post('/pay/driverRegisterIntent', { amount: 100 });
      setClientSecret(response.clientSecret);
    };

    fetchPaymentIntent();
  }, []);

  const successfulPayment = () => {
    setSnackbarMessage('Payment Successful');
    handleSnackbarOpen();
    wait(3000).then(() => {
      onPaymentSuccess();
    });
  }

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: '32px', mb: '16px'}}>
      <StripeForm total={1} clientSecret={clientSecret} successfulPaymentCallback={successfulPayment}/>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackbarClose}>
              Close
            </Button>
          </React.Fragment>
        }
      />
    </Container>
  );

}

export default DriverRegisterPayment;