import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Stepper,
  Step,
  StepLabel,
  styled,
  Snackbar,
} from '@mui/material';
import DriverRegistration from '../components/DriverRegistration';
import CheckrForm from '../components/CheckrForm';
import StripeForm from '../components/StripeForm';
import RegisrationAddress from '../components/RegistrationAddress';
import registerDriverStore from '../stores/RegisterStore';
import authStore from '../stores/AuthStore';
import DriverRegisterPayment from '../components/DriverRegisterPayment';
import { useNavigate } from 'react-router-dom';

const DotStepper = styled(Stepper)(({ theme }) => ({
  '& .MuiStepIcon-root': {
    // Default styles for dots.
    color: theme.palette.grey[400],
    '& .MuiStepIcon-text': {
      fill: theme.palette.common.white
    }
  },
  '& .MuiStepIcon-active': {
    // Styles for the active dot.
    color: theme.palette.primary.main,
  },
  '& .MuiStepIcon-completed': {
    // Styles for completed dots.
    color: theme.palette.primary.main,
  },
  '& .MuiStepConnector-line': {
    display: 'none', // This hides the connector lines.
  }
}));

const CardWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(4),
  backdropFilter: 'blur(8px)',
  zIndex: 1,
}));

const BackgroundImage = styled('img')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: -1,
});


function DriverRegistrationPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [driverInfo, setDriverInfo] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    vehicleType: '',
    licenseNumber: '',
  });
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarOpen = () => {setIsSnackbarOpen(true)};
  const handleSnackbarClose = () => {setIsSnackbarOpen(false)};
  const navigate = useNavigate();

  const steps = [
    "Basic Info & Account",
    // "Vehicle Information",
    "User Address",
    // "Driver's License",
    "Background Check",
    "Payment",
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDriverInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/');
  };

  const handleCheckrForm = async () => {

    const userAddress = registerDriverStore.address;

    if (!userAddress.street || !userAddress.city || !userAddress.state || !userAddress.postalCode) {
      setSnackbarMessage("Please enter your address before continuing.");
      handleSnackbarOpen();
      return;
    }

    const checkrData = {
      fullName: `${registerDriverStore.firstName} ${registerDriverStore.middleName ? registerDriverStore.middleName + ' ' : ''}${registerDriverStore.lastName}`.trim(),
      email: registerDriverStore.email,
      phoneNumber: registerDriverStore.phone,
      dateOfBirth: registerDriverStore.dob,
      vehicleType: registerDriverStore.vehicleType,
      drivingLicenseNumber: `${registerDriverStore.driverLicenseState} ${registerDriverStore.driverLicenseNumber}`,
      userId: authStore.userId,
    }

    console.log(checkrData);

    if (!checkrData.fullName || !checkrData.email || !checkrData.phoneNumber || 
        !checkrData.dateOfBirth || !checkrData.vehicleType || 
        !checkrData.drivingLicenseNumber || !checkrData.userId) {
      setSnackbarMessage("Please fill out all fields before continuing.");
      handleSnackbarOpen();
      return;
    }

    //Make sure we update user address
    try {
      const user = await registerDriverStore.updateUserAddress();
    } catch (error) {
      console.log(error);
      setSnackbarMessage("Error updating user address. Try again later.");
      handleSnackbarOpen();
      return;
    }

    //Send the data to Checkr
    try {
      await registerDriverStore.register();
      handleNext();
    } catch (error) {
      console.log(error);
      setSnackbarMessage("Error registering driver. Try again later.");
      handleSnackbarOpen();
      return;
    }
    
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography variant="body1">
              First, we need to get some basic information and create an account.
            </Typography>
            <DriverRegistration />

            {/* [Insert Basic Info Fields Here] */}
          </>
        );
      //   case 1:
      //     return (
      //       <>
      //         <Typography variant="body1">
      //           Next, we need information about your car.
      //         </Typography>
      //         {/* [Insert Vehicle Fields Here] */}
      //       </>
      //     );
      //   case 2:
      //     return (
      //       <>
      //         <Typography variant="body1">
      //           What's your driver's license number?
      //         </Typography>
      //         {/* [Insert License Number Field Here] */}
      //       </>
      //     );
      case 1:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              User Address
            </Typography>

            <Typography variant="body1" paragraph>
              Please enter your address below.
            </Typography>

            <Typography variant="body1" paragraph>
              Your address will be used to determine your eligibility to drive in your area.
            </Typography>
            <RegisrationAddress />
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h6" gutterBottom>
              Background Check Process
            </Typography>

            <Typography variant="body1" paragraph>
              Now, we need to run a background check. A background check serves as an essential component of our driver onboarding process. Its primary aim is to ensure the safety and trustworthiness of individuals joining our platform.
            </Typography>

            <Typography variant="body1" paragraph>
              It's more than just a formality—it's a commitment to our community, passengers, and partners. Every driver is expected to meet specific standards in terms of criminal history, driving record, and other critical areas.
            </Typography>

            <Typography variant="body1" paragraph>
              Our comprehensive review dives into various national databases, court records, and other relevant sources to gather detailed information about an applicant's past.
            </Typography>

            <Typography variant="body1" paragraph>
              This process not only helps in mitigating potential risks but also underscores our dedication to maintaining a high-quality service environment. We strive to ensure everyone can feel secure and at ease while using our platform.
            </Typography>

            <CheckrForm onFormSubmit={handleCheckrForm} />

            {/* [Insert Background Check Fields Here, if any] */}
          </>
        );
      case 3:
        return (
          <>
            <Typography variant="body1">
              All that looks good! Please pay $1 to sign up today as a driver.
            </Typography>
            <DriverRegisterPayment onPaymentSuccess={handleNext}/>
            {/* <StripeForm total={1} /> */}
            {/* [Insert Payment Fields Here] */}
          </>
        );
      default:
        return "Unknown step";
    }
  };

  return (

    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <BackgroundImage
        src="/restaurantPageImage.png"
        alt="Driver background"
      />
      <Typography variant='h1' sx={{ margin: "72px auto" }}>
        Become a Food Loop Driver
      </Typography>
      <Typography variant='h5' sx={{ margin: "8px auto" }}>
        Fill out the form below to get started.
      </Typography>
      <Container maxWidth="md">
        <CardWrapper>
          <CardContent>
            <DotStepper activeStep={activeStep}>
              {steps.map((step, index) => (
                <Step key={step} />
              ))}
            </DotStepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography variant="h6">
                    All steps completed - you're finished!
                  </Typography>
                  <Button onClick={handleSubmit}>Return Home</Button>
                </div>
              ) : (
                <div>
                  {getStepContent(activeStep)}
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </CardContent>
        </CardWrapper>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          action={
            <Button color="primary" size="small" onClick={handleSnackbarClose}>
              Close
            </Button>
          }
        />
      </Container>
    </div>
  );
}

export default DriverRegistrationPage;
