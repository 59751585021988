import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, CircularProgress, Alert, AlertTitle, Button, Box, List, ListItem, ListItemText, Divider } from "@mui/material";
import orderStore from "../stores/OrderStore";
import cartStore from "../stores/CartStore";
import StripeForm from "../components/StripeForm";
import apiHandler from "../api/ApiHandler";

function PaymentPage() {
  const navigate = useNavigate();
  const order = orderStore.currentOrder;
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchPaymentIntent = async () => {
    try {
      if (!order) {
        throw new Error("Order not found");
      }
      const response = await apiHandler.post('/pay/orderPaymentHold', { orderId: order._id });
      setClientSecret(response.clientSecret);
    } catch (err) {
      if (err.response) {
        setError(`Error: ${err.response.data.message}`);
      } else if (err.request) {
        setError("Error: No response from server. Please check your internet connection.");
      } else {
        setError(`Error: ${err.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentIntent();

    // Return items to cart if payment page is unmounted without completion
    return () => {
      if (order && !order.isPaid) {
        order.items.forEach(item => {
          cartStore.addToCart({
            product: { _id: item.foodId, name: item.name, price: item.price },
            quantity: item.quantity
          });
        });
      }
    };
  }, [order]);

  const successfulPaymentCallback = () => {
    orderStore.completeOrder();
    navigate('/order-success');
  };

  const handleRetry = () => {
    setLoading(true);
    setError('');
    fetchPaymentIntent();
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ marginTop: '70px', textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  const driverFare = order?.driverFare || 5.00;
  const tip = order?.tip || 2.00;
  const platformFees = order?.platformFees || 3.00;

  const calculateTotal = () => {
    return (order.totalAmount + driverFare + tip + platformFees).toFixed(2);
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: '70px' }}>
      {order ? (
        <Box sx={{ padding: '20px', marginTop: '16px' }}>
          <Typography variant="h4" gutterBottom>Order Summary</Typography>
          <Typography variant="body1" gutterBottom>Order Number: {order.orderNumber}</Typography>
          <Typography variant="body1" gutterBottom>Order Status: {order.orderStatus}</Typography>
          <Typography variant="body1" gutterBottom>Delivery Status: {order.deliveryStatus}</Typography>
          <Typography variant="body1" gutterBottom>Date Created: {new Date(order.dateCreated).toLocaleString()}</Typography>
          <Divider sx={{ marginY: 2 }} />
          <Typography variant="h6" gutterBottom>Customer Information</Typography>
          <Typography variant="body1">Name: {order.customer.name}</Typography>
          <Typography variant="body1">Phone: {order.customer.phone}</Typography>
          <Divider sx={{ marginY: 2 }} />
          <Typography variant="h6" gutterBottom>Delivery Address</Typography>
          <Typography variant="body1">{order.deliveryAddress.street}</Typography>
          <Typography variant="body1">{order.deliveryAddress.city}, {order.deliveryAddress.state} {order.deliveryAddress.postalCode}</Typography>
          <Divider sx={{ marginY: 2 }} />
          <Typography variant="h6" gutterBottom>Items</Typography>
          <List>
            {order.items.map((item, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`${item.name} - Quantity: ${item.quantity}`}
                  secondary={`Price: $${item.price.toFixed(2)}`}
                />
              </ListItem>
            ))}
          </List>
          <Divider sx={{ marginY: 2 }} />
          <Typography variant="h6" gutterBottom>Driver Fare: ${driverFare.toFixed(2)}</Typography>
          <Typography variant="h6" gutterBottom>Tip: ${tip.toFixed(2)}</Typography>
          <Typography variant="h6" gutterBottom>Platform Fees: ${platformFees.toFixed(2)}</Typography>
          <Typography variant="h6" gutterBottom>Total Amount: ${calculateTotal()}</Typography>
          {error && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              <AlertTitle>Payment Error</AlertTitle>
              {error}
              <Button variant="contained" color="primary" onClick={handleRetry} sx={{ marginTop: '20px' }}>
                Retry
              </Button>
            </Alert>
          )}
          <Box sx={{ marginTop: '20px' }}>
            {!error && clientSecret && (
              <StripeForm
                total={calculateTotal()}
                clientSecret={clientSecret}
                successfulPaymentCallback={successfulPaymentCallback}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Typography>Order not found</Typography>
      )}
    </Container>
  );
}

export default PaymentPage;
