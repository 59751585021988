import React from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';
import authStore from '../stores/AuthStore';

function ContactVerification({ onContinue, onOAuth, skipContactAndMFA }) {
  const [email, setEmail] = React.useState(authStore.email || '');
  const [phone, setPhone] = React.useState(authStore.phone || '');
  const [loading, setLoading] = React.useState(false);

  const isEmailFormat = (input) => /\S+@\S+\.\S+/.test(input);
  const isPhoneNumber = (input) => /^\+?[1-9]\d{1,14}$/.test(input);

  const handleSubmit = () => {
    if (skipContactAndMFA) {
      onContinue(email);
      return;
    }

    if (!email && !phone) {
      alert('Contact information cannot be empty');
      return;
    }

    if (isEmailFormat(email)) {
      authStore.setEmail(email);
      onContinue('email', email);
    } else if (isPhoneNumber(phone)) {
      authStore.setPhone(phone);
      onContinue('phone', phone);
    } else {
      alert('Invalid email or phone number');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
      <TextField
        fullWidth
        margin='normal'
        label='Email'
        variant='outlined'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder='Enter your email'
      />
      {!skipContactAndMFA && (
        <TextField
          fullWidth
          margin='normal'
          label='Phone number'
          variant='outlined'
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder='Enter your phone number'
        />
      )}
      <Button 
        variant="contained" 
        color="primary"
        onClick={handleSubmit}
        disabled={!(email || (!skipContactAndMFA && phone)) || loading}
        style={{borderRadius: '25px'}}
        endIcon={loading && <CircularProgress size={20} />}
      >
        Continue
      </Button>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '32px' }}>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<GoogleIcon />}
          onClick={() => onOAuth('google')}
          disabled={loading}
        >
          Continue with Google
        </Button>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<AppleIcon />}
          onClick={() => onOAuth('apple')}
          disabled={loading}
        >
          Continue with Apple
        </Button>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<FacebookIcon />}
          onClick={() => onOAuth('facebook')}
          disabled={loading}
        >
          Continue with Facebook
        </Button>
      </div>
    </div>
  );
}

export default ContactVerification;
