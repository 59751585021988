import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Home from './pages/Home';
import BusinessPage from './pages/BusinessPage';
import DriverPage from './pages/DriverPage';
import Footer from './components/Footer';
import FoodLoopToolbar from './components/FoodLoopToolbar';
import DownloadPage from './pages/DownloadPage';
import OrderPage from './pages/OrderPage';
import AboutUs from './pages/AboutUs';
import OurPartners from './pages/PartnersPage';
import CareersPage from './pages/CareersPage';
import CommunityPage from './pages/CommunityPage';
import DiversityPage from './pages/DiversityPage';
import SafetyPage from './pages/SafetyPage';
import SustainabilityPage from './pages/SustainabilityPage';
import InnovationPage from './pages/InnovationPage';
import CulinaryExperiencesPage from './pages/CulinaryExperiencesPage';
import FAQPage from './pages/FAQ';
import Navbar from './components/Navbar';
import NewsPage from './pages/NewsPage';
import RestaurantPage from './pages/RestaurantPage';
import DriverRegistrationPage from './pages/DriverRegistrationPage';
import AuthPage from './pages/AuthPage';
import RestaurantOrderPage from './pages/RestaurantOrderPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import PaymentPage from './pages/PaymentPage';
import CustomerPage from './pages/CustomerPage';
import OurStoryPage from './pages/OurStoryPage';
import authStore from './stores/AuthStore';
import { observer } from 'mobx-react-lite';

const lightTheme = createTheme({
  palette: {
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: '#000000',
    },
    primary: {
      main: '#555555',
    },
    secondary: {
      main: '#333333',
    },
  },
  typography: {
    fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
    fontWeight: 700, // Bold weight for headers
    fontSize: '2.5rem', // example size, adjust as needed
    fontStretch: 'normal', // Adjust based on your requirements
    h2: {
      fontWeight: 600,
      fontSize: '2rem',
      fontStretch: 'normal',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.75rem',
      fontStretch: 'normal',
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.5rem',
      fontStretch: 'normal',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.25rem',
      fontStretch: 'normal',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
      fontStretch: 'normal',
    },
    body1: {
      fontWeight: 400, // Normal weight for body text
      fontSize: '1rem',
      fontStretch: 'normal',
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      fontStretch: 'normal',
    },
    // ... You can continue to customize other typography variants as necessary
  }
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#BB86FC',
    },
    secondary: {
      main: '#03DAC6',
    },
  },
});

const PrivateRoute = observer(({ children, returnUrl, ...rest }) => {
  const { isAuthenticated } = authStore;

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={`/auth?returnUrl=${returnUrl}`} replace />
  );
});

function Pages() {
  const location = useLocation();

  useEffect(() => {
    // Reset scroll position to the top on route change
    window.scrollTo(0, 0);
  }, [location]);

  
  const isAuthPage = location.pathname === "/auth";

  return (
    <div className="hideOverflow">
      {!isAuthPage && <Navbar />}
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/business" element={<BusinessPage />} />
        <Route path="/drivers" element={<DriverPage />} />
        <Route path='/customers' element={<CustomerPage />} />
        <Route path="/download" element={<DownloadPage />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/order-restaurant" element={<RestaurantOrderPage />} />
        <Route path="/about" element={<OurStoryPage />} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/partners" element={<OurPartners />} />
        <Route path="/community" element={<CommunityPage />} />
        <Route path="/safety" element={<SafetyPage />} />
        <Route path="/diversity" element={<DiversityPage />} />
        <Route path="/sustainability" element={<SustainabilityPage />} />
        <Route path="/innovation" element={<InnovationPage />} />
        <Route path="/restaurants" element={<RestaurantPage />} />
        <Route path="/culinaryexperiences" element={<CulinaryExperiencesPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/news/:article" element={<NewsPage />} />
        <Route path="/register" element={<PrivateRoute returnUrl="/register"><DriverRegistrationPage /></PrivateRoute>} />
        {/* <PrivateRoute path="/register" element={<DriverRegistrationPage />} /> */}
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/payment" element={<PrivateRoute returnUrl="/payment"><PaymentPage /></PrivateRoute>} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
      {!isAuthPage && <Footer />}
      {!isAuthPage && <FoodLoopToolbar />}
    </div>
  );
}

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router>
          <Pages />
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
