import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

const cardElementStyle = {
  base: {
    fontSize: '20px',
  }
};

const buttonStyle = {
  width: '100%',
  margin: '16px auto'
};

function StripePaymentForm({totalAmount, clientSecret, redirectUrl = '/order-success', successfulPaymentCallback}) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: card,
      },
    });

    if (error) {
      console.log('[error]', error);
    } else {
      console.log("Payment Succeeded: ", paymentIntent);

      if (successfulPaymentCallback) {
        successfulPaymentCallback();
      } else {
        navigate(redirectUrl);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={{ style: cardElementStyle }} />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!stripe}
        style={buttonStyle}
      >
        Pay ${totalAmount}
      </Button>
    </form>
  );
}

function StripeForm({total, clientSecret, successfulPaymentCallback}) {
  return (
    <Container component="main" maxWidth="xs">
      <div style={{margin: "16px, auto"}}>
          <Elements stripe={stripePromise}>
            <StripePaymentForm totalAmount={total} clientSecret={clientSecret} successfulPaymentCallback={successfulPaymentCallback}/>
          </Elements>
      </div>
    </Container>
  );
}

export default StripeForm;
