import React from "react";
import Button from "@mui/material/Button";
import {
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import FavoritesCard from "../FavoritesCard"
import { useNavigate } from 'react-router-dom';

const restaurantData = [
    {
      name: "McDonald's",
      time: "15 mins",
      fee: "$0 delivery fee",
      image: "/mcdonalds.jpg",
    },
    {
      name: "Burger King",
      time: "20 mins",
      fee: "$0 delivery fee",
      image: "/burger-king.jpg",
    },
    {
      name: "Pizza Hut",
      time: "25 mins",
      fee: "$0 delivery fee",
      image: "/pizza-hut.jpg",
    },
    {
      name: "Dunkin' Donuts",
      time: "13 mins",
      fee: "$0 delivery fee",
      image: "/dunkin-donuts.jpg",
    },
    {
      name: "Wing Stop",
      time: "33 mins",
      fee: "$0 delivery fee",
      image: "/wing-stop.jpg",
    },
    {
      name: "Popeyes",
      time: "22 mins",
      fee: "$0 delivery fee",
      image: "/popeyes.jpg",
    },
  ];

  const styles= {
    header: {
      fontWeight: 'bold',
      fontSize: {
        xs: '15px',
        sm: '20px',
        md: '30px',
        lg: '40px',
      },
    },
    subheader: {
      marginTop: '16px',
      marginBottom: '32px',
      fontSize: {
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
      },
    },
  }

  

function FavoritesSection() {
  const navigate = useNavigate();
    return (
      <Box className="favorites-container">
        <Container maxWidth="lg">
          <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'left'}}>
            <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: "32px"}}>
              <Typography variant="h1" sx={{...styles.header, fontWeight: 800}} >
                Our Menu
              </Typography>
              <Button
              color='inherit'
              variant='outlined'
              style={{
                borderRadius: '8px',
                borderWidth: '1px',
                padding: '8px 20px',
              }}
              onClick={() => {
                navigate('/order');
              }}
            >
              View More
            </Button>
            </Box>
            <Typography variant="body1" style={{color: 'grey', margin: '25px 0 32px 0'}}>
                Explore our selection of local restaurants. 
              </Typography>
          </Box>
          <Grid container spacing={3}>
            {restaurantData.map((store, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <FavoritesCard store = {store}/>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    );
  }

  export default FavoritesSection;