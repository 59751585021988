import React, { useEffect, useState } from 'react';
import {
  Toolbar, InputBase, IconButton, Menu, MenuItem, Container, ListItem, Avatar, List,
  ListItemAvatar, ListItemText, Box, Typography, CircularProgress, Dialog, Slide, Grid, Button, TextField
} from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import { AddCircleOutline, Close } from '@mui/icons-material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import productStore from '../stores/ProductStore';
import restaurantStore from '../stores/RestaurantStore';
import cartStore from '../stores/CartStore';

const SortAndFilterBar = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  padding: theme.spacing(1, 1, 0, 0),
  flex: 1,
  width: '100%'
}));

const StyledInputBaseWithUnderline = styled(StyledInputBase)`
  && {
    position: relative;
    width: 100%;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px; 
      background-color: #000;
    }
  }
`;

const SearchIconDiv = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RestaurantOrderPage() {
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState(null);
  const [searchParams] = useSearchParams();
  const [products, setProducts] = useState(productStore.products || []);
  const [restaurant, setRestaurant] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const [productNotes, setProductNotes] = useState('');
  const [productVariations, setProductVariations] = useState([]);
  const [filters, setFilters] = useState({ category: '', priceRange: '' });
  const [restaurantId, setRestaurantId] = useState(searchParams.get('rest'));

  const navigate = useNavigate();

  const handleSortMenuClick = (event) => setSortMenuAnchor(event.currentTarget);
  const handleSortMenuClose = () => setSortMenuAnchor(null);
  const handleFilterMenuClick = (event) => setFilterMenuAnchor(event.currentTarget);
  const handleFilterMenuClose = () => setFilterMenuAnchor(null);

  const addToCart = async (product) => {
    try {
      if (!product) {
        throw new Error('Product is not set');
      }
  
      let restaurantId = cartStore.selectedRestaurantId;
  
      if (!restaurantId) {
        const fetchedProduct = await productStore.fetchProducts(1, { productId: product._id });
        if (fetchedProduct && fetchedProduct.length > 0) {
          restaurantId = fetchedProduct[0].restaurantId;
        } else {
          throw new Error('Restaurant ID could not be determined from the product');
        }
      }
  
      const item = {
        product: product,
        quantity: 1,
        notes: productNotes,
        variations: productVariations,
      };
  
      if (cartStore.selectedRestaurantId && cartStore.selectedRestaurantId !== restaurantId) {
        throw new Error('You can only order from one restaurant at a time.');
      }
  
      const existingItem = cartStore.cartItems.find(
        (cartItem) => cartItem.product._id === item.product._id && JSON.stringify(cartItem.variations) === JSON.stringify(item.variations)
      );
  
      if (existingItem) {
        existingItem.quantity += item.quantity;
      } else {
        cartStore.cartItems.push({
          ...item,
          restaurantId,
        });
        cartStore.selectedRestaurantId = restaurantId;
      }
  
      cartStore.successMessage = `${item.product.name} added to cart`;
      cartStore.persistCartState();
      cartStore.syncCartWithServer();
      cartStore.calculateDeliveryFee();
      closeProductDialog();
    } catch (error) {
      console.error(error.message);
      cartStore.errorMessage = error.message;
    }
  };
  
  

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const openProductDialog = (product) => {
    setSelectedProduct(product);
    setProductDialogOpen(true);
  };

  const closeProductDialog = () => {
    setSelectedProduct(null);
    setProductDialogOpen(false);
    setProductNotes('');
    setProductVariations([]);
  };

  const applyFilters = () => {
    const filteredProducts = productStore.products.filter((product) => {
      const matchCategory = filters.category ? product.category === filters.category : true;
      const matchPrice = filters.priceRange
        ? product.price >= filters.priceRange[0] && product.price <= filters.priceRange[1]
        : true;
      return matchCategory && matchPrice;
    });
    setProducts(filteredProducts);
  };

  useEffect(() => {
    const getMenu = async () => {
      const restId = searchParams.get('rest');
      if (!restId || restId === 'undefined') {
        navigate('/');
        return;
      }
      productStore.setRestaurantId(restId);
      cartStore.setSelectedRestaurantId(restId);
      await productStore.fetchProducts(10);
      setProducts(productStore.products);
      const rest = await restaurantStore.getRestaurant(restId);
      if (rest && rest !== 'axios error') {
        setRestaurant(rest);
      } else {
        setRestaurant('Austin');
      }
      await restaurantStore.fetchLocations(restId);
      if (restaurantStore.restaurantLocations.length > 0) {
        restaurantStore.setRestaurantLocation(restaurantStore.restaurantLocations[0]);
      }
      setLoading(false);
    };
    getMenu();
  }, [searchParams]);

  useEffect(() => {
    const filteredProducts = productStore.products.filter((product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setProducts(filteredProducts);
  }, [searchQuery]);

  useEffect(() => {
    applyFilters();
  }, [filters]);

  return useObserver(() => (
    <Container maxWidth="lg" sx={{ margin: '70px auto' }}>
      <Typography variant="h2">
        {restaurant.name || 'No restaurant selected'}
      </Typography>
      <Toolbar disableGutters variant="dense">
        <SortAndFilterBar>
          <SearchIconDiv>
            <SearchIcon sx={{ fontSize: '24px' }} />
            <StyledInputBaseWithUnderline
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleSearchChange}
            />
          </SearchIconDiv>
          <IconButton onClick={handleSortMenuClick} size="large">
            <SortIcon sx={{ fontSize: '24px' }} />
          </IconButton>
          <Menu
            anchorEl={sortMenuAnchor}
            keepMounted
            open={Boolean(sortMenuAnchor)}
            onClose={handleSortMenuClose}
          >
            <MenuItem onClick={() => {
              setProducts(products.slice().sort((a, b) => a.name.localeCompare(b.name)));
              handleSortMenuClose();
            }}>Sort by Name</MenuItem>
            <MenuItem onClick={() => {
              setProducts(products.slice().sort((a, b) => a.price - b.price));
              handleSortMenuClose();
            }}>Sort by Price</MenuItem>
          </Menu>
          <IconButton onClick={handleFilterMenuClick} size="large">
            <FilterListIcon sx={{ fontSize: '24px' }} />
          </IconButton>
          <Menu
            anchorEl={filterMenuAnchor}
            keepMounted
            open={Boolean(filterMenuAnchor)}
            onClose={handleFilterMenuClose}
          >
            <MenuItem onClick={() => {
              setFilters({ ...filters, category: 'Category1' });
              handleFilterMenuClose();
            }}>Filter by Category1</MenuItem>
            <MenuItem onClick={() => {
              setFilters({ ...filters, category: 'Category2' });
              handleFilterMenuClose();
            }}>Filter by Category2</MenuItem>
            <MenuItem onClick={() => {
              setFilters({ ...filters, priceRange: [0, 10] });
              handleFilterMenuClose();
            }}>Filter by Price: $0 - $10</MenuItem>
            <MenuItem onClick={() => {
              setFilters({ ...filters, priceRange: [10, 20] });
              handleFilterMenuClose();
            }}>Filter by Price: $10 - $20</MenuItem>
          </Menu>
        </SortAndFilterBar>
      </Toolbar>
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {products.map((item, index) => (
            <ListItem button key={index} onClick={() => openProductDialog(item)}>
              <ListItemAvatar>
                <Avatar src={item.imageUrl} alt={item.name} />
              </ListItemAvatar>
              <ListItemText
                primary={item.name}
                secondary={`Price: ${item.price} | Description: ${item.description}`}
              />
              <IconButton sx={{ fontSize: '24px' }} onClick={(event) => {
                event.stopPropagation();
                addToCart(item);
              }}>
                <AddCircleOutline sx={{ fontSize: '24px' }} />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}
      <Dialog
        open={productDialogOpen}
        onClose={closeProductDialog}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
        sx={{ '& .MuiDialog-paper': { m: 0, position: 'absolute', bottom: 0, width: '100%' } }}
      >
        <Box p={2}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{selectedProduct?.name}</Typography>
            <IconButton onClick={closeProductDialog}>
              <Close />
            </IconButton>
          </Grid>
          <Typography variant="body1">{selectedProduct?.description}</Typography>
          <Typography variant="body2" color="textSecondary">Price: {selectedProduct?.price}</Typography>
          <TextField
            label="Notes"
            fullWidth
            multiline
            rows={2}
            value={productNotes}
            onChange={(e) => setProductNotes(e.target.value)}
            margin="normal"
          />
          {selectedProduct?.variations?.map((variation, idx) => (
            <Box key={idx} mt={2}>
              <Typography variant="subtitle1">{variation.name}</Typography>
              <TextField
                fullWidth
                value={productVariations[idx] || ''}
                onChange={(e) => {
                  const newVariations = [...productVariations];
                  newVariations[idx] = e.target.value;
                  setProductVariations(newVariations);
                }}
                margin="normal"
              />
            </Box>
          ))}
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => addToCart(selectedProduct)}
            >
              Add to Cart
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Container>
  ));
}

export default RestaurantOrderPage;
