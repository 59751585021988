import React from 'react';
import { withRouter } from '../withRouter'; // Make sure the file path is corrects
import InfoPage from '../templates/InfoPage';

function CommunityPage() {
  return (
    <InfoPage
      pageTitle="Our Community Initiatives"
      subtitle="Giving Back Through Donations and Food Sharing"
      sections={[
        {
          backgroundColor:"#FFFFFF",
          heading:"Share the Joy of Giving",
          text:"At Food Loop, we believe in making a positive impact on our community. Join us in our mission to fight hunger and support those in need. Every meal donated contributes to a brighter future for families and individuals facing hardships.",
          imageSrc:"/open-door.svg",
          altText:"Community Donation",
          },
        {
          backgroundColor:"#F9F9FF",
          heading:"How You Can Help",
          text:"Your contributions can make a real difference. Whether it's a small donation or a larger effort, every act of kindness counts. Partner with us to organize food drives, donate unused ingredients, or simply spread the word about our initiatives. Together, we can create a stronger and more caring community.",
          imageSrc:"/two-chefs.png",
          altText:"Community Help",
        },
        {
          backgroundColor:"#F3F4FF",
          heading:"Making an Impact",
          text:"We collaborate with local charities and shelters to ensure that the donated food reaches those who need it the most. With your support, we're able to provide nutritious meals to underserved communities and contribute to the well-being of our neighbors.",
          imageSrc:"/groceriesImage.png",
          altText:"Community Impact",
        },
        {
          backgroundColor:"#E7E9FB",
          heading:"Join Our Mission",
          text:"Your involvement can create a ripple effect of positive change. By participating in our community initiatives, you're not just helping with hunger relief – you're fostering a culture of compassion and support. Together, let's make a lasting impact and build a better future.",
          imageSrc:"/girlChair.svg",
          altText:"Community Join",
          button: {
            text: "Get Involved"
          }
        }
      ]}
    />
  );
}

export default withRouter(CommunityPage);
