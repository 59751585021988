import { makeAutoObservable, runInAction, toJS } from 'mobx';
import axios from 'axios';

class CartStore {
  cartItems = [];
  selectedRestaurantId = null;
  deliveryLocation = null;
  deliveryFee = 0;
  platformFee = 0;
  promotion = null;
  discount = 0;
  specialInstructions = '';
  isLoading = false;
  errorMessage = null;
  successMessage = null;
  user = null;
  mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  constructor() {
    makeAutoObservable(this);
    this.initializeCartState();
  }

  async initializeCartState() {
    const cartItems = window.localStorage.getItem('cartItems') || sessionStorage.getItem('cartItems');
    const selectedRestaurantId = window.localStorage.getItem('selectedRestaurantId') || sessionStorage.getItem('selectedRestaurantId');
    const deliveryLocation = window.localStorage.getItem('deliveryLocation') || sessionStorage.getItem('deliveryLocation');
    const deliveryFee = window.localStorage.getItem('deliveryFee') || sessionStorage.getItem('deliveryFee');
    const platformFee = window.localStorage.getItem('platformFee') || sessionStorage.getItem('platformFee');
    const promotion = window.localStorage.getItem('promotion') || sessionStorage.getItem('promotion');
    const discount = window.localStorage.getItem('discount') || sessionStorage.getItem('discount');
    const specialInstructions = window.localStorage.getItem('specialInstructions') || sessionStorage.getItem('specialInstructions');
    const user = window.localStorage.getItem('user') || sessionStorage.getItem('user');

    if (cartItems) {
      this.cartItems = JSON.parse(cartItems);
    }
    if (selectedRestaurantId) {
      this.selectedRestaurantId = selectedRestaurantId;
    }
    if (deliveryLocation) {
      this.deliveryLocation = deliveryLocation;
    }
    if (deliveryFee) {
      this.deliveryFee = parseFloat(deliveryFee);
    }
    if (platformFee) {
      this.platformFee = parseFloat(platformFee);
    }
    if (promotion) {
      this.promotion = promotion;
    }
    if (discount) {
      this.discount = parseFloat(discount);
    }
    if (specialInstructions) {
      this.specialInstructions = specialInstructions;
    }
    if (user) {
      this.user = JSON.parse(user);
    }
    this.calculateDeliveryFee();
  }

  async syncCartWithServer() {
    if (this.selectedRestaurantId) {
      try {
        const response = await axios.post('/api/cart/sync', {
          cartItems: toJS(this.cartItems),
          selectedRestaurantId: this.selectedRestaurantId,
          deliveryLocation: this.deliveryLocation,
          deliveryFee: this.deliveryFee,
          platformFee: this.platformFee,
          promotion: this.promotion,
          discount: this.discount,
          specialInstructions: this.specialInstructions,
          user: this.user,
        });
        runInAction(() => {
          this.cartItems = response.data.cartItems;
          this.selectedRestaurantId = response.data.selectedRestaurantId;
          this.deliveryLocation = response.data.deliveryLocation;
          this.deliveryFee = response.data.deliveryFee;
          this.platformFee = response.data.platformFee;
          this.promotion = response.data.promotion;
          this.discount = response.data.discount;
          this.specialInstructions = response.data.specialInstructions;
          this.persistCartState();
        });
      } catch (error) {
        console.error("Failed to sync cart with server", error);
      }
    }
  }

  persistCartState() {
    const cartState = {
      cartItems: JSON.stringify(toJS(this.cartItems)),
      selectedRestaurantId: this.selectedRestaurantId,
      deliveryLocation: this.deliveryLocation,
      deliveryFee: this.deliveryFee.toString(),
      platformFee: this.platformFee.toString(),
      promotion: this.promotion,
      discount: this.discount.toString(),
      specialInstructions: this.specialInstructions,
      user: JSON.stringify(this.user),
    };

    for (const key in cartState) {
      window.localStorage.setItem(key, cartState[key]);
      sessionStorage.setItem(key, cartState[key]);
    }
  }

  setSelectedRestaurantId(restId) {
    if (!this.selectedRestaurantId || this.selectedRestaurantId === '') {
      this.selectedRestaurantId = restId;
    } else {
      this.errorMessage = "You have already selected a restaurant.";
      return;
    }
  }

  setDeliveryLocation(location) {
    this.deliveryLocation = location;
    this.persistCartState();
    this.syncCartWithServer();
    this.calculateDeliveryFee();
  }

  setDeliveryFee(fee) {
    this.deliveryFee = fee;
    this.persistCartState();
    this.syncCartWithServer();
  }

  setPlatformFee(fee) {
    this.platformFee = fee;
    this.persistCartState();
    this.syncCartWithServer();
  }

  applyPromotion(code) {
    if (code === 'PROMO10') {
      this.promotion = code;
      this.discount = 10;
    } else {
      this.errorMessage = "Invalid promotion code";
      return;
    }
    this.persistCartState();
    this.syncCartWithServer();
  }

  setSpecialInstructions(instructions) {
    this.specialInstructions = instructions;
    this.persistCartState();
    this.syncCartWithServer();
  }

  addToCart(item) {
    const restaurantId = item.product.restLocation;

    // if (this.selectedRestaurantId && this.selectedRestaurantId !== restaurantId) {
    //   this.errorMessage = "You can only order from one restaurant at a time.";
    //   return;
    // }

    const existingItem = this.cartItems.find(
      (cartItem) => cartItem.product._id === item.product._id && JSON.stringify(cartItem.variations) === JSON.stringify(item.variations)
    );

    runInAction(() => {
      if (existingItem) {
        existingItem.quantity += item.quantity;
      } else {
        this.cartItems.push({
          ...item,
          restaurantId,
        });
        this.selectedRestaurantId = restaurantId;
      }
      this.successMessage = `${item.product.name} added to cart`;
      this.persistCartState();
      this.syncCartWithServer();
      this.calculateDeliveryFee();
    });
  }

  removeFromCart(productId) {
    runInAction(() => {
      const index = this.cartItems.findIndex(
        (item) => item.product._id === productId
      );
      if (index > -1) {
        this.cartItems.splice(index, 1);
        this.successMessage = "Item removed from cart";
        if (this.cartItems.length === 0) {
          this.selectedRestaurantId = null;
          window.localStorage.removeItem('selectedRestaurantId');
          sessionStorage.removeItem('selectedRestaurantId');
        }
        this.persistCartState();
        this.syncCartWithServer();
        this.calculateDeliveryFee();
      } else {
        this.errorMessage = "Item not found in cart";
      }
    });
  }

  updateQuantity(productId, quantity, variations) {
    if (quantity < 1) {
      this.errorMessage = "Quantity cannot be less than 1";
      return;
    }

    const cartItem = this.cartItems.find(
      (item) => item.product._id === productId && JSON.stringify(item.variations) === JSON.stringify(variations)
    );

    if (!cartItem) {
      this.errorMessage = "Item not found in cart";
      return;
    }

    runInAction(() => {
      cartItem.quantity = quantity;
      this.successMessage = "Quantity updated";
      this.persistCartState();
      this.syncCartWithServer();
      this.calculateDeliveryFee();
    });
  }

  clearCart() {
    runInAction(() => {
      this.cartItems = [];
      this.selectedRestaurantId = null;
      this.deliveryLocation = null;
      this.deliveryFee = 0;
      this.platformFee = 0;
      this.promotion = null;
      this.discount = 0;
      this.specialInstructions = '';
      this.successMessage = "Cart cleared";
      window.localStorage.removeItem('cartItems');
      window.localStorage.removeItem('selectedRestaurantId');
      window.localStorage.removeItem('deliveryLocation');
      window.localStorage.removeItem('deliveryFee');
      window.localStorage.removeItem('platformFee');
      window.localStorage.removeItem('promotion');
      window.localStorage.removeItem('discount');
      window.localStorage.removeItem('specialInstructions');
      sessionStorage.removeItem('cartItems');
      sessionStorage.removeItem('selectedRestaurantId');
      sessionStorage.removeItem('deliveryLocation');
      sessionStorage.removeItem('deliveryFee');
      sessionStorage.removeItem('platformFee');
      sessionStorage.removeItem('promotion');
      sessionStorage.removeItem('discount');
      sessionStorage.removeItem('specialInstructions');
      this.syncCartWithServer();
    });
  }

  async calculateDeliveryFee() {
    if (this.deliveryLocation && this.selectedRestaurantId) {
      const distance = await this.getDistanceFromRestaurant(this.deliveryLocation);
      const numberOfStops = this.cartItems.length;

      const baseFee = 5;
      const distanceFee = distance * 0.5; // $0.5 per mile
      const stopFee = numberOfStops * 2; // $2 per stop

      const totalFee = baseFee + distanceFee + stopFee;

      runInAction(() => {
        this.deliveryFee = totalFee;
        this.persistCartState();
      });
    }
  }

  async getDistanceFromRestaurant(location) {
    try {
      const restaurant = await axios.get(`/api/locations/${this.selectedRestaurantId}`);
      const restaurantLatLong = restaurant.data.address.latLong;
      const restaurantCoordinates = `${restaurantLatLong.lat},${restaurantLatLong.long}`;
      const deliveryCoordinates = location.coordinates; // Assuming the location has coordinates

      const response = await axios.get(`https://api.mapbox.com/directions/v5/mapbox/driving/${restaurantCoordinates};${deliveryCoordinates}`, {
        params: {
          access_token: this.mapboxAccessToken,
          geometries: 'geojson'
        }
      });

      const distance = response.data.routes[0].distance / 1609.34; // Convert meters to miles
      return distance;
    } catch (error) {
      console.error("Failed to get distance from restaurant", error);
      return 10; // Return a default value in case of error
    }
  }

  async loadPastCarts() {
    try {
      const response = await axios.get('/api/cart/past', {
        params: { userId: this.user ? this.user.id : null },
      });
      runInAction(() => {
        this.pastCarts = response.data;
      });
    } catch (error) {
      console.error("Failed to load past carts", error);
    }
  }

  async loadSavedCarts() {
    try {
      const response = await axios.get('/api/cart/saved', {
        params: { userId: this.user ? this.user.id : null },
      });
      runInAction(() => {
        this.savedCarts = response.data;
      });
    } catch (error) {
      console.error("Failed to load saved carts", error);
    }
  }

  async loadAbandonedCarts() {
    try {
      const response = await axios.get('/api/cart/abandoned', {
        params: { userId: this.user ? this.user.id : null },
      });
      runInAction(() => {
        this.abandonedCarts = response.data;
      });
    } catch (error) {
      console.error("Failed to load abandoned carts", error);
    }
  }

  async saveCart() {
    try {
      const response = await axios.post('/api/cart/save', {
        cartItems: toJS(this.cartItems),
        userId: this.user ? this.user.id : null,
        selectedRestaurantId: this.selectedRestaurantId,
        deliveryLocation: this.deliveryLocation,
        deliveryFee: this.deliveryFee,
        platformFee: this.platformFee,
        promotion: this.promotion,
        discount: this.discount,
        specialInstructions: this.specialInstructions,
      });
      runInAction(() => {
        this.successMessage = "Cart saved successfully";
      });
    } catch (error) {
      console.error("Failed to save cart", error);
      this.errorMessage = "Failed to save cart";
    }
  }

  get totalPrice() {
    return this.cartItems.reduce((total, item) => total + (item.product.price * item.quantity), 0) + this.deliveryFee + this.platformFee - this.discount;
  }
}

const cartStore = new CartStore();
export default cartStore;
