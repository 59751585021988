import React from "react";
import {
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const styles = {

    root: {
      display: 'flex',
      alignItems: 'center',
      background: '#FEFAF0',
    },
    image: {
      width: '30vw',
      height: 'auto',
      marginRight: '16px',
    },
    content: {
      flex: 1,
    },
    header: {
      fontWeight: 'bold',
      maxWidth: '90%',
      fontSize: {
        xs: '30px',
        sm: '40px',
        md: '50px',
        lg: '60px',
      },
    },
    subheader: {
      marginTop: '16px',
      marginBottom: '32px',
      maxWidth: '90%',
      minWidth: '300px',
      fontSize: {
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
      },
    },
  };

  export default function PocketSection({ image, title, subtitle }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
      <div style={{ background: 'linear-gradient(to bottom right, #2A3890, #0C102A)' }}>
        <div style={{
          ...styles.root,
          background: 'linear-gradient(to bottom right, #2A3890, #0C102A)',
          maxWidth: '100%',
          minHeight: '50vh',
          flexDirection: isMobile ? "column" : "row",
        }}>
          {!isMobile && (
            <img
            src={image}
            alt="FoodLoop"
            style={{ ...styles.image}}
            />
          )}
          <div style={{ ...styles.content, paddingLeft: '5vw', paddingTop: '5vw', minWidth: '300px', maxWidth: '80%' }}>
            <Typography variant="h1" color="white" sx={{ ...styles.header, paddingBottom: '5vw' }}>
              {title}
            </Typography>
            <Typography variant="body1" color="white" sx={{ ...styles.subheader}}>
              {subtitle}
            </Typography>
          </div>
          {isMobile && (
          <img
            src={image}
            alt="FoodLoop"
            style={{
              ...styles.image,
              width: "80%",
              height: "auto",
              paddingBottom: '5vw',
              order: 2,
            }}
          />
          )}
        </div>
      </div>
    );
  }