import React from "react";
import Button from "@mui/material/Button";
import {
  Grid,
  Typography,
  Box,
  ButtonBase
} from "@mui/material";

const styles = {
  header: {
    fontWeight: 'bold',
    minWidth: '400px',
    fontSize: {
      xs: '30px',
      sm: '30px',
      md: '40px',
      lg: '50px',
    },
  },
  subheader: {
    marginTop: '16px',
    marginBottom: '32px',
    lineHeight: 2,
    minWidth: '450px',
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '16px',
      lg: '18px',
    },
  },
}

export default function DollarsSection({ image, title, subtitle, handleClick }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", paddingTop: '20vh' }}>
      <Grid container spacing={3} style={{ paddingBottom: '30px', width: '75vw' }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h1" sx={{ ...styles.header }}>
            {title} <br />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" style={{ lineHeight: '2' }}>
            {subtitle}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'flex-start'}}>
              <ButtonBase
                sx={{
                  width: '300px',
                  marginLeft: { xs: 0, md: '10px' },
                }}
                onClick={handleClick}
              >
                <img src="/playstore-black.svg" alt="Play Store" />
              </ButtonBase>
              <ButtonBase
                sx={{
                  width: '300px',
                  marginLeft: { xs: 0, md: '10px' },
                }}
                onClick={handleClick}
              >
                <img src="/appStore-black.svg" alt="App Store" />
              </ButtonBase>
            </Box>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative', flex: '1', height: '100%', maxHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <img src={image} alt='money plate' style={{ width: '75vw', height: 'auto' }} />
        <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              borderRadius: '16px',
              width: '100%',
              height: '100%',
              background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8))',
              zIndex: '2'
            }}
          />
      </Box>
    </div>
  );
}