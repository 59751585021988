import { makeAutoObservable, runInAction } from 'mobx';
import apiHandler from '../api/ApiHandler';

class ProductStore {
  products = [];
  isLoading = false;
  errorMessage = null;
  successMessage = null;
  statusCode = null;
  filters = {
    searchTerm: '',
    cuisine: '',
    rating: null,
  };
  location = null; // { latitude: number, longitude: number }
  restaurantId = null;

  constructor() {
    makeAutoObservable(this);
  }

  setLocation(latitude, longitude) {
    this.location = { latitude, longitude };
  }

  setFilter(key, value) {
    this.filters[key] = value;
  }

  setRestaurantId(rest) {
    this.restaurantId = rest;
  }

  async fetchProducts(limit = 10, filter = {}) {
    // Setting geolocation search later - need API built for it
    // if (!this.location) {
    //   this.errorMessage = 'Geolocation is not set.';
    //   return;
    // }
    
    this.isLoading = true;
    this.errorMessage = null;
    this.successMessage = null;
    this.statusCode = null;

    try {
      // const queryParams = new URLSearchParams({
      //   ...this.filters,
      //   lat: this.location.latitude,
      //   lon: this.location.longitude,
      // }).toString();

      if (this.restaurantId && this.restaurantId !== '') {
        filter = { ...filter, restaurant: this.restaurantId };
      }
      const response = await apiHandler.get(`products?filter=${JSON.stringify(filter)}&limit=${limit}`);

      runInAction(() => {
        this.statusCode = response.status;
        if (response) {
          this.products = response.results;

          this.successMessage = 'Products fetched successfully';
        } else {
          this.handleAPIError(response);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        this.errorMessage = error.message || "Network request failed";
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
      return;
    }
  }

  handleAPIError(response) {
    switch (response.status) {
      case 204:
        this.products = [];
        this.successMessage = 'No content, no products found';
        break;
      case 400:
        this.errorMessage = 'Bad request, please modify your search criteria';
        break;
      case 404:
        this.errorMessage = 'Not found, no matching products';
        break;
      case 500:
        this.errorMessage = 'Internal server error, please try again later';
        break;
      default:
        this.errorMessage = `Received unexpected status code: ${response.status}`;
        break;
    }
  }

  // Additional robust methods for product operations, including filtering by rating, cuisine, etc.
}

const productStore = new ProductStore();
export default productStore;
