import React from 'react';
import { Container, Typography, Link, Box, useTheme, useMediaQuery } from '@mui/material';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const footerStyle = {
    height: '50vh',
    background: 'linear-gradient(180deg, rgb(0, 8, 60) 0%, rgb(0, 0, 0) 100%)',
    color: 'white',
    padding: '16px',
    mixBlendMode: 'multiply',
  };

  const headingStyle = {
    margin: '25px',
    fontSize: theme.typography.h4.fontSize,
  };

  const linkBoxStyle = {
    margin: '0 25px',
  };

  const linkContainerStyle = {
    marginBottom: '12px',
    display: 'block',
  };

  const linkStyle = {
    color: 'white',
  };
  const currentYear = new Date().getFullYear();
  return (
    <footer style={footerStyle}>
      <Container>
        <Typography variant="h4" style={headingStyle}>
          <span>© {currentYear} &nbsp;</span>FL Marketplace LLC
        </Typography>
        {isMobile ? (
          <Box style={{ display: 'flex', flexDirection: 'column', linkBoxStyle}}>
            {/* Company Links */}
            <Link href="/about" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
              <Typography variant="body1">About Us</Typography>
            </Link>
            <Link href="#" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
              <Typography variant="body1">Investor Relations</Typography>
            </Link>
            <Link href="/news" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
              <Typography variant="body1">Insights and Updates</Typography>
            </Link>
            <Link href="/careers" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
              <Typography variant="body1">Career Opportunities</Typography>
            </Link>
            {/* Product Links */}
            <Link href="/culinaryexperiences" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
              <Typography variant="body1">Our Culinary Experiences</Typography>
            </Link>
            <Link href="/driver" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
              <Typography variant="body1">Deliver with Us</Typography>
            </Link>
            <Link href="#" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
              <Typography variant="body1">For Business Innovators</Typography>
            </Link>
            {/* Other Links */}
            <Link href="/community" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
              <Typography variant="body1">Global Impact Initiatives</Typography>
            </Link>
            <Link href="/safety" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
              <Typography variant="body1">Prioritizing Safety</Typography>
            </Link>
            <Link href="/diversity" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
              <Typography variant="body1">Celebrating Diversity</Typography>
            </Link>
            <Link href="/sustainability" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
              <Typography variant="body1">Championing Sustainability</Typography>
            </Link>
            <Link href="/innovation" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
              <Typography variant="body1">Exploring New Innovations</Typography>
            </Link>
          </Box>
        ) : (
          <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', margin: '0 -25px', padding: '25px', width: 'calc(100% + 50px)' }}>
            {/* Company Links */}
            <Box style={linkBoxStyle}>
              <Link href="/about" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">About Us</Typography>
              </Link>
              <Link href="#" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Investor Relations</Typography>
              </Link>
              <Link href="/news" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Insights and Updates</Typography>
              </Link>
              <Link href="/careers" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Career Opportunities</Typography>
              </Link>
            </Box>
            {/* Product Links */}
            <Box style={linkBoxStyle}>
              <Link href="/culinaryexperiences" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Our Culinary Experiences</Typography>
              </Link>
              <Link href="/driver" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Deliver with Us</Typography>
              </Link>
              <Link href="#" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">For Business Innovators</Typography>
              </Link>
            </Box>
            {/* Other Links */}
            <Box style={linkBoxStyle}>
              <Link href="/community" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Global Impact Initiatives</Typography>
              </Link>
              <Link href="/safety" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Prioritizing Safety</Typography>
              </Link>
              <Link href="/diversity" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Celebrating Diversity</Typography>
              </Link>
              <Link href="/sustainability" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Championing Sustainability</Typography>
              </Link>
              <Link href="/innovation" underline="none" sx={{ ...linkStyle, ...linkContainerStyle }}>
                <Typography variant="body1">Exploring New Innovations</Typography>
              </Link>
            </Box>
          </section>
        )}
      </Container>
    </footer>
  );
};

export default Footer;



