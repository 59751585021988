import React from "react";
import Button from "@mui/material/Button";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box
} from "@mui/material";

const styles = {

    root: {
      display: 'flex',
      alignItems: 'center',
      background: '#FEFAF0',
      padding: '20px'
    },
    image: {
      width: '30vw',
      paddingLeft: '5vw',
      paddingRight: '5vw',
      height: 'auto',
      marginRight: '16px',
    },
    content: {
      flex: 1,
    },
    header: {
      fontWeight: 'bold',
      maxWidth: '90%',
      fontSize: {
        xs: '30px',
        sm: '40px',
        md: '50px',
        lg: '60px',
      },
    },
    header2: {
      fontWeight: 'bold',
      fontSize: '40px',
        '@media (max-width: 1200px)': {
          fontSize: '30px',
        },
        '@media (max-width: 992px)': {
          fontSize: '25px',
        },
        '@media (max-width: 768px)': {
          maxWidth: '200px',
          fontSize: '20px'
        },
    },
    subheader: {
      marginTop: '16px',
      marginBottom: '32px',
      maxWidth: '90%',
      minWidth: '300px',
      fontSize: {
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
      },
    },
    subheader2: {
      marginTop: '16px',
      marginBottom: '32px',
      fontSize: '18px',
        '@media (max-width: 1200px)': {
          fontSize: '16px',
        },
        '@media (max-width: 992px)': {
          fontSize: '14px',
        },
        '@media (max-width: 768px)': {
          fontSize: '12px',
          maxWidth: '200px'
        },
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
    },
    listButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    checkIcon: {
      marginRight: '8px',
      color: '#FF4848',
    },
    reviewRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    reviewContainer: {
      shadows: 'none',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      padding: '50px',
    },
    scrollableContainer: {
      overflowX: 'auto',
      whiteSpace: 'nowrap', 
    },
    centeredImage: {
      position: "absolute",
      width: "125%",
      height: "95vh",
      objectFit: "cover",
      mixBlendMode: "multiply",
      zIndex: -2,
      left: "-10vw",
    },
    scrollingBanner: {
      transform: 'translateX(-15px) rotate(5deg)',
      width: "110%",
      paddingTop: "50px",
      paddingBottom: "15vh"
    },
    section: {
      marginTop: 50,
      marginBottom: 50,
      paddingTop: 30,
      paddingBottom: 30,
    },
    last: {
      marginTop: 80,
      paddingTop: 30,
    },
    contentOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    scrollableContent: {
      height: "100vh",
      overflowY: "auto",
    },
  };

  const TopTierSection = ({backgroundColor, image, mainLine, headerText, subheaderText, profitIncreaseText, controlMenuText, subText1, subText2, buttonText, headerColor }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
      <div>
        <div style={{
          ...styles.root,
          background: backgroundColor,
          overflow: 'hidden',
          minHeight: '30vh',
          maxWidth: '100%',
        }}>
          <Box sx={{ ...styles.content, paddingLeft: {xs: '10px', md: '50px'} }}>
            <Typography variant="h5" style={{ fontWeight: 'lighter', color: 'white', letterSpacing: 3, paddingBottom: '2vh', paddingTop: '2vh' }}>
              {mainLine}
            </Typography>
            <Typography variant="h1" sx={{ ...styles.header, fontSize: '50px', color: '#FFC501', backgroundColor:{headerColor} }}>
              {headerText || "Boost Your Bottom Line Like Never Before."}
            </Typography>
            <Typography variant="body1" sx={{...styles.subheader, color: 'white'}}>
              {subheaderText ||
                "FoodLoop isn't just about delivery – it's about amplifying your profits. As each dish leaves your kitchen, know that it's backed by a delivery model that emphasizes your margins, with zero surprise fees."}
            </Typography>
            <Grid container spacing={3} style = {{paddingTop: '5vh'}}>
              <Grid item xs={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h3" sx={{...styles.header2, color: '#FFC501', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                      {profitIncreaseText}
                    </Typography>
                    <Typography variant="body1" sx={{...styles.subheader2, color: 'white' }}>
                      {subText1}
                    </Typography>
                  </div>
                </div>
              </Grid>
  
              <Grid item xs={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h3" sx={{...styles.header2, color: '#FFC501', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                      {controlMenuText}
                    </Typography>
                    <Typography variant="body1" sx={{...styles.subheader2, color: 'white' }}>
                      {subText2}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
          {!isMobile && (
          <img
            src={image}
            alt="FoodLoop"
            style={{ ...styles.image, width: '35vw', bottom: 0, paddingTop: '15vh'}}
          />
          )}
        </div>
      </div>
    );
  }
  
  export default TopTierSection;