import React from 'react';
import { Box, Typography, TextField, Button, styled } from '@mui/material';

const DecorativeLines = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: -2,
  width: '100%',
  height: '100%',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    backgroundImage: `url('/assets/topo.svg')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '50%',
    height: '50%',
    opacity: 0.6,
  },
  '&::before': {
    right: 0,
    top: 0,
    transform: 'translateX(50%)',
  },
  '&::after': {
    left: 0,
    bottom: 0,
    transform: 'translateX(-50%)',
  }
}));

function HighlightedBox({
  header,
  subtitle,
  inputLabel,
  buttonText,
  headerColor,
  subtitleColor
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '24px',
        height: 250,
        backgroundImage: 'linear-gradient(180deg, #FFD700, #FFA500), url(/topo.svg)',
        backgroundSize: 'cover, cover',
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundBlendMode: 'overlay',
        color: headerColor,
        p: 4,
        position: 'relative',
        maxWidth: '90%',
        width: 900,
        zIndex: 5,
        mb: -12.5
      }}
    >
      <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: headerColor, mb: 2 }}>
        {header}
      </Typography>
      <Typography variant="subtitle1" sx={{ color: subtitleColor, mb: 3 }}>
        {subtitle}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: 500
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          label={inputLabel}
          sx={{ mr: 2, backgroundColor: '#FFF' }}
        />
        <Button variant="contained" color="primary" sx={{ whiteSpace: 'nowrap' }}>
          {buttonText}
        </Button>
      </Box>
      <DecorativeLines />
    </Box>
  );
}

export default HighlightedBox;
