import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import ButtonBase from '@mui/material/ButtonBase';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import FastFoodIcon from "@mui/icons-material/Fastfood";
import { darken, lighten} from "@material-ui/core/styles/colorManipulator";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';



function CardComponent({ to, imageSrc, title, description, color, textColor, deal }) {
  const theme = useTheme();
  const imageUrl = imageSrc || '/FoodLoopLogo.png';
  const darkenedColor = darken(color, 0.2);
  const darkenedText = darken(color, 0.65);
  const lightenedText = lighten(color, 0.85);
  const handleClick = () => {
  };
  return (
    <div style={{ margin: '25px' }}>
      <Link to={to} style={{ textDecoration: 'none' }}>
      <ButtonBase
      onClick={handleClick}
      sx={{
        width: "300px",
        textDecoration: "none",
        borderRadius: '18px'
      }}>
      <Card
        elevation={0}
        sx= {{borderRadius: '18px', backgroundColor: color, width: '300px'}}
      >
        {deal && (
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  top: '20px',
                  left: '5px',
                  backgroundColor: darkenedColor,
                  elevation: 5,
                  position: 'absolute', // Set position to absolute
                  zIndex: 1, // Set z-index to ensure it's on top
                }}
              >
                <AttachMoneyIcon sx={{color: darkenedText, fontSize: '18px'}}/>
                <Typography sx={{color: lightenedText, fontSize: '18px'}}>{deal}</Typography>
                <AttachMoneyIcon sx={{color: darkenedText, fontSize: '18px'}}/>
              </Card>
          )}
        <CardMedia
          component="img"
          height="180px"
          image={imageUrl}
          alt={title}
          style={{ width: "100%" }}
        />
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" style={{ color: textColor ? darkenedText : lightenedText, fontWeight: 'bold', textAlign: 'left' }}>{title}</Typography>
            <FastFoodIcon sx={{ color: textColor ? darkenedText : lightenedText }} />
          </div>
          <Typography variant="body2" style={{ color: textColor ? darkenedText : lightenedText, textAlign: 'left'}}>{description}</Typography>
        </CardContent>
      </Card>
    </ButtonBase>
      </Link>
    </div>
  );
}

export default CardComponent;
