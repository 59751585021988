import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CustomCard = ({ iconImage, mainImage, header, body, handleClick}) => {
  return (
    <Card style={{ borderRadius: '22px', height: '550px', maxWidth: '450px', margin: '0 10px 20px 0px', background: 'linear-gradient(white 0%, white 66%, #FF4848 66%, #FF4848 100%)', }}>
      <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <img
          src={iconImage}
          alt="Icon"
          style={{ width: '24px', height: '24px', margin: '20px 0 0 20px' }}
        />
        <Typography variant="h5" component="div" mt={2} style={{ margin: '20px', fontWeight: 'bold' }}>
          {header}
        </Typography>
        <Typography variant="body2" color="text.secondary" mt={2} style={{ margin: '20px' }}>
          {body}
        </Typography>
        <Button
          variant="text"
          endIcon={<ArrowForwardIcon />}
          style={{ color: '#808080', margin: '20px 0 0 20px' }}
          onClick={handleClick}
        >
          Learn More
        </Button>
      </CardContent>
      <CardMedia
        component="img"
        height="40%"
        image={mainImage}
        alt="Main Image"
        style={{ objectFit: 'fill', paddingTop: '5%',  }}
      />
    </Card>
  );
};

export default CustomCard;
