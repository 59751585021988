import { makeAutoObservable, runInAction } from 'mobx';
import apiHandler from '../api/ApiHandler';

class RestaurantStore {
  restaurants = [];
  restaurantLocations = [];
  restaurantLocation = null;
  filters = {
    searchTerm: '',
    cuisine: '',
    rating: null,
  };
  currentLocation = null;
  isLoading = false;
  errorMessage = null;
  successMessage = null;
  statusCode = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentLocation(location) {
    this.currentLocation = location;
  }

  setSearchTerm(term) {
    this.filters.searchTerm = term.trim();
  }

  setCuisine(cuisine) {
    this.filters.cuisine = cuisine.trim();
  }

  setRating(rating) {
    this.filters.rating = rating;
  }

  setRestaurantLocation(location) {
    this.restaurantLocation = location;
  }

  async getRestaurant(restId) {
    try {
      return await apiHandler.get(`restaurants/${restId}`);
    } catch (error) {
      return error;
    }
  }

  async fetchRestaurants() {
    if (!this.currentLocation) {
      this.errorMessage = 'Current location is not set.';
      return;
    }

    this.isLoading = true;
    this.errorMessage = null;
    this.successMessage = null;
    this.statusCode = null;

    try {
      const queryParams = new URLSearchParams({
        ...this.filters,
        lat: this.currentLocation.latitude,
        lon: this.currentLocation.longitude,
      }).toString();

      const response = await apiHandler.get(`search/restaurants?${queryParams}`);
      runInAction(() => {
        this.statusCode = response.status;
        if (response.status === 200) {
          this.restaurants = response.data.restaurants;
          this.successMessage = 'Restaurants fetched successfully';
        } else {
          this.handleAPIError(response);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        this.errorMessage = error.message || "Network request failed";
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async fetchLocations(restId) {
    if (!restId) {
      this.errorMessage = 'Restaurant ID is not set.';
      return;
    }

    this.isLoading = true;
    this.errorMessage = null;
    this.successMessage = null;
    this.statusCode = null;

    try {
      const response = await apiHandler.get(`locations/restaurant/${restId}`);
      runInAction(() => {
        this.statusCode = response.status;
        if (response.status === 200) {
          this.restaurantLocations = response.data.locations;
          if (this.restaurantLocations.length === 1) {
            this.restaurantLocation = this.restaurantLocations[0];
          }
          this.successMessage = 'Locations fetched successfully';
        } else {
          this.handleAPIError(response);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        this.errorMessage = error.message || "Network request failed";
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  handleAPIError(response) {
    switch (response.status) {
      case 204:
        this.restaurants = [];
        this.successMessage = 'No content, no restaurants found';
        break;
      case 400:
        this.errorMessage = 'Bad request, please modify your search criteria';
        break;
      case 404:
        this.errorMessage = 'Not found, no matching restaurants';
        break;
      case 500:
        this.errorMessage = 'Internal server error, please try again later';
        break;
      default:
        this.errorMessage = `Received unexpected status code: ${response.status}`;
        break;
    }
  }

  sortByDistance() {
    if (!this.currentLocation) {
      this.errorMessage = 'Geolocation is not set.';
      return;
    }

    this.restaurants = this.restaurants.sort((a, b) => {
      const distanceA = this.calculateDistance(this.currentLocation.latitude, this.currentLocation.longitude, a.location.latitude, a.location.longitude);
      const distanceB = this.calculateDistance(this.currentLocation.latitude, this.currentLocation.longitude, b.location.latitude, b.location.longitude);
      return distanceA - distanceB;
    });
  }

  calculateDistance(lat1, lon1, lat2, lon2) {
    const toRadians = degrees => degrees * (Math.PI / 180);
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  }

  async fetchGeolocation() {
    if (!navigator.geolocation) {
      this.errorMessage = 'Geolocation is not supported by this browser.';
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        runInAction(() => {
          this.currentLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          this.successMessage = 'Geolocation fetched successfully';
        });
      },
      (error) => {
        runInAction(() => {
          this.errorMessage = error.message;
        });
      }
    );
  }

  filterByRating(rating) {
    return this.restaurants.filter(restaurant => restaurant.rating.average >= rating);
  }

  filterByCuisine(cuisine) {
    return this.restaurants.filter(restaurant => restaurant.tags.includes(cuisine));
  }

  searchRestaurants(searchTerm) {
    return this.restaurants.filter(restaurant =>
      restaurant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      restaurant.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  async fetchRestaurantsByDistance(radius) {
    if (!this.currentLocation) {
      this.errorMessage = 'Current location is not set.';
      return;
    }

    this.isLoading = true;
    this.errorMessage = null;
    this.successMessage = null;
    this.statusCode = null;

    try {
      const response = await apiHandler.get(`restaurants?lat=${this.currentLocation.latitude}&lon=${this.currentLocation.longitude}&radius=${radius}`);

      runInAction(() => {
        this.statusCode = response.status;
        if (response) {
          this.restaurants = response.results.map(restaurant => ({
            ...restaurant,
            distance: this.calculateDistance(
              this.currentLocation.latitude,
              this.currentLocation.longitude,
              restaurant.location.latitude,
              restaurant.location.longitude
            )
          }));
          this.successMessage = 'Restaurants fetched successfully';
        } else {
          this.handleAPIError(response);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        this.errorMessage = error.message || "Network request failed";
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
      return;
    }
  }
}

const restaurantStore = new RestaurantStore();
export default restaurantStore;
