import React from "react";
import Button from "@mui/material/Button";
import {
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const styles = {

    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '20px',
      background: '#FEFAF0',
    },
    image: {
      paddingRight: '2vw',
      height: {
        xs: '20px',
        sm: '25px',
        md: '30px',
        lg: '40px',
      },
    },
    imageContainer: {
      position: 'relative',
      width: '30vw',
      height: '50vw',
      margin: '5vw 5vw 5vw 0 ',
      overflow: 'hidden',
    },
    imageMain: {
      width: '100%',
      height: '100%',
      borderRadius: '50px',
      objectFit: 'cover',
    },
    gradientOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '50px',
      width: '100%',
      height: '100%',
      background: 'radial-gradient(circle at 50% 70%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%)',
    },
    content: {
      flex: 1,
    },
    header: {
      fontWeight: 'bold',
      fontSize: '40px',
      fontSize: {
        xs: '20px',
        sm: '25px',
        md: '30px',
        lg: '40px',
      },
    },
    subheader: {
      marginTop: '16px',
      marginBottom: '32px',
      fontSize: '18px',
      maxWidth: '90%',
      minWidth: '300px',
      fontSize: {
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
      },
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
    },
    listButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    checkIcon: {
      marginRight: '8px',
      color: '#FF4848',
    },
  };

  function CompetitiveSection({items, image}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
      <Box sx={{ backgroundColor: '#FFFFFF', minHeight: '85vh', paddingLeft: {xs: '10px', md: '50px'}}}>
        <div style={{
          ...styles.root,
          backgroundColor: '#FFFFFF',
          overflow: 'hidden',
          minHeight: '80vh',
          maxWidth: '100%',
        }}>
          {!isMobile && (
           <Box style={styles.imageContainer}>
            <img src={image} alt="FoodLoop" style={styles.imageMain} />
            <Box style={styles.gradientOverlay}/>
           </Box>
          )}
          <div style={{ ...styles.content}}>
            <List>
              {items.map((item, index) => (
                <ListItem key={index} style={styles.listItem}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <Typography variant="h2" sx={{...styles.header, color: '#333', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        <img src = {item.src} 
                        style={{...styles.image}}/>
                        {item.title}
                      </Typography>
                      <Typography variant="body1" sx={{...styles.subheader, color: '#808080', paddingLeft: '90px', paddingBottom: '50px' }}>
                        {item.description}
                      </Typography>
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
      </div>
    </Box>
    );
  }

  export default CompetitiveSection;