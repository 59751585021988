import React, { useState } from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';

const LoginForm = ({ onLogin, isLoading, username, setUsername, password, setPassword }) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin();
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        margin="normal"
        label="Username"
        variant="outlined"
        value={username}
        onChange={event => setUsername(event.target.value)}
        placeholder="Enter your username"
      />

      <TextField
        fullWidth
        margin="normal"
        label="Password"
        type="password"
        variant="outlined"
        value={password}
        onChange={event => setPassword(event.target.value)}
        placeholder="Enter your password"
      />

      <Button
        fullWidth
        style={{ marginTop: '16px', borderRadius: '25px' }}
        type="submit"
        variant="contained"
        color="primary"
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
      </Button>
    </form>
  );
};

export default LoginForm;
