import React from 'react';
import { withRouter } from '../withRouter';
import InfoPage from '../templates/InfoPage'; // Make sure the file path is correct

function SafetyPage() {
  return (
    <InfoPage
      pageTitle="Your Safety is Our Priority"
      subtitle="Ensuring Safe Deliveries and Enjoyable Meals"
      sections={[
        {
          backgroundColor: "#FFFFFF",
          heading: "Driver Safety Measures",
          text: "We prioritize the safety of our drivers by implementing strict hygiene and sanitation protocols. All drivers are required to follow proper hand hygiene, wear masks and gloves during food handling, and maintain safe distancing while interacting with customers.",
          imageSrc: "/driverImage.png",
          altText: "Driver Safety",
        },
        {
          backgroundColor: "#F9F9FF",
          heading: "Food Packaging and Handling",
          text: "Every meal is prepared, packaged, and sealed with care to ensure its freshness and safety during transit. We use tamper-evident packaging to provide customers with peace of mind, guaranteeing that their orders arrive untouched and secure.",
          imageSrc: "/fastFoodImage.png",
          altText: "Food Packaging",
        },
        {
          backgroundColor: "#F3F4FF",
          heading: "Contactless Delivery",
          text: "Our contactless delivery option allows customers to receive their orders without direct contact with the driver. You can request for your meal to be left at your doorstep, ensuring a safe and convenient experience.",
          imageSrc: "/deliveryDriver.svg",
          altText: "Contactless Delivery",
        },
        {
          backgroundColor: "#E7E9FB",
          heading: "Customer Guidelines",
          text: "To ensure your safety while enjoying your meal, we recommend washing your hands before consuming, transferring your meal to your own plate if desired, and following the latest health guidelines provided by authorities.",
          imageSrc: "/groceriesImage.png",
          altText: "Safety Guidelines",
          button: {
            text: "Learn More",
          },
        },
      ]}
    />
  );
}

export default withRouter(SafetyPage);
