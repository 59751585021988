import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Tab,
  Tabs,
  Snackbar,
  CircularProgress,
  styled,
} from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ContactVerification from '../components/ContactVerification';
import authStore from '../stores/AuthStore';
import OauthOrVerify from '../components/OauthOrVerify';
import SetPassword from '../components/SetPassword';
import UserDetails from '../components/UserDetails';
import FoodPreferences from '../components/FoodPreferences';
import LoginForm from '../components/LoginForm';
import apiHandler from '../api/ApiHandler';

const CardWrapper = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(4),
  backdropFilter: 'blur(8px)',
  zIndex: 1,
}));

const SKIP_CONTACT_AND_MFA = true;
function AuthPage() {
  const handleBack = () => {
    if (step > 1) {
      const newStep = step - 1;
      setStep(newStep);
      navigate(`?step=${newStep}`);
    }
  };

  const openSnackbar = (message) => {
    setSnackbarMessage(message);
    setIsSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const handlePhoneNumberOrEmailContinue = async (data) => {
    authStore.setEmail(data);
    setEmail(data);
    if (SKIP_CONTACT_AND_MFA) {
      setStep(3);
    } else {
      setStep(2);
    }
  };

  const handleOAuthSelection = (provider) => {
    openSnackbar(`OAuth selected for: ${provider}`);
  };

  const handleVerifyPhoneNumber = async (code) => {
    setIsLoading(true);
    try {
      const result = await authStore.verifyCode(code);
      if (result) {
        openSnackbar('Phone number verified!');
        setStep(3);
      } else {
        openSnackbar('Error verifying phone number.');
      }
    } catch (error) {
      openSnackbar('Error verifying phone number.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordSet = async (passwordData) => {
    authStore.setPassword(passwordData.password);
    authStore.setUsername(passwordData.username);
    setPassword(passwordData.password);
    setUsername(passwordData.username);
    openSnackbar('Password set successfully!');

    try {
      await authStore.signUp();
      if (authStore.isAuthenticated) {
        setStep(4);
      } else {
        openSnackbar('Sign-up failed.');
      }
    } catch (error) {
      if(error.response && error.response.data && error.response.data.message) {  
        openSnackbar(error.response.data.message)
      } else {
        openSnackbar(authStore.errorMessage || 'Error during sign-up.');
      }
    }
  };

  const handleSaveDetails = async (userDetails) => {
    userDetails.fullName = `${userDetails.firstName} ${userDetails.lastName}`;
    setIsLoading(true);
    try {
      await authStore.updateUserDetails(userDetails);
      openSnackbar('Details saved successfully!');
      setStep(5);
    } catch (error) {
      openSnackbar('Error saving user details.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSkipDetails = async () => {
    setStep(5);
  };

  const isOAuthSelected = () => {
    return false;
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      await authStore.setUsername(username);
      await authStore.setPassword(password);
      await authStore.login();
      if (authStore.isAuthenticated) {
        navigate(returnUrl);
      } else {
        openSnackbar('Login failed');
      }
    } catch (error) {
      if(error.response && error.response.data && error.response.data.message) {  
        openSnackbar(error.response.data.message)
      } else {
        openSnackbar(authStore.errorMessage || 'Error during login.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegister = async () => {
    setIsLoading(true);
    try {
      authStore.setUsername(username);
      authStore.setEmail(email);
      authStore.setPassword(password);
      await authStore.signUp();
      if (authStore.isAuthenticated) {
        navigate(returnUrl);
      } else {
        openSnackbar('Registration failed');
      }
    } catch (error) {
      if(error.response && error.response.data && error.response.data.message) {  
        openSnackbar(error.response.data.message)
      } else {
        openSnackbar(authStore.errorMessage || 'Error during registration.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    setIsLoading(true);
    try {
      authStore.setEmail(resetEmail);
      await authStore.forgotPassword();
      openSnackbar('Password reset email sent');
    } catch (error) {
      openSnackbar('Error sending password reset email');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategorySelection = async selectedCategories => {
    setIsLoading(true);
    try {
      openSnackbar('Food preferences updated!');
    } catch (error) {
      openSnackbar('Error updating food preferences.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <ContactVerification
            skipContactAndMFA={SKIP_CONTACT_AND_MFA}
            onContinue={handlePhoneNumberOrEmailContinue}
            onOAuth={handleOAuthSelection}
          />
        );
      case 2:
        return (
          <OauthOrVerify
            isOauth={isOAuthSelected}
            onVerify={handleVerifyPhoneNumber}
          />
        );
      case 3:
        return <SetPassword onPasswordSet={handlePasswordSet} />;
      case 4:
        return (
          <UserDetails
            onSaveDetails={handleSaveDetails}
            onSkipDetails={handleSkipDetails}
            existingEmail={email ? email : 'example@gmail.com'}
            existingPhone=''
          />
        );
      case 5:
        return (
          <FoodPreferences
            categories={['Pizza', 'Sushi', 'Burgers', 'Pasta']}
            onSelect={handleCategorySelection}
            confirm={handleFinishSetup}
          />
        );
      default:
        return null;
    }
  };

  const handleFinishSetup = async () => {
    navigate(returnUrl);
  };

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const returnUrl = searchParams.get('returnUrl') || '/';

  const stepFromUrl = parseInt(searchParams.get('step'), 10);
  const [step, setStep] = useState(stepFromUrl || 1);

  const navigate = useNavigate();

  const handleRegisterValid = true;

  const [activeTab, setActiveTab] = useState(type === 'signup' ? 1 : 0);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(
    type === 'forgot'
  );
  const [resetEmail, setResetEmail] = useState('');

  const handleToastClose = () => {
    setShowToast(false);
  };

  const checkPasswordStrength = password => {
    return password.length >= 8;
  };

  const [resetPasswordFlow, setResetPasswordFlow] = useState('forgotPassword');
  const [registrationSubmitted, setRegistrationSubmitted] = useState(false);

  const handleResetPassword = async () => {
    setIsLoading(true);
    try {
      const response = await apiHandler.post('/reset-password', {
        token: '',
        newPassword: '',
      });
      setToastMessage('Password reset successfully.');
      setShowToast(true);
      setResetPasswordFlow('forgotPassword');
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : 'Unknown error occurred';
      setToastMessage(`Password reset failed: ${errorMessage}`);
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  const sendMFASMS = async (userId, phoneNumber) => {
    setIsLoading(true);
    try {
      const response = await apiHandler.post('/mfa/sms', {
        userId,
        phoneNumber,
      });
      setToastMessage('MFA code sent via SMS successfully.');
      setShowToast(true);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : 'Unknown error occurred';
      setToastMessage(`Failed to send MFA code via SMS: ${errorMessage}`);
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  const sendMFAEmail = async (userId, email) => {
    setIsLoading(true);
    try {
      const response = await apiHandler.post('/mfa/email', {
        userId,
        email,
      });
      setToastMessage('MFA code sent via Email successfully.');
      setShowToast(true);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : 'Unknown error occurred';
      setToastMessage(`Failed to send MFA code via Email: ${errorMessage}`);
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyMFACode = async (code) => {
    setIsLoading(true);
    try {
      const response = await apiHandler.post('/mfa/verify', {
        code,
      });
      setToastMessage('MFA code verified successfully.');
      setShowToast(true);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : 'Unknown error occurred';
      setToastMessage(`MFA code verification failed: ${errorMessage}`);
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegistrationSubmit = async () => {
    setIsLoading(true);
    try {
      await authStore.register({
        email,
        username,
        password,
      });

      await sendMFAEmail(null, email);
      setRegistrationSubmitted(true);
      setToastMessage(
        'Registration successful. Please check your email for verification.'
      );
      setShowToast(true);
      authStore.setUsername(username);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : 'Unknown error occurred';
      setToastMessage(`Registration failed: ${errorMessage}`);
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundImage:
          'radial-gradient(circle at center 200px, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0)), url(/pexels-jonathan-borba-2983101.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
      }}>
      <Container maxWidth='sm' style={{ marginTop: '10vh' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <img
            src='/FoodLoopLogo.png'
            alt='Food Loop Logo'
            style={{ width: '180px', marginBottom: '16px' }}
          />
        </div>

        <CardWrapper>
          <CardContent>
            {showForgotPasswordForm ? (
              <>
                <Typography variant='h5' align='center' gutterBottom>
                  Password Reset
                </Typography>
                <Typography variant='subtitle1' align='center' paragraph>
                  Enter your email and we'll send you a link to reset your
                  password.
                </Typography>
                <TextField
                  key='forgotten-email'
                  fullWidth
                  margin='normal'
                  label='Email'
                  variant='outlined'
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  placeholder='Enter the email linked to your account'
                />
                <Button
                  fullWidth
                  style={{ marginTop: '16px', borderRadius: '25px' }}
                  onClick={
                    resetPasswordFlow === 'forgotPassword'
                      ? handleForgotPassword
                      : handleResetPassword
                  }
                  variant='contained'
                  color='primary'
                  disabled={isLoading}>
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : resetPasswordFlow === 'forgotPassword' ? (
                    'Send Reset Link'
                  ) : (
                    'Reset Password'
                  )}
                </Button>
              </>
            ) : (
              <>
                {activeTab === 0 && (
                  <>
                    <Typography variant='h5' align='center' gutterBottom>
                      Welcome back!
                    </Typography>
                    <Typography variant='subtitle1' align='center' paragraph>
                      Glad to see you again. Enter your credentials to continue
                      enjoying Food Loop.
                    </Typography>
                  </>
                )}
                {activeTab === 1 && (
                  <>
                    <Typography variant='h5' align='center' gutterBottom>
                      Welcome to Food Loop!
                    </Typography>
                    <Typography variant='subtitle1' align='center' paragraph>
                      Join us to discover the best recipes and food stories.
                      Let's create your account.
                    </Typography>
                  </>
                )}
                <Tabs
                  value={activeTab}
                  onChange={(e, newValue) => setActiveTab(newValue)}
                  variant='fullWidth'>
                  <Tab label='Login' />
                  <Tab label='Register' />
                </Tabs>

                {activeTab === 0 && (
                  <>
                    <LoginForm
                      onLogin={handleLogin}
                      isLoading={isLoading}
                      username={username}
                      setUsername={setUsername}
                      password={password}
                      setPassword={setPassword}
                    />
                    <Button
                      color='primary'
                      size='small'
                      style={{ marginTop: '8px' }}
                      onClick={() => setShowForgotPasswordForm(true)}>
                      Forgot Password?
                    </Button>
                  </>
                )}
                {activeTab === 1 && (
                  <>
                    {renderStepContent()}{' '}
                    {step > 1 && (
                      <Button
                        onClick={handleBack}
                        style={{
                          display: 'block',
                          width: '100%',
                          marginTop: '2rem',
                        }}>
                        Back
                      </Button>
                    )}{' '}
                  </>
                )}
              </>
            )}
          </CardContent>
        </CardWrapper>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          action={
            <Button color='secondary' size='small' onClick={handleSnackbarClose}>
              Close
            </Button>
          }
        />
      </Container>
    </div>
  );
}

export default AuthPage;
