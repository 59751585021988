import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  TextField,
  Box,
  InputAdornment,
  ThemeProvider,
  createTheme,
  useTheme,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import ScheduleIcon from "@mui/icons-material/Schedule";
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import deliveryAddressStore from "../stores/DeliveryAddressStore";


const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ffffff",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          color: "#000",
          height: "48px",
          borderRadius: "0px",
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
          "&.Mui-focused": {
            backgroundColor: "#FFFFFF",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          color: "#000",
          height: "48px",
          borderRadius: "0px",
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          "&.Mui-focused": {
            borderColor: "#000",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#000"
            }
          }
        },
        notchedOutline: {
          borderColor: "transparent"
        },
        hover: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
          }
        }
      }
    }
  },
});

function DeliveryInput() {
  const theme = useTheme();
  const [selectedTime, setSelectedTime] = useState(null);
  const [isTimePickerFocused, setTimePickerFocused] = useState(false);
  const [addressQuery, setAddressQuery] = useState('');
  const [addressOptions, setAddressOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [restaurants, setRestaurants] = useState([]);
  const [error, setError] = useState(null);
  const [selectedAutocompleteValue, setSelectedAutocompleteValue] = useState('');
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(deliveryAddressStore.selectedAddress);


  useEffect(() => {
    if (addressQuery && addressQuery.length >= 3) {
      const fetchAddresses = async () => {
        const mapboxUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(addressQuery)}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
        const response = await fetch(mapboxUrl);
        const data = await response.json();
        if (data.features) {
          setAddressOptions(data.features.map((feat) => feat.place_name));
        }
      };
      fetchAddresses();
    }
  }, [addressQuery]);
  const navigate = useNavigate();

  const handleFindFood = async () => {
    try {
      const encodedAddress = encodeURIComponent(selectedAddress);
      const mapboxUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedAddress}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
      const mapboxResponse = await fetch(mapboxUrl);
      const mapboxData = await mapboxResponse.json();

      if (!mapboxData.features || mapboxData.features.length === 0) {
        throw new Error('Could not determine the city from the address');
      }

      const addressFeature = mapboxData.features.find((feat) => feat.id.includes('address'));
      const [street, city, statePostalCode] = addressFeature.place_name.split(', ');
      
      const postalCode = addressFeature.context.find((context) => context.id.includes('postcode')).text;
      const state = addressFeature.context.find((context) => context.id.includes('region')).text;

      const address = {
        street,
        city,
        state,
        postalCode
      }

      const deliveryAddress = {
        address,
        instructions: '',
        name: '',
        type: ''
      }

      deliveryAddressStore.setSelectedAddress(deliveryAddress);

      navigate('/order');

    } catch (error) {
      console.error('Error fetching restaurants:', error);
      setSnackbarMessage(error.message || 'Error fetching restaurants.');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ThemeProvider theme={darkTheme}>
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'left',
          alignItems: 'left',
          paddingTop: '10px', 
        }}
      >
        <Autocomplete
          value={selectedAutocompleteValue}
          onChange={(event, newValue) => {
            setSelectedAutocompleteValue(newValue);
            setSelectedAddress(newValue);
          }}
          style={{ width: "600px", maxWidth: "60%" }}
          onInputChange={(event, newInputValue) => {
            setAddressQuery(newInputValue);
          }}
          options={addressOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start" style={{ paddingLeft: '10px' }}>
                    <PlaceOutlinedIcon
                      style={{
                        color: 'black',
                        display: 'flex',
                        marginTop: '-8px',
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Box
          style={{
            display: 'flex',
            alignItems: 'left',
            transition: 'width 0.3s ease',
            marginTop: isMobile ? '10px' : '0',
            shadows: 'none',
          }}
        >
          {selectedTime || isTimePickerFocused ? (
          <TimePicker
              value={selectedTime}
              onChange={(time) => {
                console.log("Selected Time (TimePicker):", time);
                setSelectedTime(time);
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  variant="outlined"
                  onFocus={() => setTimePickerFocused(true)}
                  onBlur={() => setTimePickerFocused(false)}
                />
              )}
            />
          ) : (
            <Button variant="contained">
              <ScheduleIcon
                style={{ color: 'black', cursor: 'pointer', boxShadow: 'none' }}
                onClick={() => setTimePickerFocused(true)}
              />
            </Button>
          )}
        <Button variant="contained" onClick={handleFindFood} style={{ boxShadow: 'none' }}>
          find food
        </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleSnackbarClose}
            severity="error"
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </div>
    </ThemeProvider>
  );
}

export default DeliveryInput;