import React from 'react';
import { withRouter } from '../withRouter';
import InfoPage from '../templates/InfoPage';

function AboutUsPage() {
  return (
    <InfoPage
      pageTitle="About Food Loop Delivery"
      subtitle="Bringing Delicious Meals to Your Doorstep"
      sections={[
        {
          backgroundColor: "#FFFFFF",
          heading: "Why Choose Food Loop?",
          text: "At Food Loop Delivery, we're passionate about providing you with the best dining experience at the comfort of your home. Enjoy the flexibility to order your favorite meals at any time, along with competitive prices and a wide range of cuisines.",
          imageSrc: "/paolo-feser-1MFqJzNUwOM-unsplash.jpg",
          altText: "Food Image 1"
        },
        {
          backgroundColor: "#F9F9FF",
          heading: "Quality and Convenience",
          text: "We're dedicated to delivering not only delicious food but also ensuring it arrives fresh and satisfying. Our team of experienced drivers ensures timely and safe delivery, so you can enjoy your meals hot and ready to devour.",
          imageSrc: "/szymon-fischer-9Cwg1ym3SCs-unsplash.jpg",
          altText: "Food Image 2"
        },
        {
          backgroundColor: "#F3F4FF",
          heading: "Rewards and Perks",
          text: "As a part of Food Loop Delivery, you're not just a customer – you're a valued member of our community. Earn rewards with every order, enjoy exclusive discounts on your favorite eateries, and make the most of your dining experiences with us.",
          imageSrc: "/loes-klinker-vLQY3beH2h0-unsplash.jpg",
          altText: "Food Image 3"
        },
        {
          backgroundColor: "#E7E9FB",
          heading: "Join Our Team",
          text: "Are you passionate about food and customer service? Join our delivery team and become a crucial part of Food Loop's success story. Enjoy flexible working hours, competitive pay, and the satisfaction of bringing smiles to hungry customers.",
          imageSrc: "/shri-Q4CX55VsBP0-unsplash.jpg",
          altText: "Food Image 4",
          button: {
            text: "Become a Driver"
          }
        }
      ]}
    />
  );
}

export default withRouter(AboutUsPage);