import React from "react";
import Button from "@mui/material/Button";
import {
  List,
  ListItem,
  Typography,
  Box,
  Grid,
  ButtonBase
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomCard from '../DiscoverCards';
import { useNavigate } from 'react-router-dom';

const styles = {

    root: {
      width: "100%",
      display: 'flex',
      alignItems: 'center',
      background: '#FEFAF0',
      padding: '20px'
    },
    imageContainer: {
      position: 'relative',
      width: '30vw',
      height: '30vw',
      marginRight: '5vw',
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: '100%',
      borderRadius: '100px',
      objectFit: 'cover',
    },
    gradientOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '100px',
      width: '100%',
      height: '100%',
      background: 'radial-gradient(circle at 50% 50%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%)',
    },
    content: {
      flex: 1,
    },
    header: {
      fontWeight: 'bold',
      fontSize: {
        xs: '30px',
        sm: '40px',
        md: '50px',
        lg: '60px',
      },
    },
    subheader: {
      marginTop: '16px',
      marginBottom: '32px',
      fontSize: {
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
      },
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px',
    },
    listButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    checkIcon: {
      marginRight: '8px',
      color: '#FF4848',
    },
  };

  function DiscoverSection({ title, subtitle, listItems, buttonText1, handleButton1, handleButton2 }) {
    
    return (
      <Box sx={{ backgroundColor: '#FEFAF0', minHeight: '100vh', paddingLeft: { xs: '10px', md: '50px' }, }}>
        <Box
          sx={{
            ...styles.root,
            overflow: 'hidden',
            minHeight: '80vh',
            maxWidth: '90%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box style={styles.imageContainer}>
            <img src="/discoverImage.png" alt="FoodLoop" style={styles.image} />
            <Box style={styles.gradientOverlay}/>
          </Box>
          <Box sx={{ ...styles.content, paddingRight: { xs: 0, md: '5vw' }, width: { xs: '100%', md: '50%' } }}>
            <Typography variant="h1" sx={styles.header}>
              {title}
            </Typography>
            <Typography variant="body1" sx={styles.subheader}>
              {subtitle}
            </Typography>
            <List>
              {listItems.map((item, index) => (
                <ListItem key={index} sx={styles.listItem}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <Typography variant="body1" sx={{ color: '#FF4848', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        <CheckCircleIcon sx={{ ...styles.checkIcon, color: '#FF4848' }} />
                        {item.title}
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#808080' }}>
                        {item.description}
                      </Typography>
                    </div>
                  </div>
                </ListItem>
              ))}
              <ListItem sx={styles.listButtons}>
                <Button
                  color="inherit"
                  variant="outlined"
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    width: { xl: '75%', md: '40%', sm: '25%', xs: '25%' },
                    borderRadius: '8px',
                    borderWidth: '1px',
                  }}
                  onClick={handleButton1}
                >
                  {buttonText1}
                </Button>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <ButtonBase
                    sx={{
                      width: { xl: '100%', md: '50%'},
                      marginLeft: { xs: 0, md: '10px' },
                      '& img': {
                        width: '100%',
                      },
                    }}
                    onClick={handleButton1}
                  >
                    <img src="/playstore-black.svg" alt="Play Store" />
                  </ButtonBase>
                  <ButtonBase
                    sx={{
                      width: { xl: '100%', md: '50%'},
                      marginLeft: { xs: 0, md: '10px' },
                      '& img': {
                        width: '100%',
                      },
                    }}
                    onClick={handleButton2}
                  >
                    <img src="/appStore-black.svg" alt="App Store" />
                  </ButtonBase>
                </Box>
              </ListItem>
            </List>
          </Box>
        </Box>
        <ThreeCardsRow />
      </Box>
    );
  }

  const ThreeCardsRow = () => {
    const navigate = useNavigate();

    const cardData = [
      {
        iconImage: '/redStar.svg',
        mainImage: '/girlChair.svg',
        header: 'Download The App',
        body: 'Discover the unique benefits of FoodLoop and get the best experience possible.',
        navigate: ''
      },
      {
        iconImage: '/yellowSemicircle.svg',
        mainImage: '/deliveryDriver.svg',
        header: 'Become A Driver',
        body: 'FoodLoop drivers earn up to 30% more income than other food delivery services. No hidden fees, no corners cut. ',
        navigate: '/drivers'
      },
      {
        iconImage: '/blueStar.svg',
        mainImage: '/shakingHands.svg',
        header: 'Become A Partner',
        body: 'Partner with FoodLoop and skyrocket your profits, access more customers, and streamline your operations.',
        navigate: '/restaurants'
      },
    ];
  
    return (
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: { xs: 'center', lg: 'space-between' },
          padding: { xs: '0vh 10px 10vh 10px', md: '0vh 26px 10vh 26px' },
        }}
      >
        {cardData.map((card, index) => (
          <Grid item xs={12} lg={4} key={index}>
            <CustomCard
              iconImage={card.iconImage}
              mainImage={card.mainImage}
              header={card.header}
              body={card.body}
              handleClick={() => {
                if (card.navigate) {
                  navigate(card.navigate)
                }
              }}
            />
          </Grid>
        ))}
      </Grid>

    );
  };

  export default DiscoverSection;