import React, { useEffect } from "react";
import DeliveryInput from "../DeliveryInput";
import { Typography, Box, Button, useMediaQuery, useTheme } from "@mui/material";
import DeliveryLocationComponent from "../DeliveryLocation";
import authStore from "../../stores/AuthStore";

const userAddresses = [
  { type: 'home', location: '123 Main St.' },
  { type: 'work', location: '456 Work Ave.' },
];
const styles = {
  centeredImage: {
    backgroundImage: 'radial-gradient(circle at 10% 40%, rgba(0,0,0,.4) 0%, rgba(0,0,0,0.02) 100%), url("/mainBackground.png")',
    backgroundBlendMode: 'multiply',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minHeight: '80vh',
    color: 'white',
    padding: '20px',
  },
};

function LandingPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const isLoggedIn = authStore.isAuthenticated;

  return (
      <Box sx={{ ...styles.centeredImage, display: 'flex', flexDirection: 'row', position: 'relative' }}>
        <Box
          sx={{
            position: 'relative',
            zIndex: 2,
            paddingTop: { xs: '50px', md: '100px' },
            paddingLeft: { xs: '10px', md: '50px' },
            paddingBottom: { xs: '10vh', md: '15vh' },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              alignItems: 'start',
              fontSize: { xs: '3rem', sm: '4rem' },
              marginTop: { xs: '5vh', md: '10vh' },
              marginBottom: { xs: '10vh' },
              marginRight: { xs: '10px', md: '50px' },
              position: 'relative',
              color: 'white',
              display: 'block',
              lineHeight: '1.2',
              mixBlendMode: 'color-dodge',
            }}
          >
            Fresh Flavors,<br />Fresher Deals.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              marginRight: { xs: '10px', md: '50px' },
              marginBottom: { xs: '10vh' },
              width: '65%',
              alignItems: 'start',
              color: 'white',
              mixBlendMode: 'color-dodge',
              paddingBottom: { xs: '40px', md: '80px' },
              fontSize: {
                xs: '12px',
                sm: '16px',
              },
            }}
          >
            Dine smarter with FoodLoop. Quality food from local restaurants delivered right to your doorstep at unbeatable prices.
          </Typography>
          <DeliveryInput />
          <Box sx={{paddingTop: { xs: '10px' }, color: 'white' }}>
            <DeliveryLocationComponent isLoggedIn={isLoggedIn} addresses={userAddresses} />
          </Box>
        </Box>
        {!isMobile && (
          <Box sx={{ zIndex: 0, position: 'absolute', bottom: 0, right: 0 }}>
             <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5))',
                zIndex: 3,
              }}
            ></Box>
            <img
              src="/phoneImage2.png"
              style={{
                width: '30vw',
                position: 'absolute',
                bottom: 0,
                right: 0,
                filter: 'brightness(.8)',
              }}
              alt="Phone Image 2"
            />
            <img
              src="/phoneImage1.png"
              style={{
                width: '30vw',
                position: 'absolute',
                bottom: 0,
                right: '280px',
                filter: 'brightness(.7)',
              }}
              alt="Phone Image 1"
            />
          </Box>
        )}
      </Box>
  );
}

export default LandingPage;
