import React from "react";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
  ButtonBase,
} from "@mui/material";
import FastFoodIcon from "@mui/icons-material/Fastfood";

const FavoritesCard = ({store}) => {
    const handleClick = () => {
        console.log(`Clicked on ${store.name}`);
      };
    return (
      <ButtonBase
      onClick={handleClick}
      sx={{
        width: "300px",
        textDecoration: "none",
        borderRadius: '18px'
      }}>
      <Card
        elevation={0}
        sx= {{borderRadius: '18px', backgroundColor: '#FF4848', width: '300px'}}
      >
        <CardMedia
          component="img"
          height="180"
          image={store.image}
          alt={store.name}
          style={{ width: "100%" }}
        />
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" style={{ color: 'white', fontWeight: 'bold' }}>{store.name}</Typography>
            <FastFoodIcon sx={{ color: "white" }} />
          </div>
          <Typography variant="body2" style={{ color: 'white' }}>{store.time}</Typography>
        </CardContent>
      </Card>
    </ButtonBase>
    );
};

export default FavoritesCard