import React from "react";
import {
  Typography,
  Box,
} from "@mui/material";

import CustomCard2 from "../BenefitsCards";

const styles = {
  header: {
    fontWeight: 'bold',
    fontSize: {
      xs: '30px',
      sm: '40px',
      md: '50px',
      lg: '60px',
    },
  },
  };

  const ProfitsSection = ({ titleText, subtitleText, cardData }) => {
    return (
      <div style={{ backgroundColor: '#FEFAF0', minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <div style={{
          alignItems: 'center',
          textAlign: 'center',
        }}>
          <div>
            <Typography variant="h5" style={{ fontWeight: 'lighter', color: '#FF4848', letterSpacing: 3, paddingBottom: '2vh', paddingTop: '5vh' }}>
              {titleText || "FoodLoop"}
            </Typography>
            <Typography variant="h1" sx={{ ...styles.header, paddingBottom: '10vh' }}>
              {subtitleText || "Unlock Unprecedented Profits"}
            </Typography>
          </div>
        </div>
        {cardData ? <ThreeCardsRow cardData={cardData} /> : null}
      </div>
    );
  }  
  
  export default ProfitsSection;

  const ThreeCardsRow = ({cardData}) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          justifyContent: 'space-between',
          padding: { xs: '0vh 10px 10vh 10px', md: '0vh 50px 10vh 50px' },
        }}
      >
        {cardData.map((card, index) => (
            <CustomCard2
              key={index}
              iconImage={card.iconImage}
              header={card.header}
              body={card.body}
            />
        ))}
      </Box>
    );
  };