import React from "react";
import Button from "@mui/material/Button";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    background: '#FEFAF0',
  },
  imageContainer: {
    position: 'relative',
    width: '30vw',
    height: '30vw', // Ensure it's a square
    overflow: 'hidden', // Ensure the image is cropped
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '50px',
    objectFit: 'cover', // Maintain aspect ratio
  },
  gradientOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '50px',
    width: '100%',
    height: '100%',
    background: 'radial-gradient(circle at 50% 50%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%)',
  },
  content: {
    flex: 1,
  },
  header: {
    fontWeight: 'bold',
    fontSize: {
      xs: '30px',
      sm: '30px',
      md: '40px',
      lg: '50px',
    },
  },
  header2: {
    fontWeight: 'bold',
    fontSize: {
      xs: '18px',
      sm: '20px',
      md: '30px',
      lg: '40px',
    },
  },
  subheader: {
    marginTop: '16px', // Assuming theme.spacing(2) corresponds to 16px
    marginBottom: '32px', // Assuming theme.spacing(4) corresponds to 32px
    minWidth: '300px',
    maxWidth: '70vw',
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '16px',
      lg: '18px',
    },
  },
  subheader2: {
    marginTop: '16px', // Assuming theme.spacing(2) corresponds to 16px
    marginBottom: '32px',
    fontSize: '18px', // Default font size for larger screens (lg)
    '@media (max-width: 1200px)': {
      fontSize: '16px', // Adjust for medium screens (md)
    },
    '@media (max-width: 992px)': {
      fontSize: '14px', // Adjust for small screens (sm)
    },
    '@media (max-width: 768px)': {
      fontSize: '12px', // Adjust for extra-small screens (xs)
      maxWidth: '200px',
    },
  },
};

  const ProfitabilitySection = ({backgroundColor, bodyColor, image, mainLine, mainLineColor, headerText, subheaderText, profitIncreaseText, controlMenuText, subText1, subText2, buttonText, headerColor, handleClick, showGradient  }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed
    return (
      <div>
        <Box sx={{
          ...styles.root,
          background: backgroundColor,
          minHeight: '60vh',
          paddingLeft: {xs: '30px', md: '70px'},
        }}>
          <div style={{ ...styles.content, maxWidth: '60vw'}}>
            <Typography variant="h5" style={{ fontWeight: 200, color: 'mainLineColor', letterSpacing: 2, paddingBottom: '2vh' }}>
              {mainLine}
            </Typography>
            <Typography variant="h1" sx={{ ...styles.header, color: headerColor }}>
              {headerText}
            </Typography>
            <Typography variant="body1" sx={{...styles.subheader, color: bodyColor}}>
              {subheaderText}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h1" sx={{...styles.header2, color: mainLineColor, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                      {profitIncreaseText}
                    </Typography>
                    <Typography variant="body1" sx={{...styles.subheader2, color: bodyColor }}>
                      {subText1}
                    </Typography>
                    <Button
                      color='inherit'
                      variant='outlined'
                      style={{
                        backgroundColor: '#333',
                        color: 'white',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        height: '40px',
                        minWidth: '8vw',
                        borderRadius: '8px',
                        borderWidth: '1px',
                        marginTop: '50px',
                        padding: '0 10px 0 10px'
                      }}
                      onClick={handleClick || (() => {
                        // Handle sign-up button click if needed
                        console.log("Coming soon...");
                      })}
                    >
                      {buttonText  || "Sign Up"}
                    </Button>
                  </div>
                </div>
              </Grid>
  
              <Grid item xs={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <Typography variant="h1" sx={{...styles.header2, color: mainLineColor, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                      {controlMenuText}
                    </Typography>
                    <Typography variant="body1" sx={{...styles.subheader2, color: bodyColor }}>
                      {subText2}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          {!isMobile && (
          <Box style={styles.imageContainer}>
          <img src={image} alt="FoodLoop" style={styles.image} />
          {!showGradient && <Box style={styles.gradientOverlay} />}
        </Box>
          )}
        </Box>
      </div>
    );
  }
  
  export default ProfitabilitySection;