import React, { useState, useEffect } from 'react';
import { 
    Box, Typography, useTheme, useMediaQuery, Button, IconButton, 
    Fade, Tooltip, Card, CardActionArea, CardContent, CardMedia
} from '@mui/material';
import { scroller, animateScroll } from 'react-scroll';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack, ArrowForward, ArrowUpward } from '@mui/icons-material';
import articles from '../Articles';

const NewsPage = () => {
    const { article: selectedSlug } = useParams();
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('md'));

    const handleScrollTo = id => {
        setTimeout(() => {
            scroller.scrollTo(id, {
                duration: 1000,
                delay: 0,
                smooth: true,
                offset: -100,
            });
        }, 100);
    };

    useEffect(() => {
        if (selectedSlug && (sm || window.innerHeight < 900)) {
            handleScrollTo(selectedSlug);
        }
    }, [selectedSlug, sm]);

    useEffect(() => {
        const checkScrollPosition = () => {
            setShowScrollToTop(window.scrollY <= -100);
        };

        window.addEventListener('scroll', checkScrollPosition);

        return () => {
            window.removeEventListener('scroll', checkScrollPosition);
        };
    }, []);

    const nextArticle = step => {
        const currentArticleIndex = articles.findIndex(article => article.slug === selectedSlug);
        const nextIndex = (currentArticleIndex + articles.length + step) % articles.length;
        const nextSlug = articles[nextIndex].slug;

        navigate(`/news/${nextSlug}`);
        handleScrollTo(nextSlug);
    };

    const selectedArticle = articles.find(article => article.slug === selectedSlug);

    return (
        <Box sx={{ maxWidth: 1200, m: '0 auto', mt: { xs: 7, sm: 8 }, p: { xs: 2, sm: 8 } }}>
            <Typography variant={'h3'} sx={{ fontWeight: 700 }}>
                Check out what's new in our world
            </Typography>

            {selectedArticle ? (
                <Box id={selectedSlug}>
                    <Typography variant="h4">{selectedArticle.title}</Typography>
                    <Typography variant="subtitle1">By {selectedArticle.author} on {new Date(selectedArticle.date).toLocaleDateString()}</Typography>
                    <Typography variant="body1">{selectedArticle.summary}</Typography>
                    {selectedArticle.Component && <selectedArticle.Component />}
                </Box>
            ) : (
                articles.map(article => (
                    <Card 
                        key={article.slug}
                        sx={{ mb: 3 }}
                        onClick={() => navigate(`/news/${article.slug}`)}
                    >
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={article.image}
                                alt={article.title}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {article.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {article.summary}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))
            )}

            {selectedSlug && (
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                    <Button startIcon={<ArrowBack />} onClick={() => nextArticle(-1)}>
                        Previous Article
                    </Button>
                    <Button endIcon={<ArrowForward />} onClick={() => nextArticle(1)}>
                        Next Article
                    </Button>
                </Box>
            )}

            <Fade in={showScrollToTop}>
                <Tooltip title={'Back to top'}>
                    <IconButton
                        onClick={() => {
                            animateScroll.scrollToTop({
                                duration: 1000,
                                delay: 0,
                                smooth: true,
                            });
                        }}
                        sx={{ position: 'fixed', right: 16, bottom: 16 }}
                    >
                        <ArrowUpward />
                    </IconButton>
                </Tooltip>
            </Fade>
        </Box>
    );
};

export default NewsPage;
