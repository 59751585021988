import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Navbar from "../components/Navbar";
import HighlightedBox from "../components/page-sections/HighlightedBox";
import ReviewsSection from "../components/page-sections/ReviewSection";
import EmpowerSection from "../components/page-sections/EmpowerSection";
import ElevateSection from "../components/page-sections/ElevateSection";
import TransparencySection from "../components/page-sections/TransparencySection";
import DiscoverSection from "../components/page-sections/DiscoverSection";
import FavoritesSection from "../components/page-sections/FavoritesSection";
import LandingPage from "../components/page-sections/LandingPage";
import { useNavigate } from 'react-router-dom';

const styles = {
  section: {
    marginTop: 50,
    marginBottom: 50,
    paddingTop: 30,
    paddingBottom: 30,
  },
};

export default function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();

  const downloadApp = () => {
    console.log("Coming soon...");
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const navToRestaurant = () => {
    navigate('/restaurants');
  };

  const navToBusiness = () => {
    navigate('/business');
  };

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Navbar />
      <LandingPage />
      <div style={styles.section}>
        <FavoritesSection />
      </div>
      <DiscoverSection
        title="Discover How FoodLoop Saves You Money"
        subtitle="At FoodLoop, we believe in delivering more than just delicious meals. We're committed to helping you save money all while enjoying a seamless ordering experience & giving back to the local community."
        listItems={[
          { title: "Save:", description: "Enjoy exclusive savings on your favorite dishes from local restaurants, right at your fingertips." },
          { title: "Earn:", description: "Drivers & restaurants earn an average of 30% more income when compared to the leading competitors." },
        ]}
        buttonText1="Order"
        buttonText2="Download"
        handleButton1={scrollToTop}
        handleButton2={downloadApp}
      />

      <TransparencySection
        mainTitle="Better Earnings Through True Transparency."
        subTitle="Our innovative approach allows drivers to take control of their financial stability and enjoy unlimited access to delivery orders. No more inconsistent payments, hidden fees, & sliding scales."
        contentSections={[
          {
            title: "Better Earnings",
            text: "Averaging the highest revenue per mile*, our drivers earn an average of 30% more income than our leading competitors."
          },
          {
            title: "Honesty in Exchange",
            text: "Say goodbye to hidden fees and unpredictable earnings. With FoodLoop, what you see is what you get."
          }
        ]}
        buttonText="Sign Up"
        buttonClickHandler={navToBusiness}
        learnMoreHandler={navToRestaurant}
        backgroundImage="/scooterImage.png"
      />
      <ElevateSection
        title="Elevate Your Business With FoodLoop."
        subtitle="FoodLoop stands as a testament to what's possible when innovation meets understanding. We've crafted a realm where restaurants don't just survive, but flourish."
        items={[
          { title: "Subscription Model:", description: "Increased profitability through our innovative subscription model." },
          { title: "Unlimited Orders:", description: "Streamlined operations with unlimited orders and no per-order fees." },
          { title: "Total Control:", description: "Maximize profits with complete control over pricing and margins." },
        ]}
        buttonText="Learn More"
        learnMoreText="Sign Up"
        handleButton={navToBusiness}
        handleLearnMore={navToRestaurant}
      />
      <EmpowerSection
        title="Empower Austin Through Local Engagement"
        subtitle="FoodLoop is committed to creating positive change in Austin. We strive to provide higher paying jobs for drivers, help local restaurants keep their profits, and support local families in need. By partnering with local charity Intelihelp, each order made through FoodLoop helps deliver a meal or supplies to a family in need."
        buttonText="Learn More"
        handleClick={navToBusiness}
      />
      <ReviewsSection
        sectionTitle="The FoodLoop Effect"
        subtitle="FoodLoop has exceeded my expectations. The service is fantastic!"
      />
      <div style={styles.section}>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginBottom: 100 }}>
          <HighlightedBox
            header="Stay Updated with FoodLoop News"
            subtitle="Subscribe to our newsletter for updates and offers."
            inputLabel="Your Email"
            buttonText="Subscribe"
            headerColor="#333"
            subtitleColor="#333"
          />
        </Box>
      </div>
    </div>
  );
}
