import React from "react";
import { Box, Typography, Divider, Button, useMediaQuery, useTheme, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckList from "../CheckList";

export default function PricingSection({
  superHeader,
  transparencyHeader,
  transparencyText,
  price,
  elitePlanTexts,
  indexedTextEntries,
  listEntries,
  handleClick,
  handleLearnMore
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        background: 'linear-gradient(to top right, #FF4848, #D52826)',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        minHeight: '80vh',
        padding: { xs: '20px', md: '50px' }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flex: 1,
          pr: { xs: 0, md: '30px' },
          textAlign: 'left'
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 200, color: 'white', letterSpacing: 2, pb:'7vh' }}>
          {superHeader}
        </Typography>
        <Typography variant="h1" fontWeight="bold" color="white" gutterBottom sx={{ fontSize: { xs: '30px', md: '50px' } }}>
          {transparencyHeader}
        </Typography>
        <Typography variant="body1" color="white" sx={{ width: { xs: '100%', md: '80%' }, pt: '15px', pb: '10vh', fontSize: { xs: '14px', md: '16px' } }}>
          {transparencyText}
        </Typography>
        <CheckList listEntries={listEntries} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flex: 1,
          pl: { xs: 0, md: '30px' },
          textAlign: 'left',
          mt: isMobile ? '30px' : '0'
        }}
      >
        {elitePlanTexts.map((text, index) => (
          <Typography key={index} variant="h1" fontWeight="bold" color="white" gutterBottom sx={{ fontSize: { xs: '30px', md: '50px' }, pt: index > 0 ? '15px' : '0' }}>
            {text}
          </Typography>
        ))}
        <Divider sx={{ borderColor: 'white', my: '15px' }} />
        <Typography variant="h1" fontWeight="bold" color="white" gutterBottom sx={{ fontSize: { xs: '30px', md: '50px' }, pt: '15px' }}>
          {price}
        </Typography>
        <Divider sx={{ borderColor: 'white', mt: '15px' }} />
        <List sx={{ pt: '30px', display: 'flex', flexWrap: 'wrap' }}>
          {indexedTextEntries.map((entry, index) => (
            <ListItem key={index} sx={{ color: 'white', width: { xs: '100%', md: '50%' } }}>
              <ListItemIcon>
                <ArrowForwardIcon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary={entry} />
            </ListItem>
          ))}
        </List>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', pt: '30px' }}>
          <Button
            color='inherit'
            variant='outlined'
            sx={{
              width: '150px',
              backgroundColor: 'transparent',
              color: 'white',
              fontWeight: 'medium',
              textTransform: 'uppercase',
              height: '50px',
              borderRadius: '8px',
              borderWidth: '1px',
              mx: '10px',
              mt: '30px'
            }}
            onClick={handleClick}
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
