import { makeAutoObservable, runInAction, toJS } from 'mobx';
import apiHandler from '../api/ApiHandler';

class DeliveryAddressStore {
    deliveryAddresses = [];
    selectedAddress = null;
    deliveryTime = null;
    isLoading = false;
    errorMessage = null;
    successMessage = null;
    statusCode = null;
    filters = {
        searchTerm: '',
        cuisine: '',
        rating: null,
    };
    location = null; // { latitude: number, longitude: number }
    
    constructor() {
        makeAutoObservable(this);
        this.initializeDeliveryAddressState();
    }

    async initializeDeliveryAddressState() {
        const selectedAddress = window.localStorage.getItem('selectedAddress');
        const deliveryTime = window.localStorage.getItem('deliveryTime');
        if (selectedAddress) {
            try {
                this.selectedAddress =JSON.parse(selectedAddress);
            } catch {
                // If there is an error parsing the address, remove it from local storage
                this.selectedAddress = null;
                window.localStorage.removeItem('selectedAddress');
            }
        }
        if (deliveryTime) {
            this.deliveryTime = deliveryTime;
        }
    }

    setDeliveryAddresses(deliveryAddresses) {
        this.deliveryAddresses = deliveryAddresses;
    }

    setDeliveryTime(deliveryTime) {
        this.deliveryTime = deliveryTime;
        window.localStorage.setItem('deliveryTime', deliveryTime);
    }

    setSelectedAddress(address) {
        this.selectedAddress = address;
        window.localStorage.setItem('selectedAddress', JSON.stringify(toJS(address)));
    }

    setFilter(key, value) {
        this.filters[key] = value;
    }

    async fetchDeliveryAddresses() {
        this.isLoading = true;
        this.errorMessage = null;
        this.successMessage = null;
        this.statusCode = null;

        try {
            const response = await apiHandler.get('deliveryAddress');

            runInAction(() => {
                if (response) {
                    this.deliveryAddresses = response;
                    this.successMessage = 'Delivery addresses fetched successfully';
                } else {
                    this.handleAPIError(response);
                }
            });
        } catch (error) {
            runInAction(() => {
                this.errorMessage = error.message || "Failed to fetch delivery addresses";
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

async addDeliveryAddress(newAddress) {
    this.isLoading = true;
    this.errorMessage = null;
    this.successMessage = null;
    this.statusCode = null;

    try {
      const response = await apiHandler.post('deliveryAddress', newAddress);

      runInAction(() => {
        if (response) {
          this.deliveryAddresses.push(response);
          this.successMessage = 'Delivery address added successfully';
        } else {
          this.handleAPIError(response);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.errorMessage = error.message || "Failed to add delivery address";
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  handleAPIError(response) {
    this.errorMessage = response.message || 'An error occurred';
    this.statusCode = response.statusCode;
  }
}

const deliveryAddressStore = new DeliveryAddressStore();
export default deliveryAddressStore;