import { makeAutoObservable } from 'mobx';
import apiHandler from '../api/ApiHandler';
import authStore from './AuthStore';
import { US_STATES, getAllStateAbbreviations, getStateAbbreviation, getStateName } from '../utils/States';

class RegisterDriverStore {
  firstName = '';
  middleName = '';
  lastName = '';
  email = '';
  phone = '';
  dob = '';
  driverLicenseState = '';
  driverLicenseNumber = '';
  zipcode = '';
  vehicleType = 'Sedan';
  address = {
    street: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
  }

  errorMessage = '';
  successMessage = '';
  hasCompletedOnboarding = false;

  constructor() {
    makeAutoObservable(this);
  }

  setFirstName(firstName) {
    this.firstName = firstName.trim();
  }

  setMiddleName(middleName) {
    this.middleName = middleName.trim();
  }

  setLastName(lastName) {
    this.lastName = lastName.trim();
  }

  setEmail(email) {
    this.email = email.trim();
  }

  setPhone(phone) {
    this.phone = phone;
  }

  setDOB(date) {
    this.dob = date;
  }

  setDriverLicenseState(stateNameOrAbbreviation) {
    const abbreviationFromName = getStateAbbreviation(stateNameOrAbbreviation);
    const isAbbreviationValid = getAllStateAbbreviations().includes(stateNameOrAbbreviation);

    if (abbreviationFromName) {
      this.driverLicenseState = abbreviationFromName;
    } else if (isAbbreviationValid) {
      this.driverLicenseState = stateNameOrAbbreviation;
    } else {
      this.errorMessage = "Invalid state provided.";
    }
  }

  setDriverLicenseNumber(driverLicenseNumber) {
    this.driverLicenseNumber = driverLicenseNumber.trim();
  }

  setZipcode(zipcode) {
    this.zipcode = zipcode.trim();
  }

  setVehicleType(vehicleType) {
    this.vehicleType = vehicleType.trim();
  }

  setAddress(address) {
    this.address = address;
  }

  async updateUserAddress() {
    console.log(this.address);
    return await apiHandler.put(`users/${authStore.userId}`, { address: this.address });
  }

  async register() {
    const fullName = `${this.firstName} ${this.middleName ? this.middleName + ' ' : ''}${this.lastName}`.trim();
    const driverLicenseNumber = `${this.driverLicenseState} ${this.driverLicenseNumber}`;

    const driverData = {
      fullName: fullName,
      email: this.email,
      phoneNumber: this.phone,
      dateOfBirth: this.dob,
      vehicleType: this.vehicleType,
      drivingLicenseNumber: driverLicenseNumber,
      userId: authStore.userId
    };

    try {
      const response = await apiHandler.post('register/driver', driverData);

      if (response.message && /Driver creation and background check initiated successfully/.test(response.message)) {
        this.hasCompletedOnboarding = true;
        this.successMessage = response.message;
      } else {
        this.errorMessage = response.message || "Unknown Error";
      }
      return response;
    } catch (error) {
      this.errorMessage = error.message || "Network request failed";
      throw error;
    }
  }
}

const registerDriverStore = new RegisterDriverStore();
export default registerDriverStore;
