import React, { useState } from 'react';
import { Button, TextField, Container, Grid } from '@mui/material';
import apiHandler from '../api/ApiHandler';
import { DatePicker } from '@mui/x-date-pickers';
import registerDriverStore from '../stores/RegisterStore';
import { observer } from 'mobx-react-lite';

const DriverRegistration = observer((onChange) => {

  const handleDateChange = (name, value) => {
    registerDriverStore.setDOB(value.toISOString());
  }

  return (
    <Container component="main" maxWidth="xs">
      <form >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              value={registerDriverStore.firstName}
              onChange={(e) => registerDriverStore.setFirstName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="middleName"
              label="Middle Name"
              name="middleName"
              value={registerDriverStore.middleName}
              onChange={(e) => registerDriverStore.setMiddleName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              value={registerDriverStore.lastName}
              onChange={(e) => registerDriverStore.setLastName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={registerDriverStore.email}
              onChange={(e) => registerDriverStore.setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              name="phoneNumber"
              value={registerDriverStore.phone}
              onChange={(e) => registerDriverStore.setPhone(e.target.value)}
            />
            <Grid item sx={{ mt: '16px' }}>
              <DatePicker
                label="Date of Birth"
                value={registerDriverStore.dob ? new Date(registerDriverStore.dob) : new Date()}
                onChange={(date) => handleDateChange("dob", date)}
                maxDate={new Date()}
                renderInput={(params) => <TextField {...params} sx={{
                  mt: 16,
                  mb: '8px',
                  width: '100%', 
                }} />}
              />
            </Grid>

          </Grid>
        </Grid>




        {/* ...Add more form fields similarly... */}
        {/* <Button type="submit" fullWidth variant="contained" color="primary">
          Register
        </Button> */}
      </form>
    </Container>
  );

});

const styles = {
  datePicker: {
    width: '100%',
    margin: '8px 0',
  }
}

export default DriverRegistration;