import React, { useState } from "react";
import { Button, TextField, Container, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { observer } from "mobx-react-lite";
import registerDriverStore from "../stores/RegisterStore";
import { US_STATES } from "../utils/States";

const RegisrationAddress = observer(({ onFormSubmit }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedAddress = {
            ...registerDriverStore.address,
            [name]: value
        }
        registerDriverStore.setAddress(updatedAddress)
    }
    return (
        <Container component="main" maxWidth="xs">
            <form>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="street"
                    label="Street"
                    name="street"
                    value={registerDriverStore.address.street}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="line2"
                    label="Address Line 2"
                    name="line2"
                    value={registerDriverStore.address.line2}
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="city"
                    label="City"
                    name="city"
                    value={registerDriverStore.address.city}
                    onChange={handleChange}
                />
                <FormControl fullWidth>
                    <InputLabel id="stateLabel" sx={{ mt: '16px' }}>State</InputLabel>
                    <Select
                        id="state"
                        label="State"
                        labelId="stateLabel"
                        name="state"
                        value={registerDriverStore.address.state}
                        sx={{ width: '100%', mt: '16px', mb: '8px' }}
                        onChange={handleChange}
                    >
                        {US_STATES.map((state) => (
                            <MenuItem key={state.abbreviation} value={state.abbreviation}>{state.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="zip"
                    label="Zip Code"
                    name="postalCode"
                    value={registerDriverStore.address.postalCode}
                    onChange={handleChange}
                />
            </form>
        </Container>
    );
});

export default RegisrationAddress;

