const articles = [
    {
        title: "Food Loop Celebrates its Millionth Delivery!",
        slug: "millionth-delivery",
        image: "/discoverImage.png",
        author: "Jane Doe",
        date: new Date("2023-07-10"),
        summary: "A major milestone achieved as Food Loop completes its millionth delivery. Dive into our journey towards making food delivery affordable for all.",
        Component: () => <div>Article details for millionth delivery...</div>
    },
    {
        title: "Why Flat Fee Model is the Future of Food Delivery",
        slug: "flat-fee-future",
        image: "/moneyPlate.png",
        author: "John Smith",
        date: new Date("2023-06-15"),
        summary: "Dive deep into how our unique flat fee model is revolutionizing the food delivery landscape.",
        Component: () => <div>Article details about the flat fee model...</div>
    },
    {
        title: "Empowering Drivers: The Backbone of Food Loop",
        slug: "empowering-drivers",
        image: "/deliveryDriver.svg",
        author: "Alice Green",
        date: new Date("2023-05-22"),
        summary: "We believe in a driver-centric approach. Learn about the benefits our drivers enjoy and how it's reshaping the delivery industry.",
        Component: () => <div>Article details about empowering drivers...</div>
    },
    {
        title: "Announcing Our Expansion to 10 New Cities!",
        slug: "new-city-expansion",
        image: "/sellingFood.svg",
        author: "Robert Brown",
        date: new Date("2023-05-05"),
        summary: "With our continued success, we're thrilled to bring Food Loop's revolutionary delivery platform to more cities.",
        Component: () => <div>Article details about city expansion...</div>
    },
    {
        title: "From Farm to Table: Keeping Food Fresh with Rapid Deliveries",
        slug: "keeping-food-fresh",
        image: "/tomatoes.png",
        author: "Lucy White",
        date: new Date("2023-04-18"),
        summary: "Delight in fresh food every time. Discover how Food Loop ensures rapid deliveries, keeping your orders fresh upon arrival.",
        Component: () => <div>Article details about fresh deliveries...</div>
    },
    {
        title: "Food Loop Partners with Local Eateries for Exclusive Deals",
        slug: "local-eatery-partnerships",
        image: "/pizza-hut.jpg",
        author: "Mike Blue",
        date: new Date("2023-03-30"),
        summary: "Enjoy exclusive deals from your favorite local eateries. Learn more about our exciting new partnerships.",
        Component: () => <div>Article details about new partnerships...</div>
    },
    {
        title: "Cutting Down Emissions with Food Loop's Eco-Friendly Fleet",
        slug: "eco-friendly-fleet",
        image: "/scooter-delivery.svg",
        author: "Linda Gray",
        date: new Date("2023-03-10"),
        summary: "As part of our commitment to a greener planet, Food Loop is introducing a more eco-friendly fleet. Dive into our sustainability initiatives.",
        Component: () => <div>Article details about our green initiatives...</div>
    }
];

export default articles;