import React, { useState } from 'react';
import {
  Drawer, List, ListItem, ListItemText, IconButton, Box, Toolbar, Grid, Typography, Button, Badge,
} from '@mui/material';
import { Close, RemoveCircleOutline, AddCircleOutline, ShoppingCart } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import cartStore from '../stores/CartStore';
import authStore from '../stores/AuthStore';
import deliveryAddressStore from '../stores/DeliveryAddressStore';
import orderStore from '../stores/OrderStore';
import apiHandler from '../api/ApiHandler';

const Cart = observer(({ iconColor = 'black' }) => {
  const [cartOpen, setCartOpen] = useState(false);
  const cart = cartStore.cartItems;
  const navigate = useNavigate();

  const changeQuantity = (item, quantity) => {
    if (quantity === 0) {
      cartStore.removeFromCart(item.product._id);
    } else {
      cartStore.updateQuantity(item.product._id, quantity, item.variations);
    }
  };

  const confirmOrder = async () => {
    if (authStore.isAuthenticated) {
      const restaurant = cartStore.cartItems[0]?.product.restaurant;

      if (restaurant && deliveryAddressStore.selectedAddress && cart.length > 0) {
        try {
          const user = await apiHandler.get(`users/${authStore.userId}`);

          const customer = {
            id: user._id,
            name: user.fullName,
            phone: user.phoneNumber,
          };

          const restaurantId = restaurant;
          const deliveryAddress = deliveryAddressStore.selectedAddress.address;

          const items = cart.map((item) => ({
            foodId: item.product._id,
            name: item.product.name,
            quantity: item.quantity,
            price: item.product.price,
            variations: item.variations,
            notes: item.notes,
          }));

          const totalAmount = cart.reduce((total, item) =>
            total + (item.product.price * item.quantity), 0).toFixed(2);

          const order = {
            restaurantLocationId: restaurantId,
            customer,
            deliveryAddress,
            items,
            totalAmount,
            deliveryFee: cartStore.deliveryFee,
            platformFee: cartStore.platformFee,
            discount: cartStore.discount,
            specialInstructions: cartStore.specialInstructions,
            status: "Pending",
          };

          await orderStore.createOrder(order);
          navigate('/payment');
        } catch (error) {
          console.error(error);
        }
      } else {
        let missingInfo = [];

        if (!restaurant) missingInfo.push("restaurant information");
        if (!deliveryAddressStore.selectedAddress) missingInfo.push("delivery address");
        if (cart.length === 0) missingInfo.push("cart items");

        console.error(`Missing information for order: ${missingInfo.join(", ")}`);

        missingInfo = null;
      }
    } else {
      const redirUrl = "/checkout";
      navigate(`/auth?type=signin&returnUrl=${redirUrl}`);
    }
  };

  const groupItemsByRestaurant = () => {
    const groupedItems = {};
    cart.forEach(item => {
      const restaurantId = item.product.restaurant;
      if (!groupedItems[restaurantId]) {
        groupedItems[restaurantId] = [];
      }
      groupedItems[restaurantId].push(item);
    });
    return groupedItems;
  };

  const groupedItems = groupItemsByRestaurant();

  return (
    <>
      <IconButton sx={{ width: '50px', height: '50px' }} onClick={() => setCartOpen(!cartOpen)}>
        <Badge badgeContent={cart.length} color="primary">
          <ShoppingCart sx={{ fontSize: '24px', color: iconColor }} />
        </Badge>
      </IconButton>
      <Drawer
        anchor="right"
        open={cartOpen}
        onClose={(_, reason) => reason === "backdropClick" && setCartOpen(false)}
      >
        <Box sx={{ width: 300, padding: 2 }}>
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Your Order</Typography>
              <IconButton onClick={() => setCartOpen(false)}>
                <Close />
              </IconButton>
            </Grid>
          </Toolbar>
          {cart.length > 0 ? (
            <List>
              {Object.keys(groupedItems).map((restaurantId, index) => (
                <React.Fragment key={index}>
                  <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    {groupedItems[restaurantId][0].product.restaurantName}
                  </Typography>
                  {groupedItems[restaurantId].map((item, idx) => (
                    <ListItem key={idx}>
                      <ListItemText
                        sx={{ width: '100px' }}
                        primary={item.product.name}
                        secondary={`$${item.product.price}`} />
                      <Grid container alignItems="center">
                        <IconButton onClick={() => changeQuantity(item, item.quantity - 1)}>
                          <RemoveCircleOutline />
                        </IconButton>
                        <ListItemText
                          primary={item.quantity}
                          sx={{ width: '28px', textAlign: 'center' }}
                        />
                        <IconButton onClick={() => changeQuantity(item, item.quantity + 1)}>
                          <AddCircleOutline />
                        </IconButton>
                        <ListItemText
                          sx={{ width: '20px', textAlign: 'end' }}
                          primary={`$${(item.product.price * item.quantity).toFixed(2)}`}
                        />
                      </Grid>
                    </ListItem>
                  ))}
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography justifyContent="center" textAlign="center" margin="16px">
              Select an item to get started!
            </Typography>
          )}
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            disabled={cart.length === 0}
            onClick={confirmOrder}
          >
            Continue to Payment
          </Button>
        </Box>
      </Drawer>
    </>
  );
});

export default Cart;
