import { makeAutoObservable } from 'mobx'
import apiHandler from '../api/ApiHandler'

class AuthStore {
  username = ''
  userId = null
  email = ''
  password = ''
  token = null
  isAuthenticated = false
  errorMessage = ''

  constructor() {
    makeAutoObservable(this)
    this.initializeAuthState()
    this.logout = this.logout.bind(this)
  }

  async initializeAuthState() {
    const storedToken = window.localStorage.getItem('token')
    const storedUserId = window.localStorage.getItem('userId')
    if (storedToken) {
      this.token = storedToken
      this.isAuthenticated = true
      this.userId = storedUserId
    }
  }

  setUsername(username) {
    this.username = username
  }

  setUserId(userId) {
    this.userId = userId
    window.localStorage.setItem('userId', userId)
  }

  setEmail(email) {
    this.email = email
  }

  setPassword(password) {
    this.password = password
  }

  setErrorMessage(message) {
    this.errorMessage = message
  }

  setIsAuthenticated(authStatus) {
    this.isAuthenticated = authStatus
    if (!authStatus) {
      this.token = null
      window.localStorage.removeItem('token')
    }
  }

  setToken(tokenValue) {
    this.token = tokenValue
    window.localStorage.setItem('token', tokenValue)
  }

  logout() {
    this.setUsername('')
    this.setPassword('')
    this.setEmail('')
    this.setToken(null)
    this.setUserId(null)
    this.setIsAuthenticated(false)
  }

  async resetPassword(newPassword, token) {
    return await apiHandler.post('auth/reset-password', {
      token: token,
      newPassword: newPassword,
    })
  }

  async forgotUsername() {
    return await apiHandler.post('auth/forgot-username', {
      email: this.email,
    })
  }

  async forgotPassword() {
    return await apiHandler.post('auth/forgot-password', {
      email: this.email,
    })
  }

  async signUp() {
    const data = await apiHandler.post('auth/signup', {
      username: this.username,
      email: this.email,
      password: this.password,
    })
    if (data && data.token) {
      this.setToken(data.token)
      this.setUserId(data.id)
      this.setIsAuthenticated(true)
    }
  }

  async login() {
    const data = await apiHandler.post('auth/signin', {
      username: this.username,
      password: this.password,
    })
    if (data && data.token) {
      this.setToken(data.token)
      this.setUserId(data.id)
      this.setIsAuthenticated(true)
    }
  }

  async sendSMSVerificationCode(phone, code) {
    return await apiHandler.post('auth/auth/mfa/sms', {
      phoneNumber: phone,
      code: code,
    })
  }

  async sendEmailVerificationCode(email, code) {
    return await apiHandler.post('auth/auth/mfa/email', {
      email: email,
      code: code,
    })
  }

  async verifyCode(code) {
    const response = await apiHandler.post('auth/auth/mfa/verify', {
      code: code,
    })
    return response.isValid;
  }

  async updateUserDetails(details) {
    return await apiHandler.put(`users/${this.userId}`, details)
  }
}

const authStore = new AuthStore();
export default authStore;
