import React from "react";
import {
  Container,
  Typography,
  Grid,
} from "@mui/material";
import ReviewCard from "../ReviewCard";

const reviews = [
  {
    id: 1,
    quote: "FoodLoop has a magical touch! The flavors are amazing.",
    name: "Alice",
    profileImage: "/heshan-perera-imbTFiEY9ls-unsplash.jpg", // Same image URL
    starReview: 5,
  },
  {
    id: 2,
    quote: "Absolutely loved the experience. The dishes were delightful!",
    name: "Bob",
    profileImage: "/heshan-perera-imbTFiEY9ls-unsplash.jpg", // Same image URL
    starReview: 4,
  },
  {
    id: 3,
    quote: "A wonderful place for food enthusiasts. Highly recommend!",
    name: "Charlie",
    profileImage: "/heshan-perera-imbTFiEY9ls-unsplash.jpg", // Same image URL
    starReview: 5,
  },
  {
    id: 4,
    quote: "Good food but the service could be improved.",
    name: "Diana",
    profileImage: "/heshan-perera-imbTFiEY9ls-unsplash.jpg", // Same image URL
    starReview: 3,
  },
  {
    id: 5,
    quote: "An amazing culinary journey. The desserts are to die for!",
    name: "Eve",
    profileImage: "/heshan-perera-imbTFiEY9ls-unsplash.jpg", // Same image URL
    starReview: 4,
  },
];


const styles = {
  reviewRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  reviewContainer: {
    shadows: 'none',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    padding: '50px',
  },
  scrollableContainer: {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
};


  const ReviewsSection = ({ sectionTitle, subtitle}) => {
    return (
      <div>
        <Container style={{
          alignItems: 'center',
          textAlign: 'center',
        }}>
          <Typography variant="h1" style={{ paddingTop: '10vh', fontSize: '60px', fontWeight: 'normal' }} gutterBottom>
            {sectionTitle || "The FoodLoop Effect"}
          </Typography>
          <Typography variant="subtitle1" style={{ fontSize: '12px', fontWeight: 'lighter', letterSpacing: 3 }} paragraph>
            {subtitle || "FoodLoop has exceeded my expectations. The service is fantastic!"}
          </Typography>
        </Container>
        <div className={styles.scrollableContainer} style={{ overflowX: 'auto' }}>
          <Grid container spacing={3}>
            {reviews.map((review, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <ReviewCard
                  key={review.id}
                  quote={review.quote}
                  name={review.name}
                  profileImage={review.profileImage}
                  starReview={review.starReview}
                />
                </Grid>
            ))}
          </Grid>
        </div>
      </div>
    );
  }
  export default ReviewsSection;
