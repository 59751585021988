import React, { useState } from 'react';
import { Button, TextField, Container, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { observer } from 'mobx-react-lite';
import registerDriverStore from '../stores/RegisterStore';
import { US_STATES } from '../utils/States';

const CheckrForm = observer(({ onFormSubmit }) => {  // Add onFormSubmit prop

  const fullName = `${registerDriverStore.firstName} ${registerDriverStore.middleName ? registerDriverStore.middleName + ' ' : ''}${registerDriverStore.lastName}`.trim();

  const handleStateChange = (e) => {
    const { value } = e.target;
    registerDriverStore.setDriverLicenseState(value);
  }

  const handleDateChange = (name, value) => {
    registerDriverStore.setDOB(value.toISOString());
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Call the callback from parent with driver data
    onFormSubmit();
  };

  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="fullName"
          label="Full Name"
          name="fullName"
          value={fullName}
          disabled
        // onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          value={registerDriverStore.email}
          onChange={(e) => registerDriverStore.setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="phoneNumber"
          label="Phone Number"
          name="phoneNumber"
          value={registerDriverStore.phone}
          onChange={(e) => registerDriverStore.setPhone(e.target.value)}
        />
        <Grid sx={{ mt: '16px', mb: '8px' }}>
        <DatePicker
          label="Date of Birth"
          value={registerDriverStore.dob ? new Date(registerDriverStore.dob) : new Date()}
          onChange={(date) => handleDateChange("dob", date)}
          maxDate={new Date()}
          renderInput={(params) => <TextField {...params} sx={{
            mt: 16,
            mb: '8px',
            width: '100%',
          }} />}
        />
        </Grid>
        
        {/* <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="socialSecurityNumber"
          label="Social Security Number"
          name="socialSecurityNumber"
          value={registerDriverStore.socialSecurityNumber}
          onChange={handleChange}
        /> */}
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="driverLicenseNumber"
          label="Driver's License Number"
          name="driverLicenseNumber"
          value={registerDriverStore.driverLicenseNumber}
          onChange={(e) => registerDriverStore.setDriverLicenseNumber(e.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel id="stateLabel" sx={{ mt: '16px' }}>State</InputLabel>
          <Select
            id="state"
            label="State"
            labelId="stateLabel"
            name="state"
            value={registerDriverStore.driverLicenseState}
            sx={{ width: '100%', mt: '16px', mb: '16px' }}
            onChange={handleStateChange}
          >
            {US_STATES.map((state) => (
              <MenuItem key={state.abbreviation} value={state.abbreviation}>{state.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button type="submit" fullWidth variant="contained" color="primary">
          Send To Checkr
        </Button>
      </form>
    </Container>
  );
});

export default CheckrForm;
