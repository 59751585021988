import React, { useState, useRef } from 'react';
import { Button, TextField, Grid, Typography } from '@mui/material';
import authStore from '../stores/AuthStore';

function OauthOrVerify({ isOauth, onVerify }) {
  const [verificationCode, setVerificationCode] = useState(new Array(6).fill(''));
  const [error, setError] = useState('');
  const inputsRef = useRef([]);

  const handleInputChange = (index, value) => {
    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode);
    if (value && index < 5) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleVerifyClick = async () => {
    try {
      const phone = authStore.phone;  // Assuming phone data is stored in authStore
      const code = Math.floor(100000 + Math.random() * 900000).toString();
      await authStore.sendSMSVerificationCode(phone, code);
    } catch (error) {
      setError('Failed to send verification code. Please try again.');
    }
  };

  const handleCodeSubmit = async () => {
    try {
      const code = verificationCode.join('');
      const isValid = await authStore.verifyCode(code);
      if (isValid) {
        onVerify();
      } else {
        setError('Incorrect code. Please enter the correct verification code.');
      }
    } catch (error) {
      setError('Verification failed. Please try again.');
    }
  };

  return (
    <div>
      {verificationCode.includes('') ? (
        <div>
          <Typography variant="h6" gutterBottom>
            Enter the 6-digit code sent to your phone
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {verificationCode.map((digit, idx) => (
              <Grid item key={idx}>
                <TextField
                  variant="outlined"
                  inputProps={{ maxLength: 1 }}
                  value={digit}
                  onChange={(e) => handleInputChange(idx, e.target.value)}
                  inputRef={(ref) => (inputsRef.current[idx] = ref)}
                />
              </Grid>
            ))}
          </Grid>
          <Button onClick={handleCodeSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </div>
      ) : (
        <Button onClick={handleVerifyClick} variant="contained" color="primary">
          Verify Phone Number
        </Button>
      )}
      {error && <Typography color="error">{error}</Typography>}
    </div>
  );
}

export default OauthOrVerify;
