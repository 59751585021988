import React from "react";
import Navbar from "../components/Navbar";
import DollarsSection from "../components/page-sections/DollarsComponent";
import ProfitabilitySection from "../components/page-sections/ProfitabilitySection";
import ProfitsSection from "../components/page-sections/ProfitsSection";
import PricingSection from "../components/page-sections/PricingSection";
import PocketSection from "../components/page-sections/PocketSection";
import QuestionSection from "../components/page-sections/QuestionSection";
import Banner from "../components/ScrollingBanner";
import { useNavigate } from 'react-router-dom';

const indexedTextEntries = ['Fast Delivery Times', 'Seamless Order Management', 'Competitive Pricing', 'Untampered Food Guarantee', 'Transparent Cost Breakdown', 'Support Local Families', 'Reliable Delivery Drivers', '24/7 Customer Support', 'Complete Control', 'Flexible Delivery Options' ];
const ListEntries = [{ header: 'Clear Pricing Structure', content: 'No hidden costs. Just straightforward billing allowing you to put more money in your pocket.' },
{ header: 'Food Integrity Insurance', content: 'Rest easy knowing your food remains untampered with our Golden Seal delivery method.' },
{ header: 'Your Menu, Your Terms', content: 'Complete control of your menu without any surcharges, allowing you to set your own profit margins without ridiculous markups.' },];

const cardData = [
  {
    iconImage: '/YellowRocket.svg',
    header: 'Never Miss Out on Delivery Orders.',
    body: 'Our flat monthly subscription model gives you the freedom to earn more without any limitations.',
  },
  {
    iconImage: '/RedHandshake.svg',
    header: 'Predictable Payment Structures',
    body: 'Say goodbye to unpredictable earnings. Our platform ensures stability and reliability.',
  },
  {
    iconImage: '/BlueDirections.svg',
    header: 'More Money In Your Pocket',
    body: 'We believe that our drivers should keep as much revenue as possible, and we stand by that. ',
  },
];

export default function RestaurantPage() {
  const navigate = useNavigate();

  const navToDriverSignUp = () => {
    navigate('/register');
  }

  const handleClick = () => {
    console.log("Navigating to driver sign-up...");
  };

  const handleLearnMore = () => {
    console.log("Navigating to learn more...");
  };

  return (
    <>
      <Navbar />
      <DollarsSection
        image="/scooterist2.png"
        title="Earn More With FoodLoop's Driver Subscription Model"
        subtitle="Join FoodLoop and take control of your earnings with our flat monthly subscription model. Get unlimited access to delivery orders, make up to 30% more revenue, and keep your entire tip. Say goodbye to sliding scales and hidden fees."
        buttonText="Sign Up"
        handleClick={navToDriverSignUp}
      />
      <ProfitabilitySection
        backgroundColor="#FFFFFF"
        mainLineColor="#FF4848"
        bodyColor="#333"
        image="/deliveryWoman.png"
        mainLine="Boost Your Income"
        headerText="Drivers Make An Average of 30% More Income With FoodLoop*"
        subheaderText="FoodLoop’s unique subscription model allows drivers to earn better earnings and with reliable payment structures. With our flat monthly subscription model, you'll have unlimited access to delivery orders with consistent payments. No more guess work."
        profitIncreaseText="Highest Revenue Per Mile*"
        controlMenuText="Consistency is Key"
        subText1="FoodLoop drivers average the highest revenue per mile when compared to other leading food delivery services."
        subText2="Say goodbye to sliding scales and guess work, know exactly what you’re going to earn, every time."
        handleClick={navToDriverSignUp}
      />
      <ProfitsSection
        titleText="FoodLoop"
        subtitleText="Earn More, Stress Less."
        cardData={cardData}
      />
      <PricingSection
        superHeader="Transparency"
        transparencyHeader="Every Mile Matters"
        transparencyText="With our revolutionary subscription-based system, we’ve removed unpredictable earnings and brought clarity to your pay. Drive more & earn consistently, without the worry of sliding scales or hidden fees."
        price="$49 / month"
        elitePlanTexts={["Pro Driver Plan", "Unlimited Deliveries. Unlimited Possibilities."]}
        indexedTextEntries={indexedTextEntries}
        listEntries={ListEntries}
        handleClick={() => console.log("Navigating to driver sign-up...")}
        handleLearnMore={() => console.log("Navigating to learn more...")}
      />
      <PocketSection
        image="/driverImage.png"
        title="Driving to Financial Freedom"
        subtitle="Embrace a driving career where you're not just a number, but a valued member of the FoodLoop family. Our unique model prioritizes your earnings and work-life balance, setting us apart from the competition. With FoodLoop, you gain access to a steady stream of orders, comprehensive support, transparent and consistent pay structures, and the highest revenue per mile. With FoodLoop, you're not just delivering meals; you're delivering excellence."
      />
      <QuestionSection
        sectionTitle="Profitability First"
        mainTitle="Questions"
        subTitle="Find answers to common questions and concerns about partnering with FoodLoop."
        arrowsImageAlt="arrows"
      />
    </>
  );
}
