import React from 'react';
import { Paper, Typography, Button } from '@mui/material';
import ContentSection from '../components/page-sections/ContentSection';
import ContentSectionAlt from '../components/page-sections/ContentSectionAlt';

function InfoPage({
  pageTitle,
  subtitle,
  sections = [],
  heroImage = null,
}) {
  const heroStyle = {
    background: heroImage 
      ? `
        radial-gradient(circle at 100% 0%, rgba(150, 134, 130, 0.6) 0%, rgba(76, 76, 76, 0.9) 33%),
        url(${heroImage}) center/cover no-repeat
      ` 
      : 'radial-gradient(circle at 100% 0%, rgba(150, 134, 130, 0.6) 0%, rgba(76, 76, 76, 0.9) 33%)',
    width: '100%',
    minHeight: '40vh',
    marginTop: 0,
    position: 'relative',
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div>
      <div style={heroStyle}>
        <Paper elevation={0} sx={{ p: 3, my: 3, boxShadow: 'none', background: 'transparent' }}>
          <Typography variant="h1" align="center" fontWeight={400} paddingTop='5vh' color="white" gutterBottom>
            {pageTitle}
          </Typography>
          <Typography variant="h4" align="center" color="white" gutterBottom>
            {subtitle}
          </Typography>
        </Paper>
      </div>

      {sections.map((section, index) => {
        const Component = index % 2 === 0 ? ContentSection : ContentSectionAlt;
        return (
          <Component
            key={section.heading}
            backgroundColor={section.backgroundColor}
            heading={section.heading}
            text={section.text}
            imageSrc={section.imageSrc}
            altText={section.altText}
          >
            {section.button && (
              <Button variant="contained" color="primary" sx={{ fontSize: '1.5rem', alignSelf: 'flex-end' }}>
                {section.button.text}
              </Button>
            )}
          </Component>
        );
      })}
    </div>
  );
}

export default InfoPage;
