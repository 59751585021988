import { makeAutoObservable, runInAction } from 'mobx';
import apiHandler from '../api/ApiHandler';

class OrderStore {
  orders = [];
  currentOrder = null;
  trackingInfo = null;
  isLoading = false;
  errorMessage = null;
  successMessage = null;
  clientSecret = null;

  constructor() {
    makeAutoObservable(this);
  }

  async createOrder(order) {
    this.isLoading = true;
    try {
      const response = await apiHandler.post('orders', order);

      runInAction(() => {
        if (response) {
          this.currentOrder = response.message;
          this.orders.push(response.message);
          this.successMessage = 'Order created successfully';
        } else {
          this.handleAPIError(response);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.errorMessage = error.message || "Failed to create order";
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async getOrderDetails(orderId) {
    this.isLoading = true;
    try {
      const response = await apiHandler.get(`orders/${orderId}`);

      runInAction(() => {
        if (response.status === 200) {
          this.currentOrder = response;
          this.successMessage = 'Order details fetched successfully';
        } else {
          this.handleAPIError(response);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.errorMessage = error.message || "Failed to fetch order details";
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async trackOrder(orderId) {
    this.isLoading = true;
    try {
      const response = await apiHandler.get(`orders/${orderId}/track`);

      runInAction(() => {
        if (response.status === 200) {
          this.trackingInfo = response.data.tracking;
          this.successMessage = 'Order tracking updated successfully';
        } else {
          this.handleAPIError(response);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.errorMessage = error.message || "Failed to track order";
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  handleAPIError(response) {
    switch (response.status) {
      case 400:
        this.errorMessage = 'Bad request, please check your order details';
        break;
      case 404:
        this.errorMessage = 'Order not found';
        break;
      case 500:
        this.errorMessage = 'Internal server error, please try again later';
        break;
      default:
        this.errorMessage = `Received unexpected status code: ${response.status}`;
        break;
    }
  }

  // Additional methods for updating order status, handling cancellations, refunds, etc.
}

const orderStore = new OrderStore();
export default orderStore;
